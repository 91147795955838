import {configureStore} from '@reduxjs/toolkit';
import criteriaReducer from '../features/search/search-criteria-slice';
import curatedSearchReducer from '../features/search/curated-search-slice';
import layoutReducer from '../features/layout/layout-slice';
import resultsReducer from '../features/search/search-results-slice';
import widgetReducer from '../features/popups/search-widget-slice';
import toastReducer from '../features/popups/toast-slice';
import topicsReducer from '../features/topicbrowse/topic-browse-slice';
import frtocReducer from '../features/frtoc/frtoc-slice';
import dateBrowseReducer from '../features/date-browse/date-browse-slice';
import collectionsReducer from '../features/collectionbrowse/collection-browse-slice';
import cmrReport from '../features/collectionbrowse/cmr-report-slice';

export const store = configureStore({
    reducer: {
        widget: widgetReducer,
        toast: toastReducer,
        layout: layoutReducer,
        criteria: criteriaReducer,
        results: resultsReducer,
        curatedSearch: curatedSearchReducer,
        topics: topicsReducer,
        frtoc: frtocReducer,
        dateBrowse: dateBrowseReducer,
        collections: collectionsReducer,
        cmrReport: cmrReport
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>
