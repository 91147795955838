export function useGetBreakpoint() {
	// Breakpoint values correspond to values found in stylesheets/scss/abstracts/tokens/primitives.
	// If those values are updated, they will need to be updated here, also.
	const breakpointXS = "375px";
	const breakpointSM = "480px";
	const breakpointMD = "768px";
	const breakpointLG = "960px";
	const breakpointXL = "1200px";

	/// ### Breakpoint media query map
	const breakpoints = {
		"xsmall": `(min-width: ${breakpointXS})`,
		"small": `(min-width: ${breakpointSM})`,
		"medium": `(min-width: ${breakpointMD})`,
		"large": `(min-width: ${breakpointLG})`,
		"xlarge": `(min-width: ${breakpointXL})`
	};

	let breakpoint = "xsmall";
	for (const [key, value] of Object.entries(breakpoints)) {
		const matchQuery = window.matchMedia(value);
		if (matchQuery.matches) {
			breakpoint = key;
		}
	}
	return breakpoint;
}