import React from "react";
import {useAppSelector} from '../../app/hooks'
import {Navigator} from '../../features/search/search-results-slice'
import urlUtil from '../../utility/urlUtil'
import { useNavigate } from "react-router-dom";

const ButtonShowMoreLess = ({navigator}:{navigator:Navigator})=>{
	const selectedFacet =  useAppSelector(state=>state.criteria.facetToExpand)
	const navigate = useNavigate();
	const criteria = useAppSelector((state)=> state.criteria)

	const selectFacetForExpansion = ()=>{
		if(navigator.name==selectedFacet){
			navigate(`/app/search/${urlUtil.expandFacet(criteria,'non_existing_facet')}`)
		}else{
			navigate(`/app/search/${urlUtil.expandFacet(criteria,navigator.name)}`)
		}
	}
    return (
		<button type="button"
			className={`btn-navigator-show-moreless ${selectedFacet == navigator.name ? 'less' : 'more'}`}
			tabIndex={0}
			onClick={selectFacetForExpansion}
			>
			{`See ${selectedFacet==navigator.name ? ' Less' : ' More'}`}
		</button>		
	);
}

export default ButtonShowMoreLess;