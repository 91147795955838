import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ResultItem, BrowseItem } from '../../app/browse.types';

export interface FrToc {
  data?: Data;
  packageid?: string;
  currentDate?: string;
  endDate?: string;
  startDate?: string;
  inProgress: boolean;
  browse: BrowseItem[];
}

export interface Data {
  footer: Footer
  pageTitle: string
  readeraids: Readeraids
  downloadlinks: Downloadlinks2
  header: Header
  agencies: Agency[]
}

export interface Footer {
  parts: Parts
}

export interface Parts {
  xmllink: string
  pdflink: string
  txtlink: string
}

export interface Readeraids {
  downloadlinks: Downloadlinks
}
export interface Downloadlinks {
  pdflink: string
  txtlink: string
}

export interface Downloadlinks2 {
  morelink: string
  sharelink: string
}

export interface Header {
  parts: Parts2
}

export interface Parts2 {
  xmllink: string
  pdflink: string
  somefield: string
}

export interface Agency {
  agencyheading: string
  tocsubjects: any[]
}

const initialState: FrToc = {
  data: {
    agencies: [],
    pageTitle: null,
    downloadlinks: {
      morelink: null,
      sharelink: null
    },
    footer: {
      parts: {
        pdflink: null,
        txtlink: null,
        xmllink: null
      }
    },
    header: {
      parts: {
        pdflink: null,
        somefield: null,
        xmllink: null
      }
    },
    readeraids: {
      downloadlinks: {
        pdflink: null,
        txtlink: null
      }
    }
  },
  endDate: null,
  startDate: null,
  inProgress: true,
  browse: null
}

export const getTableOfContents = createAsyncThunk('frtoc/get', (date: string) => {
  return axios
    .get(`/wssearch/today/FR/${date}`)
    .then(({ data }) => { data.inProgress=false; return data as FrToc })

});

const frtocSlice = createSlice({
  name: 'frtoc',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getTableOfContents.fulfilled, (state, { payload }) => {
      //state.inProgress = false
      const issue = payload.data.downloadlinks.morelink.split("/")[3];
      const currentDate = issue.substring(3);
      state = payload;
      state.currentDate = currentDate;
      state.packageid = issue;
      state.data.footer.parts.xmllink = null; // because there's not supposed to be an XML link for Front Matter. Search service returns link to Daily FR XML.

      const agencies = [];
      payload.data.agencies.forEach((agency, agencyIndex) => {
        const agencyItem = {
          label: agency.agencyheading,
          nodes: []
        }
        let currentCat = (agency.tocsubjects[0].agencysubheading);
        let catSubjects = [];
        agency.tocsubjects.forEach((subject, subjectIndex) => {
          if (subject !== "") {
            if (subject.agencysubheading && subject.agencysubheading !== currentCat) {
              agencyItem.nodes.push({
                label: currentCat,
                nodes: [...catSubjects]
              });
              currentCat = subject.agencysubheading;
              catSubjects = [];
              catSubjects.push(subject);
            } else {
              catSubjects.push(subject);
            }
          } else {
            agencyItem.nodes.push({
              label: currentCat,
              nodes: [...catSubjects]
            });
          };
        });
        agencies.push(agencyItem);
      });
      state.browse = [...agencies];
      return state;
    })
      .addCase(getTableOfContents.pending, (state) => {
        state.inProgress = true
        //payload.inProgress = true;
      })
      .addCase(getTableOfContents.rejected, (state) => {
        //state.inProgress = false

        // console.log('rejected called')
      })
  }

})

//export const {doSearch} = frtocSlice.actions;

const { actions, reducer } = frtocSlice;
export default reducer;
