import { NodeValue } from "../app/browse.types";

export default {
	getCollectionBrowseSortInfo() {
		// TODO: Look at making this be transmitted as a URL query instead as encoded JSON.
		// That would make the URL easier to human parse and easier to collect with React-Router
		let path = window.location.pathname.substring(16).split("/");
		try {
				return JSON.parse(decodeURIComponent(path[path.length - 1]));
		} catch(e) {
				// console.error(e);
		}
		return {pageSize: 0, offset: 0};
	},

	getCollectionBrowsePath() {
			let path = window.location.pathname.substring(16).split("/");
			// path.shift();
			if (path[path.length - 1].includes('pageSize') || path[path.length - 1].includes('offset')) {
					path.pop();
			}
			let browsePath = path.join("/");
			if (!browsePath.endsWith("/")) {
					browsePath += "/";
			}
			
			return browsePath;
	},

	getRenditions(nodeValue: NodeValue, collection:string) {
		const FILE_TYPE_PER_COLLECTION = {
			sjournal: ['pdffile', 'htmlfile']
		};
		const renditionBaseUrl = "/content/pkg/";
		// const collection = nodeValue.collectionCode.toLowerCase();
		let nodeRenditions = Object.keys(nodeValue).filter((r) => r.includes("file"));
		// Since different collections may have different file types and corresponding renditions,
		// we define those file types beforehand and extract only the relevant/required files or renditions from the node value
		// to avoid rendering unintended renditions.
		if (FILE_TYPE_PER_COLLECTION.hasOwnProperty(collection)) {
			nodeRenditions = Object.keys(nodeValue).filter((r) => r.includes("file") && FILE_TYPE_PER_COLLECTION[collection].includes(r));
		}
		const itemRenditions = [];
		// if you have both text and html renditions just remove one
		if (nodeRenditions.includes('textfile') && nodeRenditions.includes('htmlfile')) {
			nodeRenditions.splice(nodeRenditions.indexOf('htmlfile'), 1);
		}

		if (nodeRenditions.length > 0) {
			nodeRenditions.forEach((f) => {
				let contentType = nodeValue[f].split(".")[1];
				if (contentType==='xml' && nodeValue[f].indexOf('/uslm/') != -1) {
					contentType='uslm';
				}
				if (contentType != "gif" && !nodeValue[f].includes("thumbnail")) {
					itemRenditions.push({
						url: `${renditionBaseUrl}${nodeValue[f]}`,
						format: contentType
					});
				}
			})
		}
		return itemRenditions;
	},

	getDetailsLink(nodeValue: NodeValue) {
		// TODO: these two consts should get elevated for use app-wide 
		const contentDetailsBaseUrl = "/app/details/";
		const renditionBaseUrl = "/content/pkg/";
	
		let url = "";
		if (nodeValue.packageid) {
			url = `${contentDetailsBaseUrl}${nodeValue.packageid}${(nodeValue.granuleid ? "/" + nodeValue.granuleid : "")}`;
		}
		return url;
	},

	getThumbnail(nodeValue: NodeValue) {
		let thumbnail = "";
		if (Object.hasOwn(nodeValue, "image") && nodeValue.image.includes("thumbnail")) {
			thumbnail = `/content/pkg/${nodeValue.packageid}/${nodeValue.image}`;
		} else {
			let files = Object.keys(nodeValue).filter((f) => f.includes("mime"));
			files.forEach((mime) => {
				let file = mime.replace("mime", "file");
				if (nodeValue[mime].includes("image") && nodeValue[file].includes("thumbnail")) {
					thumbnail = `/content/pkg/${nodeValue[file]}`;
				}
			});

		}
		return thumbnail;
	},

	showSpinner(parentNode, state) {
		// TODO: This is a temporary change. Review the significance and proper usage of this method, and refactor accordingly.
		// NOTE: 
		// if (node.nodeValue.collectionCode.toLowerCase() === 'budget') {
		// 		return false;
		// }
		// NOTE: this logic may not work with budget...
		const browsePath = this.getCollectionBrowsePath();
		let parentBrowsePathAlias = parentNode.nodeValue.browsePathAlias;
		if (parentNode.nodeValue.hasgranules && parentNode.nodeValue.hasgranules === "true") {
			parentBrowsePathAlias += `/${parentNode.nodeValue.packageid}/`;
		}

		const show = !browsePath.includes(parentBrowsePathAlias) && state.inProgress;
		return show;
	}
}