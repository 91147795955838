import React from "react";

const ButtonDetails = (props: any) => {
  return (
    // http://localhost:5173/content/pkg/pdf/SERIALSET-00480_00_00-006-0005-0000.pdf
    <a
      className="btn-details"
      href={props.url}
      target={props.label !== "details" ? '_blank':'' }
      onClick={(props.onClick) ? props.onClick : null}
    >
      {props.label}
    </a>
  );
};
export default ButtonDetails;
