import React, { useContext } from "react";
import textUtils from "../../utility/textUtils";
import Accordion from 'react-bootstrap/Accordion';
import { AccordionContext } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap";
import { BrowseHeaderContent } from "../../app/browse.types";
import ResultItemTitle from "../layout/ResultItemTitle";
import RenditionButttonGroup from "../navigation/RenditionButttonGroup";
import ResultItemFigure from "../navigation/ResultItemFigure";

function ContextAwareToggle({ children, eventKey, callback, className }) {
	const { activeEventKey } = useContext(AccordionContext);
	const toggle = useAccordionButton(eventKey, (evt) => {
		// console.log("click", callback);
		callback && callback(evt)
	});
	const isOpen = activeEventKey.includes(eventKey);

	return (
		<button
			type="button"
			className={`accordion-button ${className}`}
			onClick={toggle}
			aria-expanded={isOpen}
		>
			{children}
		</button>
	)
}

function BrowseHeaderFancy({
	label,
	slug,
	url,
	showShare,
	onClick
}: {
	label: BrowseHeaderContent,
	slug: string,
	url?: string,
	showShare?: boolean,
	onClick(event: React.MouseEvent<HTMLButtonElement>): void}
) {
	const convertedTitle =  textUtils.htmlToMarkdown(label.line1);
	const convertedSubtitle = (label.line2) ? textUtils.htmlToMarkdown(label.line2) : "";
	const convertedDescription = (label.description) ? textUtils.htmlToMarkdown(label.description) : "";

	const itemDetails = {
		url: (url) ? url : "",
		title: convertedTitle,
		subtitle: convertedSubtitle
	}

	return (
		// <Accordion.Header
		// 	as="header"
		// 	className="browse-header fancy"
		// 	onClick={onClick}
		// >
		<header className="browse-header fancy">
			<ContextAwareToggle eventKey={slug} callback={onClick} className="browse-titles">
				{
					<ResultItemTitle
						title={label.line1}
						subtitle={label.line2 ? label.line2 : ""}
						url={""}
						hasPill={false}
					/>
				}
			</ContextAwareToggle>
			{
				(label.renditions)
					? <RenditionButttonGroup
							details={itemDetails}
							renditions={label.renditions}
							showShare={showShare}
						/>
					: null
			}
			{
				(label.thumbnail)
					? <ContextAwareToggle eventKey={slug} callback={onClick} className="browse-thumbnail">
							<ResultItemFigure
								thumbnailSrc={label.thumbnail}
								details={itemDetails} />
						</ContextAwareToggle>
					: null
			}
			{
				(label.description)
					? <ContextAwareToggle eventKey={slug} callback={onClick} className="browse-description">
							<p>{ convertedDescription }</p>
						</ContextAwareToggle>
					: null
			}
			{/* {
				(label.thumbnail || label.description)
					? <ContextAwareToggle eventKey={slug} callback={onClick} className="browse-description">
							<aside className="browse-description">
								{
									(label.thumbnail)
										? <ResultItemFigure
											thumbnailSrc={label.thumbnail}
											details={itemDetails} />
										: null
								}
								{
									(label.description)
										? <p>{ convertedDescription }</p>
										: null
								}
							</aside>
						</ContextAwareToggle>
					: null
			} */}
		</header>
		// </Accordion.Header>
	)
}

export default BrowseHeaderFancy;