import React from "react";
import HistoricalResultsToggle from "./HistoricalResultsToggle";
import SelectSortSearchResults from "./SortResults";

function SearchSummaryDetails({recordCount, hasHistoricalToggle, hasSortSelect}: {recordCount: string, hasHistoricalToggle: boolean, hasSortSelect: boolean}) {

	return (
		<section
			className="search-summary-results"
			aria-label="View The results of your search here"
		>
			<h1 id="record-count-id" className="summary-title">
				{recordCount} Records
			</h1>

			{
				(hasHistoricalToggle)
					? <HistoricalResultsToggle/>
					: null
			}

			{
				(hasSortSelect)
					? <SelectSortSearchResults />
					: null
			}
		</section>
	)
}

export default SearchSummaryDetails