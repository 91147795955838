import React from 'react'
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ButtonClose from '../navigation/ButtonClose';
import { LoadingSpinner, Icon } from '@govinfo/react-components';
// import Spinner from "./Spinner";
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {downloadCSVResults,downloadJSONResults} from '../../features/search/search-results-slice';

function ModalDownloadResults(props:{onClose:()=>void,show:boolean}) {
	
	const criteria = useAppSelector((state)=> state.criteria)
	const dispatch = useAppDispatch();

	let csvProgress =useAppSelector((state)=>state.results.downloadProgressCSV)
	let jsonProgress =useAppSelector((state)=>state.results.downloadProgressJSON)
	// TODO: Refactor into reusable component with other modals
	// TODO: Add props to send settings
	return (
		// <!-- Modal - Download Results -->
		<Modal
			show={props.show}
			onHide={props.onClose}>
			<ModalHeader closeButton>
				<h2 className='modal-title'><Icon id="download" />Download</h2>
			</ModalHeader>
			<ModalBody>
				<p>Search results can be downloaded as either a CSV or JSON format file, up to the first 1,000 results.</p>
			</ModalBody>
			<ModalFooter as="footer">
				<aside className='btn-group-horizontal'>
					<button type='button'
						className='btn-details'
						title='Download CSV'
						onClick={(e)=>{e.preventDefault(); dispatch(downloadCSVResults(criteria))}}>
						{ csvProgress ? <><LoadingSpinner size="button" /> </> : null }
						CSV
					</button>  
					<button type='button'
						className='btn-details'
						title='Download JSON'
						onClick={(e)=>{e.preventDefault(); dispatch(downloadJSONResults(criteria))}}>
						{ jsonProgress ? <><LoadingSpinner size="button" /> </> : null }
						JSON
					</button>
				</aside>

				<ButtonClose onClose={props.onClose} />

			</ModalFooter>
		</Modal>
	);
}

export default ModalDownloadResults;
