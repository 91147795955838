import { SearchQuery } from '../features/search/search-criteria-slice'

export default {
    addNavigator(criteria: SearchQuery, navigator: string, value: string) {
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.offset=0
        criteria.facets = criteria.facets || {}
        if ( !criteria.facets.hasOwnProperty(navigator)) {
            criteria.facets[navigator] = []
        }

        //if (navigator == 'publishdatehier' || navigator == 'dochierarchy') {
            let navVal: string = value;
            let updated: boolean = false;

            // sometimes parent is part of the facet, sometimes, child is part of the facet


            //1/ consider it as a parent 

            let navs: string[] = criteria.facets[navigator].map((val, i) => {
                if (val.toString().match('^' + navVal + '.*')) {
                    updated = true
                    return navVal
                } else {
                    return val
                }
            })
            // 2. consider it as a child
            if (!updated) {
                navs = criteria.facets[navigator].map((val, i) => {
                    if (navVal.indexOf(val) != -1) {
                        updated = true
                        return navVal
                    } else {
                        return val
                    }
                })
            }
            if (!updated) {
                criteria.facets[navigator].push(navVal);
            } else {
                criteria.facets[navigator] = navs
            }

        //} 
        // else {
        //     criteria.facets[navigator].push(value);
        // }


        // add it to filter order as well 
        criteria.filterOrder = criteria.filterOrder || []
        if (!criteria.filterOrder.includes(navigator)) {
            criteria.filterOrder.push(navigator)
        }

        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))


    },
    removeNavigator(criteria: SearchQuery, payload: { nav: string, value: string }) {
        // if(payload.nav == 'publishdatehier' || payload.nav == 'dochierarchy'){ // there is 
        // }else
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.offset=0
        let index = criteria.facets[payload.nav].length > 1 ? criteria.filterOrder.indexOf(payload.nav)+1 : criteria.filterOrder.indexOf(payload.nav)
        criteria.filterOrder.splice(index, criteria.filterOrder.length)

        Object.keys(criteria.facets).forEach(function(key,index) {
            if(!criteria.filterOrder.includes(key)) {
                delete criteria.facets[key]
            }   
        });
        if(criteria.facets[payload.nav] && criteria.facets[payload.nav].length > 1){
            const i = criteria.facets[payload.nav].indexOf(payload.value)
            criteria.facets[payload.nav].splice(i, 1)
        }


        // const i = criteria.facets[payload.nav].indexOf(payload.value)
        // criteria.facets[payload.nav].splice(i, 1)
        // if (criteria.facets[payload.nav].length == 0) {
        //     delete criteria.facets[payload.nav]
        //     criteria.filterOrder.splice(criteria.filterOrder.indexOf(payload.nav), 1)
        // }
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },
    clearNavigators(criteria: SearchQuery) {
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.offset=0
        criteria.facets = {};
        criteria.filterOrder = [];
        delete criteria.facetCustomSorts;
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },

    expandFacet(criteria: SearchQuery, payload: string) {
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.facetToExpand = payload
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },
    changeSort(criteria: SearchQuery, payload: string) {
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.sortBy = payload
        criteria.offset=0
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },
    selectPageSize(criteria: SearchQuery, payload: number) {
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.pageSize = payload;
        criteria.offset=0
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },

    pageSelection(criteria: SearchQuery, payload: number) {
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.offset = payload;
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },
    updateSearchQuery(criteria: SearchQuery, payload: string) {
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.query = payload;
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },
    toggleHistorical(criteria: SearchQuery) {
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.historical = !criteria.historical
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },
    addCustomSort(criteria: SearchQuery, navigator:string){
        criteria = JSON.parse(JSON.stringify(criteria))
        criteria.facetCustomSorts =  criteria.facetCustomSorts || {}
        if(!criteria.facetCustomSorts[navigator]){
            criteria.facetCustomSorts[navigator]=true
        }
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },
    removeCustomSort(criteria: SearchQuery, navigator:string){
        criteria = JSON.parse(JSON.stringify(criteria))
        if(criteria.facetCustomSorts && criteria.facetCustomSorts[navigator]){
            delete criteria.facetCustomSorts[navigator]
        }
        if(criteria.facetCustomSorts && Object.keys(criteria.facetCustomSorts).length===0){
            delete criteria.facetCustomSorts
        }
        return encodeURIComponent(JSON.stringify(criteria).replaceAll('/', '¼'))
    },
}
