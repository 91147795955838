import React from 'react';
// import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import ToastHeader from 'react-bootstrap/ToastHeader';
import ToastBody from 'react-bootstrap/ToastBody';
import { Icon } from '@govinfo/react-components';
import { toggleToast } from '../../features/popups/toast-slice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

function ToastBasic(props:any) {
  const dispatch = useAppDispatch();
	const toast = useAppSelector((state)=> state.toast.show);
	const toggle = () => {
		dispatch(toggleToast());
	}

	// TODO: Expand customization via props for variations (warning, error, etc.)
	return (
		<Toast
			className='toast-info'
			show={toast}
			onClose={toggle}
			delay={4000}
			autohide
		>
			<ToastHeader>
				{/* <h1 className="toast-title"><i className='bi bi-clipboard-fill'></i>{props.title}</h1> */}
				<h1 className="toast-title"><Icon id="clipboard" />{props.title}</h1>
			</ToastHeader>
			<ToastBody as='p'>
				{props.children}
			</ToastBody>
		</Toast>
	);
}

export default ToastBasic;
