import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface WidgetState {
    widgetOpen: boolean,
    hasInputFocus: boolean
}

const initialState: WidgetState = {
    widgetOpen: false,
    hasInputFocus: false
}

const widgetSlice = createSlice({
    name:'widget',
    initialState,
    reducers:{
        toggleWidget: (state) => {
            state.widgetOpen =! state.widgetOpen;
            state.hasInputFocus = state.widgetOpen;
        },
        openWidget: (state) => {
            state.widgetOpen = true;
            state.hasInputFocus = true;
        },
        closeWidget: (state) => {
            state.widgetOpen = false;
            state.hasInputFocus = false;
        },
        toggleBasicSearchInput: (state) => {
            state.hasInputFocus = !state.hasInputFocus;
        },
        focusBasicSearchInput: (state) => {
            state.hasInputFocus = true;
        },
        blurBasicSearchInput: (state) => {
            state.hasInputFocus = false;
        }
    }
})

export default widgetSlice.reducer
export const  {toggleWidget, openWidget, closeWidget, toggleBasicSearchInput, focusBasicSearchInput, blurBasicSearchInput}  = widgetSlice.actions
