import React, {useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ButtonClose from '../navigation/ButtonClose';
import {LoadingSpinner, Icon} from '@govinfo/react-components';
// import Spinner from "./Spinner";
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {
    updateSelectedCongress,
    downloadJSONCmrReport,
    downloadCSVCmrReport
} from '../../features/collectionbrowse/cmr-report-slice';

// NOTE: This was started, anticipating a need for the CMR table download functionality on the CMR Topic Browse page.
// However, it is not. Holding on to this because it will eventually be needed when the CMR Browse page is eventually
// ported to this app. The UI is roughed in. Functionality needs implementing.

function ModalDownloadCMRTable(props: { onClose: () => void, show: boolean }) {
	const dispatch = useAppDispatch();
	const listOfCongress = useAppSelector((state) => state.collections.books);
	const defaultSelectedCongress = (listOfCongress && listOfCongress.length > 0) ? listOfCongress[0].book : "";

	let csvProgress = useAppSelector((state) => state.cmrReport.downloadCSVInProcess);
	let jsonProgress = useAppSelector((state) => state.cmrReport.downloadJSONInProcess);
	let selectedCongress = useAppSelector((state) => state.cmrReport.congress);

	useEffect(() => {
		dispatch(updateSelectedCongress(defaultSelectedCongress));
	}, [defaultSelectedCongress]);

    // TODO: Refactor into reusable component with other modals
    // TODO: Add props to send settings
    return (
        // <!-- Modal - Download Results -->
        <Modal
            show={props.show}
            onHide={props.onClose}>
            <ModalHeader closeButton>
                <h2 className='modal-title'><Icon id="download"/>Download</h2>
            </ModalHeader>
            <ModalBody>
                <p>A table of Congressionally Mandated Reports can be downloaded as either a CSV or JSON format
                    file.</p>
                <label htmlFor={selectedCongress} className="modal-form-label">Select a Congress</label>
                <Form.Select
                    id="congressSelect"
                    title="Congress Select"
                    className="results-number-select form-select"
                    onChange={(evt) => {
                        dispatch(updateSelectedCongress(parseInt(evt.target.value)));
                    }}
                    value={selectedCongress}>
                    {
                        (listOfCongress && listOfCongress.length > 0)
                            ? listOfCongress.map((congress) => {
                                return <option value={congress.book}
                                               key={`item-${congress.book}`}> {`${congress.title}`}  </option>
                            })
                            : null
                    }
                </Form.Select>
            </ModalBody>
            <ModalFooter as="footer">
                <aside className='btn-group-horizontal'>
                    <button type='button'
                            className='btn-details'
                            title='Download CSV'
                            onClick={() => dispatch(downloadCSVCmrReport(selectedCongress))}>
                        {csvProgress ? <LoadingSpinner size="button"/> : null}
                        CSV
                    </button>
                    <button type='button'
                            className='btn-details'
                            title='Download JSON'
                            onClick={() => dispatch(downloadJSONCmrReport(selectedCongress))}>
                        {jsonProgress ? <LoadingSpinner size="button"/> : null}
                        JSON
                    </button>
                </aside>
                <ButtonClose onClose={props.onClose}/>
            </ModalFooter>
        </Modal>
    );
}

export default ModalDownloadCMRTable;
