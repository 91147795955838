import React from "react";
import Markdown from "react-markdown";
import textUtils from "../../utility/textUtils";
// import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import ResultItemTitle from "../layout/ResultItemTitle";
import ResultItemFigure from "./ResultItemFigure";
import RenditionButttonGroup from "./RenditionButttonGroup";

interface ResultItemCommonProps {
	line2?: string;
	packageid?: string;
	granuleid?: string;
	renditions?: {
		url: string,
		format: string
	}[];
	hasThumbnail?: boolean;
	thumbnailSrc?: string | null,
	fullSizeSrc?: string | null
	showShare?: boolean;
	shareTitle?: string,
	url?: string;
	teaser?: string;
	hasPill?: boolean;
	className?: string;
}

type ResultTitleProps =
	| {
		line1?: string,
		title?: never,
		}
	| {
		line1?: never,
		title?: string,
	};

// type ResultThumbnailProps = 
// 	| {
// 			hasThumbnail?: true,
// 			thumbnailSrc?: string,
// 			fullSizeSrc?: string
// 		}
// 	| {
// 		hasThumbnail?: false,
// 		thumbnailSrc?: never | null,
// 		fullSizeSrc?: never | null
// 	};

type ResultItemProps = ResultItemCommonProps & ResultTitleProps; // & ResultThumbnailProps;

const ResultItem = (props: ResultItemProps) => {
	// function htmlToMarkdown(convertString:string) {
	// 	// This is assuming the only HTML coming in is <b></b>
	// 	// This should also, ideally, be handled on the backend by the API
	// 	const bTagRegExp = new RegExp("<\/?b>", "g");
	// 	let convertedString = convertString.replaceAll(bTagRegExp, "**");
	// 	// for browse <span> tags are coming
	// 	const spanTagRegExp = new RegExp("<\/?span[^>]*>", "g");
	// 	convertedString = convertedString.replaceAll(spanTagRegExp, "");
		
	// 	return convertedString
	// }

	const convertedTitle =  (props.line1) ? textUtils.htmlToMarkdown(props.line1) : textUtils.htmlToMarkdown(props.title);
	const convertedSubtitle = (props.line2) ? textUtils.htmlToMarkdown(props.line2) : "";
	const convertedTeaser = (props.teaser) ? textUtils.htmlToMarkdown(props.teaser) : "";
	const convertedShareTitle = (props.shareTitle)? textUtils.htmlToMarkdown(props.shareTitle): convertedTitle;

	const itemDetails = {
		url: props.url?.toLowerCase() != "unavailable" ? props.url : "", // This is specifically related to the View Entire Issue section and is necessary to preserve the behavior currently in PROD.
		title: convertedTitle,
		subtitle: convertedSubtitle
	}
	// This is almost similar to itemDetails above, except we don’t need to check if the URL is unavailable
	// because we expect that value for View Entire Section in the CREC collection browse.
	const shareDetails = {
		url: props.url,
		title: convertedShareTitle,
		subtitle: convertedSubtitle
	}

	return (
		<article className={`result-item ${(props.className) ? props.className : ""}`}>
			<header className="result-item-header">

				<ResultItemTitle
					title={itemDetails.title}
					subtitle={itemDetails.subtitle}
					url={itemDetails.url}
					hasPill={props.hasPill}
				/>
			</header>
			{
				(props.teaser && props.teaser !== "")
					? <Markdown>{convertedTeaser}</Markdown>
					: null
			}

			{
				(props.hasThumbnail)
					? <ResultItemFigure
						thumbnailSrc={props.thumbnailSrc}
						fullSizeSrc={(props.fullSizeSrc != "") ? props.fullSizeSrc : props.thumbnailSrc}
						details={itemDetails} />
					: null
			}
			{
				(props.renditions)
					? <RenditionButttonGroup
							details={itemDetails}
							shareDetails={shareDetails}
							renditions={props.renditions}
							showShare={props.showShare}
						/>
					: null
			}
		</article>
	)
}
export default ResultItem;
