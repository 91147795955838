import React, { useState, useEffect, useRef } from "react";
import Accordion from 'react-bootstrap/Accordion';

function BrowseHeader({label, onClick}: {label:string, onClick(event: React.MouseEvent<HTMLButtonElement>): void}) {
	return (
		<Accordion.Header
			as="header"
			className="browse-header"
			onClick={onClick}>
			{label}
		</Accordion.Header>
	)
}

export default BrowseHeader;