import React, {useState, useEffect, useRef} from "react";
import {getResults} from "./features/search/search-results-slice";
import {useAppDispatch, useAppSelector} from './app/hooks';
import {useParams, useNavigate, useSearchParams, useLocation} from 'react-router-dom';
import {urlState} from './features/search/search-criteria-slice';
import { updateLayout, updateSearchRSS, updateTitle } from "./features/layout/layout-slice";
import ModalDownloadResults from "./components/popups/ModalDownloadResults";
import SearchSummary from "./components/searchResults/SearchSummary";
import ResultsContainer from "./components/searchResults/ResultsContainer";
import NavigatorMenu from "./components/searchResults/NavigatorMenu";
import ErrorNotFound from "./components/layout/ErrorNotFound";
import NoSearchResults from "./components/searchResults/NoResultsPanel";
// import PageNotFound from "./components/navigation/PageNotFound";
import Toasts from "./components/popups/Toasts";
import { LoadingSpinner, PageHeader, Masthead, SiteFooter } from "@govinfo/react-components";

function AppSearch(props: any) {
	const dispatch = useAppDispatch();
	let {searchReq} = useParams();
	const criteria = useAppSelector((state)=>state.criteria);
	const inprogress = useAppSelector((state)=>state.results.inProgress);
	const apiError = useAppSelector((state)=>state.results.apiError);
	const layout = useAppSelector((state) => state.layout);
	const [searchParams, setSearchParams] = useSearchParams();
	let [badJSON, setBadJSON] = useState(false);
	let [hasError, setHasError] = useState(false);
	let navigate = useNavigate();
	const qstr = searchParams.get('qstr');
	let searchtext =  qstr ? qstr : (searchReq ? searchReq : undefined);
	const location = useLocation();
	const mainContentLinkRef = useRef();

	const [showDownloadModal, setShowDownloadModal] = useState(false);
	const closeDownloadModal = () => {
		setShowDownloadModal(!showDownloadModal);
	}

	useEffect(() => {
		dispatch(updateLayout(props.slug));
	}, []);

	useEffect(() => {
		dispatch(updateSearchRSS(`/govinfosearch/search/rss/${location.pathname.slice(12)}`));
	}, [navigate]);

	useEffect(() => {
		try {
			if(qstr) { // apache hack
				JSON.parse(qstr)
				setBadJSON(false)
				dispatch(urlState(qstr.replaceAll('¼','/')));
				dispatch(getResults(JSON.parse((qstr).replaceAll('¼','/'))));
			}
			if(searchReq){
				JSON.parse(searchReq)
				setBadJSON(false)
				dispatch(urlState(searchReq.replaceAll('¼','/')));
				dispatch(getResults(JSON.parse((searchReq).replaceAll('¼','/'))));  
			}else{
				dispatch(getResults(criteria));
			}
		} catch(e){
			setBadJSON(true)
		}
		
	}, [navigate]);

	useEffect(() => {
		// I think, when other error states are identified, then the truthy value for setHasError can be expanded:
		// ex: setHasError(badJSON && badWhatever && badThisOtherThing)
		// or set a variable and use that.
		// TODO: If there is ever only one error page, than hasError can replace badJSON below.
		// Otherwise, it's probably going to need branching or something.
		setHasError(badJSON || apiError);
	}, [badJSON, apiError]);

	useEffect(() => {
		let newTitle = layout.title;
		if (criteria.query != "") {
			newTitle = `${criteria.query} | Search Results`;
		}
		dispatch(updateTitle(newTitle));
		// document.title = (criteria.query != "") ? `${criteria.query} | ${layout.title}` : `${layout.title}`;
	} , [criteria.query]);

	useEffect(() => {
		// runs on location, i.e. route, change
		(window as any).Piwik && (window as any).Piwik.getAsyncTracker().trackLink(window.location.href , 'link')
	  }, [location]);

	const widgetCallback = (urlFrag: string) => {
		navigate('/app/search/' + encodeURIComponent(urlFrag.replaceAll('/', '¼')));
	}

	const isValidJsonUrl = (str:string|undefined) => {
		if(str){
			try{
				JSON.parse(str);
			}catch (e){
			   return false;
			}
		}
		return true;
	}

	return (
		<>
			{
				inprogress
					? <LoadingSpinner size="page" />
					: null
			}

			<PageHeader layout={layout} mainContentLinkRef={mainContentLinkRef} hasSearchWidget={true} widgetCallback={widgetCallback} widgetDefaultOpen={true} searchText={searchtext && isValidJsonUrl(searchtext) ? JSON.parse(searchtext.replaceAll('¼','/')).query :''}/>
			<main className={`search-results${hasError ? " error" : ""}`}>
				{ ( hasError )
					? <NoSearchResults />
					: (
						<>
							<Masthead layout={layout} downloadCallback={closeDownloadModal} />
							<SearchSummary />
							<ResultsContainer />
							<NavigatorMenu/>
						</>
					)
				}
			</main>
			<SiteFooter mainContentLinkRef={mainContentLinkRef} />
			<ModalDownloadResults onClose={closeDownloadModal} show={showDownloadModal} />
			<Toasts />
		</>
	);
}

export default AppSearch;
