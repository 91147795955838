/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import AuthorIcon from "../../assets/icons/browse-by-author-icons.svg?react";

const icons = ["legislative", "executive", "judicial"];

function Icon({ id, inlineSvg, ...props }) {
	return (
		<>
			{
				(inlineSvg)
					? <AuthorIcon id={id} />
					: <svg {...props}>
							<use href={`#${id}`} />
						</svg>
			}
		</>
	)
}

export default Icon;
