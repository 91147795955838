import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from './app/hooks';
import { updateLayout } from "./features/layout/layout-slice";
import { PageHeader, SiteFooter } from '@govinfo/react-components';
import ErrorNotFound from './components/layout/ErrorNotFound';

function AppError({ slug, error }: { slug: string, error: "not-found" }) {
	const mainContentLinkRef = useRef();
	const dispatch = useAppDispatch();
	const layout = useAppSelector((state) => state.layout);
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(updateLayout(`${slug}-${error}`));
	}, []);

	// TODO: This needs to be refactored so any of the App files can use the same function
	const widgetCallback = (urlFrag: string) => {
		navigate('/app/search/' + encodeURIComponent(urlFrag.replaceAll('/', '¼')));
	}

	return (
		<> 
			<PageHeader layout={layout} mainContentLinkRef={mainContentLinkRef} widgetCallback={widgetCallback} hasSearchWidget={true} widgetDefaultOpen={false} />
			<main className='error'>
				{/* TODO: Add additional error types as they are determined, if any. */}
				{/* Formalized error page to match other govinfoapp page/views for consistency, including moving location in folders. */}
				{
					(error === "not-found")
						? <ErrorNotFound />
						: null
				}
			</main>
			<SiteFooter />
		</>
	);
}

export default AppError;