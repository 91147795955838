import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FacetField from "./FacetField";

function FacetsSummary() {
  const facets = useAppSelector((state) => state.criteria.facets);
  const filterOrder = useAppSelector((state) => state.criteria.filterOrder);
  const navigators = useAppSelector((state) => state.results.response.navigators);
  const navChain = [];

  return (
    <aside className="summary-facets">
      <h1 className="summary-title">Refined By:</h1>
      <dl className="summary-facets-list">
        {filterOrder.map((val, index) => {
          if(navigators){
            return navigators?.map((navigator) => {
              if (navigator.name == val) {
                return (
                  <>
                    <dt className="facet-field-group" key={`${navigator.displayName.replaceAll(' ','-')}`} >
                      {(index > 0) ? <i className="summary-facet-next-arrow bi bi-arrow-right"></i> : ''}
                      {navigator.displayName}:
                    </dt>
                    <FacetField facetValues={facets[val]} navigator={navigator} key={`${navigator.displayName.replaceAll(' ','-')}-${index}`}/>
                  </>
                );
              }
            })
          };
        })}
      </dl>
    </aside>
  );
};
export default FacetsSummary
