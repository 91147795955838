import React from "react";
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import urlUtil from '../../utility/urlUtil'
import { useNavigate } from "react-router-dom";



const SelectSortSearchResults = () => {

	const navigate = useNavigate();
	const sortValue = useAppSelector((state) => state.criteria.sortBy)
	const criteria =useAppSelector((state)=> state.criteria)
	const onSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		
		//dispatch(changeSort(e.target.value))
		navigate(`/app/search/${urlUtil.changeSort(criteria,e.target.value)}`)
	}

	return (
		<>
			<label htmlFor="sort-by" className="visually-hidden">Sort By</label>
			<Form.Select onChange={onSortChange}
				id="sort-by"
				className="results-sort-select"
				aria-label="Sort results by"
				value={sortValue? sortValue : "0"}>
				<option value="0">Relevance</option>
				<option value="2">Date New to Old</option>
				<option value="1">Date Old to New</option>
				<option value="3">Alphabetical (A-Z)</option>
				<option value="4">Alphabetical (Z-A)</option>
			</Form.Select>
		</>
	);
}
export default SelectSortSearchResults