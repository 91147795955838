import React, { ReactNode, useState } from 'react';
import Collapse from "react-bootstrap/Collapse";
import LinkHelp from '../navigation/LinkHelp';

function ContentSection(props: {sectionTitle: string, helpRibbon: boolean, helpUrl: string, collapsable: boolean, defaultOpen: boolean, children: ReactNode}) {
	const [ isOpen, setIsOpen ] = useState(props.defaultOpen);
	const toggleOpen = () => {
		setIsOpen(!isOpen);
	}

	return (
		<>
			<h2 className={`section-title ${props.collapsable ? "section-toggle" : ""}`}>
				{
					props.collapsable
					? (
						<>
							<button
								type="button"
								className="btn-section-toggle"
								onClick={toggleOpen}
								aria-expanded={isOpen}
								aria-controls="content-container">
								{props.sectionTitle}
							</button>

							<button
								type="button"
								className="btn-section-toggle-caret"
								onClick={toggleOpen}
								aria-expanded={isOpen}
								aria-controls="content-container">
								<span className="visually-hidden-focusable">Toggle content section</span>
							</button>
						</>
					)
					: props.sectionTitle
				}
			</h2>

			<Collapse in={isOpen}>
				<div id="content-container" className="section-content">
					{props.children}
				</div>
			</Collapse>

			{
				props.helpRibbon
				? (
					<LinkHelp isRibbon={true} helpUrl={props.helpUrl} />
				) : null
			}
		</>
	);
}

export default ContentSection;