import React from 'react';
import { useAppSelector } from '../../app/hooks';

function NoSearchResults() {
	const layout = useAppSelector((state) => state.layout);

	return (
		<article className="message empty-results">
			<h2>
				{/* <span className='icon-stack'>
					<i className="bi bi-file-earmark"></i>
					<i className="bi bi-question"></i>
				</span> */}
				No results found.
			</h2>
			<p>Suggestions:</p>
			<ul>
				<li>Try different keywords or search terms.</li>
				<li>Make sure all words are spelled correctly.</li>
				<li>Get additional <a href={`${layout.help}`}>Search Tips</a>
				</li>
			</ul>
		</article>
	);
}

export default NoSearchResults;