import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from 'axios';

export interface DocItem {
	accode: string[];
	docclass: string;
	index: number;
	lastmodified_s: string;
	packageid: string;
	teaser: string;
	title: string;
	url: string;
}

export interface CuratedResponse {
	docs?: DocItem[];
	maxScore?: number;
	numFound?: number;
	pageSize?: number;
	start?: number;
}

export interface ResponseHeader {
	QTime?: number;
	params?: {
		q?: string;
		start?: number;
	};
	status?: number;
}

export interface CuratedSearchQuery {
	q: string;
	start: number;
}

export interface CuratedSearchState {
	inProgress: boolean;
	query: CuratedSearchQuery;
	response: CuratedResponse;
	responseHeader: ResponseHeader;
	spellcheck: {
		suggestions: string[];
	}
}

const initialState: CuratedSearchState = {
	inProgress: false,
	query: {
		q: "",
		start: 0,
	},
	response: {},
	responseHeader: {},
	spellcheck: {
		suggestions: []
	}
}

export const getResults = createAsyncThunk('results/curatedsearch', (searchPayload: CuratedSearchQuery) => {

	return axios
		.get(`/wcmservices/search?q=${searchPayload.q}&start=${searchPayload.start}`)
		.then(({ data }) => data);
})

const curatedSearchSlice = createSlice({
	name: "curatedSearch",
	initialState,
	reducers: {
		setSearchInputQuery(state, action) {
			state.query.q = action.payload;
			return state;
		},
		setSearchStartQuery(state, action) {
			state.query.start = action.payload;
			return state;
		},
		setPageSize(state, action) {
			state.response.pageSize = action.payload;
			return state;
		}
	},
	extraReducers:(builder) => {
		builder.addCase(getResults.pending, (state, action) => {
				state.inProgress = true;
		 }),
		builder.addCase(getResults.rejected, (state, action) => {
			state.inProgress = false;
			// return errorState
		}),             
		builder.addCase(getResults.fulfilled, (state, action) => {
			// state.apiError=false
			state = action.payload;
			state.query = action.meta.arg;
			state.inProgress = false;

			return state;
		})
	}
});

export const { setSearchInputQuery, setSearchStartQuery, setPageSize } = curatedSearchSlice.actions;
export default curatedSearchSlice.reducer;