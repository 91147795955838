import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from './app/hooks';
import { updateLayout } from "./features/layout/layout-slice";
import { categoryItems, collectionItems } from "./components/data/categoryBrowseNav";
import { PageHeader, SiteFooter } from "@govinfo/react-components";
import TabsCategoryBrowse from "./components/navigation/TabsCategoryBrowse";

// AppCategoryBrowse imports data from ./components/data/categoryBrowseNav to build the tabbed structure
// for displaying the categories and their associated collections.
// To make changes to either categories or the listed collections, edit them in the imported file. 

function AppBrowseCategory({ slug }: { slug: string }) {
	const mainContentLinkRef = useRef();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const layout = useAppSelector((state) => state.layout);
	let { category } = useParams();

	useEffect(() => {
		dispatch(updateLayout(slug));
	}, []);

	// TODO: This needs to be refactored so any of the App files can use the same function
	const widgetCallback = (urlFrag: string) => {
		navigate('/app/search/' + encodeURIComponent(urlFrag.replaceAll('/', '¼')));
	}

	return (
		<>
			<PageHeader layout={layout} mainContentLinkRef={mainContentLinkRef} widgetCallback={widgetCallback} hasSearchWidget={true} widgetDefaultOpen={false} />

			<main className="browse-category">
				<p className="description">{layout.description}</p>
				{
					categoryItems.map((categoryItem, categoryIndex) => {
						const displayCollections = collectionItems.filter((c) => {
							return categoryItem.items.includes(c.slug);
						});
						const isActive = (category && category == categoryItem.slug) || (category == undefined && categoryIndex == 0);
						return (
							<TabsCategoryBrowse
								category={categoryItem}
								collections={displayCollections}
								isActive={isActive}
								key={`${categoryItem.slug}-${categoryIndex}`}
							/>
						)
					})
				}
			</main>

			<SiteFooter mainContentLinkRef={mainContentLinkRef} />
	</>
	)
}
export default AppBrowseCategory;