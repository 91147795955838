import React, { useId } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Form from 'react-bootstrap/Form';
import urlUtil from '../../utility/urlUtil';
import { useNavigate } from "react-router-dom";

const PageSizeSelector = (props: { onChange: (e, pageSize: number) => void, pageSize: number, pageSizeOptions?: number[] }) => {
	const navigate = useNavigate();
	const criteria = useAppSelector((state) => state.criteria);
	const pageSizeSelectID = useId();
	const defaultPages = [10, 50, 100];

	return (
		<>
			<label htmlFor={pageSizeSelectID} className="visually-hidden">Select number of results per page</label>
			<Form.Select
				id={pageSizeSelectID}
				title="Page size select"
				className="results-number-select form-select"
				onChange={(evt) => {
					props.onChange(evt, parseInt(evt.target.value))
					//	navigate(`/app/search/${urlUtil.selectPageSize(criteria, parseInt(evt.target.value) )}`)
				}}
				value={`${props.pageSize}`}
			>

				{
					(props.pageSizeOptions && props.pageSizeOptions.length > 0)
						? props.pageSizeOptions.map((e) => {
								return <option value={e} key={`item-${e}`}> {`${e} per page`}</option>
							})
						: defaultPages.map((e) => {
								return <option value={e} key={`item-${e}`}> {`${e} per page`}</option>
					})


				}

			</Form.Select>
		</>
	);
}

export default PageSizeSelector;
