import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";
import { stat } from "fs";
import { RootState } from "../../app/store";
import { PageDetails, TopicBrowseResponse } from "../../app/browse.types";

/// TODO: replace this with something that determines collection code based on URL (probably)
// const collectionCode = "CMR";

const initialState: TopicBrowseResponse = {
	topicBrowse: [],
	collectionCode: null,
	azLevelNodeIndex: null,
	subjectLevelNodeIndex: null,
	inProgress: false,
	error: null
}

const pageSizeSelectOptions = [100, 200, 500];
const showPaginationMinimumCount = pageSizeSelectOptions[0];

export const getAZLevelResults = createAsyncThunk('topicBrowse/azLevel', (payload, { getState }) => { //levels:{level1:string,level2:string}
	const { topics } = getState() as { topics: TopicBrowseResponse };

	if (!topics.topicBrowse || topics.topicBrowse.length == 0) {
		return axios.get(`/wssearch/tb/${topics.collectionCode}?subject=&navName=conceptsnav`)
			.then(({ data }) => data)
	} else {
		return topics.topicBrowse;
	}
});

export const getSubjectLevelResults = createAsyncThunk('topicBrowse/subjectLevel', (frag: string, { getState }) => {
	const { topics } = getState() as { topics: TopicBrowseResponse };
	const idx = topics.topicBrowse.findIndex(e => e.label == frag)

	if (!topics.topicBrowse[idx].nodes || topics.topicBrowse[idx].nodes.length == 0) {
		return axios.get(`/wssearch/tb/${topics.collectionCode}?subject=${frag}&navName=conceptsnav`)
			.then(({ data }) => {
				return data;
			})
	} else {
		return topics.topicBrowse[idx].nodes;
	}
});

export const getResultsLevel = createAsyncThunk('topicBrowse/resultsLevel', async(page: PageDetails, { getState }) => {
	const { topics } = getState() as { topics: TopicBrowseResponse };
	return axios
		.get(`/wssearch/tb/${topics.collectionCode}/${page.level2}?navName=conceptsnav&offset=${page.offset}&pageSize=${page.pageSize}&sortDirection=${page.sortDirection}`)
		.then(({ data }) => {
			data.pageSize = page.pageSize;
			data.offset = page.offset;
			data.sortDirection = page.sortDirection;
			data.apiError = false;
			return data;
		});
});

const topicsSlice = createSlice({
	name: 'topicBrowse',
	initialState,
	reducers: {
		setCollectionCodeForQuery(state, action) {
			state.collectionCode = action.payload;
			return state;
		},
		toggleAZLevelNode: (state, action) => {
			state.inProgress=false;
			state.azLevelNodeIndex = action.payload;
			return state;
		},
		toggleSubjectLevelNode: (state, action) => {
			state.inProgress=false;
			state.subjectLevelNodeIndex = action.payload;
			return state;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getAZLevelResults.fulfilled, (state, action) => {
			if (state.topicBrowse && state.topicBrowse.length > 0) {
				state.inProgress = false;
				return state;
			}

			return {
				topicBrowse: action.payload.map(element => {
					return {
						label: element,
						nodes: []
					}
				}),
				collectionCode: state.collectionCode,
				azLevelNodeIndex: null,
				subjectLevelNodeIndex: null,
				inProgress: false
			}
		})
		.addCase(getAZLevelResults.pending, (state, action) => {
			state.inProgress = true;
		})
		.addCase(getAZLevelResults.rejected, (state, action) => {
			state.inProgress = false;
		})
		.addCase(getSubjectLevelResults.pending, (state, action) => {
			state.inProgress = true;
		})
		.addCase(getSubjectLevelResults.fulfilled, (state, action) => {
			const idx = state.topicBrowse.findIndex(e => e.label === action.meta.arg)
			state.inProgress = false;
			if (state.topicBrowse[idx] && state.topicBrowse[idx].nodes && state.topicBrowse[idx].nodes.length > 0) {
				state.azLevelNodeIndex = idx
				return state;
			}

			state.topicBrowse[idx].nodes = action.payload.map(element => {
				return {
					label: element,
					results: []
				}
			});
			state.azLevelNodeIndex = idx;
			state.subjectLevelNodeIndex = null;
			return state;
		})
		.addCase(getResultsLevel.pending, (state, action) => {
			state.inProgress = true;
		})
		.addCase(getResultsLevel.fulfilled, (state, action) => {
			state.inProgress = false;
			const idx = state.topicBrowse.findIndex(e => e.label === action.meta.arg.level1);
			const idx2 = state.topicBrowse[idx].nodes.findIndex(e => e.label === action.meta.arg.level2);
			state.topicBrowse[idx].nodes[idx2] = {
				label: action.meta.arg.level2,
				results: action.payload.childNodes.map(c => c.nodeValue),
				sortDirection: parseInt(action.payload.nodeValue.sortDirection),
				apiError: action.payload.nodeValue.apiError,
				nodeValue: action.payload.nodeValue,
				books: action.payload.nodeValue.books,
				showPagination: (action.payload.nodeValue.count > showPaginationMinimumCount),
				pagination: {
					pageCount: action.payload.nodeValue.count,
					pageSize: action.payload.nodeValue.pageSize,
					offset: action.payload.nodeValue.pageNumber,
					pageSizeOptions: pageSizeSelectOptions
				}
			};
			state.subjectLevelNodeIndex = idx2;
			return state;
		})
		.addCase(getResultsLevel.rejected, (state, action) => {
			state.inProgress = false;
			state.error = action.error.message;
			return state;
		});
	}
})

const { actions, reducer } = topicsSlice;
export default reducer;
export const { setCollectionCodeForQuery, toggleAZLevelNode, toggleSubjectLevelNode } = topicsSlice.actions;