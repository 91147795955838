import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { getBrowseResults } from './features/collectionbrowse/collection-browse-slice';
import browseUtils from "./utility/browseUtils";
import { LoadingSpinner, PageHeader, SiteFooter } from "@govinfo/react-components";
import PageDescription from "./components/layout/PageDescription";
import RelatedResources from "./components/layout/RelatedResources";
import ButtonDownloadCMRTable from "./components/navigation/ButtonDownloadCMRTable";
import Toasts from "./components/popups/Toasts";
import { createBrowseTree } from './utility/createBrowseTree'
import { getCollectionData, updateLayout, updatePageSizes } from "./features/layout/layout-slice";

function AppBrowseCollection({ slug }: { slug: string }) {
	const mainContentLinkRef = useRef();
	let navigate = useNavigate();
	let { collection } = useParams();
	let [ searchParams, setSearchParams ] = useSearchParams();
	collection = collection?.toLowerCase();
	const dispatch = useAppDispatch();
	const browseState = useAppSelector((state) => state.collections);
	const layoutState = useAppSelector((state) => state.layout);
	const [browsePath, setBrowsePath] = useState("");
	const [accode, setAccode] = useState(null);
	const [sort, setSort] = useState(1);
		
	const widgetCallback = (urlFrag: string) => {
		navigate('/app/search/' + encodeURIComponent(urlFrag.replaceAll('/', '¼')));
	}

	useEffect(() => {
		dispatch(updateLayout(slug)); // generic slug for collection browse pages' layout
	}, []);

	useEffect(() => {
		dispatch(updatePageSizes(collection));
	}, []);

	useEffect(() => {
		dispatch(getCollectionData(collection));
	}, []);

	useEffect(() => {
		setBrowsePath(browseUtils.getCollectionBrowsePath());
		setAccode(collection);
		let browseQuery = {
			browsePath: browseUtils.getCollectionBrowsePath(),
			childrenOnly: 0,
			sortDirection: 1
		}
		if (collection == "budget" && searchParams.get("sortDirection")) {
			browseQuery.sortDirection = parseInt(searchParams.get("sortDirection"));
		}
		dispatch(
			getBrowseResults(browseQuery)
		);
	}, [navigate]);

	return (
		<>
			<PageHeader
				layout={layoutState}
				mainContentLinkRef={mainContentLinkRef}
				hasSearchWidget={true}
				widgetCallback={widgetCallback} widgetDefaultOpen={false}
			>
				{
					(collection.toLowerCase() == "ccal")
						? <aside className="masthead-button-group">
								<a className="btn-ui" href="/link/CCAL/latest/senate">Today's Senate Calendar</a>
								<a className="btn-ui" href="/link/CCAL/latest/house">Today's House Calendar</a>
							</aside>
						: null
				}
				{
					(collection.toLowerCase() == "cmr")
						? <aside className="masthead-button-group">
								<a className="btn-ui" href="/app/browse/topic/cmr/">Browse by Topic</a>
								<ButtonDownloadCMRTable />
							</aside>
						: null
				}
				{
					(collection.toLowerCase() == "crec")
						? <aside className="masthead-button-group">
							<a className="btn-ui" href="/link/crec/section/dailydigest">Today's Daily Digest</a>
							<a className="btn-ui" href="/app/search/%7B%22query%22%3A%22crtype%3A(ddresumeongoing%20or%20ddresumefinal)%20or%20title%3A(Interim%20Resume%20of%20Congressional%20Activity)%22%2C%22offset%22%3A0%2C%22sortBy%22%3A2%7D">
								Resume of Congressional Activity
							</a>
						</aside>
						: null
				}
			</PageHeader>

			<PageDescription/>

			{
				(layoutState.apiInProgress || (browseState.inProgress && browseState.childNodes.length == 0))
					? <LoadingSpinner size="page"/>
					: null
			}

			<main key={`containerKey-${browseState.activeKeys}`}>
				<h2 className="section-title">{`Browse ${layoutState.hasTheInHeading ? "the " : ""}${layoutState.title.replace("- Browse", "")}`}</h2>
				<Fragment>
					{
						createBrowseTree(browseState.childNodes, accode, navigate, null, sort, setSort)
					}
				</Fragment>
			</main>

			<RelatedResources/>

			<SiteFooter mainContentLinkRef={mainContentLinkRef}/>
			<Toasts/>
		</>
	);
}

export default AppBrowseCollection;