import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import LinkHelp from '../navigation/LinkHelp';
import CuratedSearchForm from './CuratedSearchForm';
import SearchSummaryHeader from '../searchResults/SearchSummaryHeader';
import SearchSummaryDetails from '../searchResults/SearchSummaryDetails';

function CuratedSearchSummary() {
	const curatedState = useAppSelector((state) => state.curatedSearch);
	const layout = useAppSelector((state) => state.layout);

	return (
		<section id="page-summary" className="search-summary curated">
			<LinkHelp isRibbon={true} helpUrl={layout.help} />

			<CuratedSearchForm />

			{/* TODO: replace clearQueryUrl string with correct path. Maybe get path from router
			*/}

			{
				((curatedState.responseHeader.params != undefined))
					? <SearchSummaryHeader
							searchQuery={ curatedState.responseHeader.params.q }
							clearQueryUrl="/app/search/curated"/>
					: null
			}

			{
				(curatedState.response != null)
					? <>
							<hr />

							<SearchSummaryDetails
								recordCount={ (curatedState.response.numFound != null) ? curatedState.response.numFound.toString() : "0" }
								hasHistoricalToggle={false}
								hasSortSelect={false}
							/>

							<hr />
						</>
					: null
			}
		</section>
	)
}

export default CuratedSearchSummary;