export default {
	 htmlToMarkdown(convertString:string) {
		// This is assuming the only HTML coming in is <b></b>
		// This should also, ideally, be handled on the backend by the API
		const bTagRegExp = new RegExp("<\/?b>", "g");
		let convertedString = convertString.replaceAll(bTagRegExp, "**");
		// for browse <span> tags are coming
		const spanTagRegExp = new RegExp("<\/?span[^>]*>", "g");
		convertedString = convertedString.replaceAll(spanTagRegExp, "");
		
		return convertedString
	},

	slugify(path) {
		let slug = path.replaceAll(/\//g,'-').replaceAll(/\s/g,'');
		return slug;
	}
}