import React, { useState, useEffect, useRef } from "react";
import Accordion from 'react-bootstrap/Accordion';
// import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
// import { NodeValue, LeafNodeValue } from "../../app/browse.types";
import { BrowseHeaderContent } from "../../app/browse.types";
import BrowseHeader from "./BrowseHeader";
import BrowseHeaderFacncy from "./BrowseHeaderFancy";

type BrowseNodeProps = {
	handleExpandLevel: Function,
	label: string,
	itemKey: string,
	itemEventKey: string,
	hasFancyHeader?: boolean,
	headerContent?: BrowseHeaderContent,
	children?: React.ReactNode,
}

function BrowseNode(props: BrowseNodeProps) {

	return (
		<>
			{
				<Accordion.Item as="article" eventKey={props.itemEventKey} key={props.itemKey}>
					<BrowseHeader label={props.label} onClick={(evt) => {
						evt.preventDefault();
						props.handleExpandLevel(props.label);
					}} />
					<Accordion.Body>
						{ props.children }
					</Accordion.Body>
				</Accordion.Item>
			}
		</>
	);
}

export default BrowseNode;