import React, { Fragment, useState, useEffect, useRef } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getBrowseResults, removeActiveKey, removeVisitedKey, addActiveNodeKey } from "../../features/collectionbrowse/collection-browse-slice";
import browseUtils from "../../utility/browseUtils";
import textUtils from "../../utility/textUtils";
import Accordion from 'react-bootstrap/Accordion';
import { LoadingSpinner } from "@govinfo/react-components";
import BrowseHeader from "./BrowseHeader";
import BrowseHeaderFancy from "./BrowseHeaderFancy";
import BrowseNode from "./BrowseNode";
import ResultItem from "../navigation/ResultItem";
import { BrowseHeaderContent } from "../../app/browse.types";
import { getResults } from "../../features/search/curated-search-slice";
// import { getGranuleResults } from "../../features/collectionbrowse/collection-browse-slice";

function BrowseGranules(props: any) {
	let navigate = useNavigate();
	let dispatch = useAppDispatch();
	const browseState = useAppSelector((state) => state.collections);
	const layoutState = useAppSelector((state) => state.layout);
	let { collection } = useParams();
	collection = collection?.toLowerCase();
	const nodeBrowsePathAlias = `${props.node.nodeValue.browsePathAlias}/${props.node.nodeValue.packageid}`;
	const slug = textUtils.slugify(nodeBrowsePathAlias);
	let headerLine1 = props.node.nodeValue.granuleline1;
	let headerLine2 = (props.node.nodeValue.packageline2) ? textUtils.htmlToMarkdown(props.node.nodeValue.packageline2) : "";
	let headerRenditions = browseUtils.getRenditions(props.node.nodeValue, collection);
	let headerDetails = browseUtils.getDetailsLink(props.node.nodeValue);
	let thumbnail = browseUtils.getThumbnail(props.node.nodeValue);
	let headerDescription = (props.node.nodeValue.desc && thumbnail !== "") ? props.node.nodeValue.desc : "";
	let hasFancyHeader = (headerRenditions.length > 0) ||
											 (headerLine2 !== "") ||
											 (headerDetails !== "") ||
											 ((headerDescription !== "") && (thumbnail !== ""));
	
	function handleExpandPackage(slug: string, pathAlias: string, node: ChildNode) {
		if (node.childNodes.length == 0 && browseState.visitedKeys.includes(slug)) {
			dispatch(removeVisitedKey(slug));
		}
		if (browseState.activeKeys.includes(slug)) { // If the node is active (open), remove its key from the active node list.
			dispatch(removeActiveKey(slug));
		} else if (browseState.visitedKeys.includes(slug)) { // If the node is not active (not open) but has been visited before, add its key to the active node list again.
			dispatch(addActiveNodeKey(slug));
			console.log(node.childNodes.length, pathAlias);
		} // else {
			// console.log(slug, pathAlias, browseState.activeKeys.includes(slug));
			// if (!browseState.visitedKeys.includes(slug)) { // If node has not been visited, navigate to opened/active node
				if (layoutState.paginationEnabled) { // If this collection has pagination, collect the necessary info and navigate
					let sortInfo = browseUtils.getCollectionBrowseSortInfo();
					sortInfo.pageSize = layoutState.pageSizes[0]
					sortInfo.offset = 0
					navigate(`/app/collection/${collection}/${pathAlias}/${JSON.stringify(sortInfo)}`);
				} else { // otherwise, just navigate
					navigate(`/app/collection/${collection}/${pathAlias}`);
				}
			// }
		// }
	}
	
	return (
		<Accordion.Item as="article" eventKey={`${slug}`} key={`item-${slug}-${props.node.index}`}>
			{
				(hasFancyHeader)
					? <BrowseHeaderFancy
							label={{
								line1: headerLine1,
								line2: headerLine2,
								thumbnail: thumbnail,
								renditions: (headerRenditions.length > 0) ? headerRenditions : [],
								description: headerDescription
							}}
							slug={slug}
							url={headerDetails}
							showShare={true}
							onClick={(evt) => {
								evt.preventDefault();
								handleExpandPackage(slug, nodeBrowsePathAlias, props.node);
							}}
							key={`head-${slug}`}
						/>
					:	<BrowseHeader
							label={props.node.nodeValue.granuleline1}
							onClick={(evt) => {
								evt.preventDefault();
								handleExpandPackage(slug, nodeBrowsePathAlias, props.node);
							}}
							key={`head-${slug}`}
						/>
			}
			<Accordion.Body key={`body-${slug}`}>
				{
					(!browseState.activeKeys.includes(slug) && browseState.inProgress)
						? <LoadingSpinner size="node" key={`spinner-${slug}`} />
						: <Fragment key={`fragment-${slug}`}>
								{
									props.node.childNodes.map((granule, index, nodes) => {
										const itemRenditions = browseUtils.getRenditions(granule.nodeValue, collection);
										const isSubTitle = (itemRenditions.length == 0) && (granule.nodeValue.level > 2);

										return (
											<Fragment key={`fragment-${slug}-${granule.nodeValue.granuleid}`}>
												{
													(itemRenditions.length == 0)
														? <h2 className={`section${(isSubTitle) ? "-sub-" : "-"}title`} key={`title-${granule.nodeValue.title}-${granule.nodeValue.index}`}>{granule.nodeValue.title}</h2>
														: <ResultItem
																line1={granule.nodeValue.title}
																packageid={granule.nodeValue.packageid}
																url={browseUtils.getDetailsLink(granule.nodeValue)}
																renditions={itemRenditions}
																showShare={true}
																hasThumbnail={false}
																className={`level-${granule.nodeValue.level}`}
																key={`item-${granule.nodeValue.title}-${index}`}
															/>
												}
											</Fragment>
										)
									})
								}
							</Fragment>
				}
			</Accordion.Body>
		</Accordion.Item>
	);
}

export default BrowseGranules;