import React, { useState } from "react";
// import { ResultLineItem } from "../../features/search/search-results-slice";
import { useAppSelector } from '../../app/hooks';
import { ShareDetailsType, Icon } from "@govinfo/react-components";
import ButtonDetails from "./ButtonDetails";
import ButtonDetailsShare from "./ButtonDetailsShare";

type RenditionButtonGroupProps = {
	details?: ShareDetailsType,
	renditions?: {url: string, format: string}[],
	showShare?: boolean,
	shareDetails?: ShareDetailsType // only send shareDetails if different than details (eg FRTOC page)
}

function formatRenditionButtonLabel(format: string) {
	format = format.toLowerCase();
	let label = (format == "txt" || format == "htm") ? "text" : format;

	return label;
}

const RenditionButttonGroup = (props: RenditionButtonGroupProps) => {
	const [ showButtons, setShowButtons ] = useState(false);
	const layout = useAppSelector((state) => state.layout);

	const toggleButtons = (isOpen: boolean) => {
		setShowButtons(isOpen);
	}

	function getRenditionButton(format:string, url){
		return (
			<ButtonDetails
				key={`${url}`}
				url={url}
				label={formatRenditionButtonLabel(format)}
				onClick={(layout.slug === "search-results") ? () => toggleButtons(false) : null}
			/>
		)
	}

	function getRenditionButtons(){
		let itemsRendition = {};
		
		props.renditions.forEach(r=>{
			itemsRendition[r.format]=r.url
		})
		let buttons: React.JSX.Element[] = []
		for (const button in itemsRendition) {
			switch (button) {
				case 'text':
					buttons.push(getRenditionButton('text',itemsRendition['text']))
					break;					
				case 'txt':
					buttons.push(getRenditionButton('text',itemsRendition['txt']))
					break;					
				case 'htm':
					buttons.push(getRenditionButton('text',itemsRendition['htm']))
					break;					
				default:
					buttons.push(getRenditionButton(button,itemsRendition[button]))						
			}
		}
		return buttons
	}

	function reditionSort() {
		const formatOrder = ['pdf', 'text', 'txt','htm', 'xml', 'uslm'];

		props.renditions.sort((a, b) => {
			const formatAIndex = formatOrder.indexOf(a.format);
			const formatBIndex = formatOrder.indexOf(b.format);

			// Objects with preset format come first
			if (formatAIndex !== -1 && formatBIndex === -1) {
				return -1;
			} else if (formatAIndex === -1 && formatBIndex !== -1) {
				return 1;
			}
			// Order by formatOrder
			return formatAIndex - formatBIndex;
			});
	}

	reditionSort();

	return (
		<aside className="rendition-button-group">
			{
				(layout.template === "search-results")
					? <button
							type="button"
							className={`btn-details-view${showButtons ? " expand" : ""}`}
							onClick={() => toggleButtons(!showButtons)}
							onBlur={(evt) => {
								if ((evt.relatedTarget === null) || ((evt.relatedTarget) && (evt.relatedTarget.className !== "btn-details"))) {
									toggleButtons(false);
								}
							}}
						>
							View <Icon id="caret-up" />
						</button>
					: null
			}

			<p>
				{ getRenditionButtons() }

				{
					(props.details.url && props.details.url !== "")
						? <ButtonDetails
								key={`details-${props.details.url}`}
								url={props.details.url}
								label="details"
							/>
						: null
				}
			</p>

			{
				(props.showShare)
					? <ButtonDetailsShare details={(props.showShare && props.shareDetails) ? props.shareDetails : props.details} />
					: null
			}
		</aside>
	);
};

export default RenditionButttonGroup;
