import React, { useState } from "react";
import { ResultLineItem } from "../../features/search/search-results-slice";
import ModalImage from "../popups/ModalImage";

const ResultItemFigure = ({thumbnailSrc, fullSizeSrc, details}:{thumbnailSrc:string, fullSizeSrc?:string, details: { url: string, title: string, subtitle?: string }}) => {
	const [showModal, setShowModal] = useState(false);
	const closeModal = () => {
		setShowModal(!showModal);
	};

	// /wssearch/getthumbnail/SERIALSET-11619_03_0b-002-0570-0003/thumbnails/SERIALSET-11619_03_0b-002-0570-0003-0003.gif
	// /wssearch/getthumbnail/PPP-PHOTOS-2011-book2/graphics/PPP-PHOTOS-2011-book2-folio-1.jpg
	// /content/pkg/PPP-PHOTOS-2011-book2/graphics/PPP-PHOTOS-2011-book2-folio-1.jpg

	return (
		<figure className="thumbnail result-item-thumbnail">
			<img
				className="image-search-results"
				onClick={() => {
					setShowModal(true);
				}}
        src={thumbnailSrc}
				alt={details.title}
			/>

			<ModalImage
				show={showModal}
				onClose={closeModal}
				details={details}
				thumbnailSrc={thumbnailSrc}
				fullSizeSrc={fullSizeSrc}
				// imageData={((fullSizeSrc != null) ? fullSizeSrc : thumbnailSrc)}
			/>
		</figure>
	);
};

export default ResultItemFigure;
