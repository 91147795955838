import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Facets {
  [facetName: string]: string[];
}

export interface SearchQuery {
  facets: Facets;
  filterOrder: string[];
  facetToExpand: string;
  offset: number;
  pageSize: number;
  sortBy: string;
  query: string;
  historical: boolean;
  facetCustomSorts?: any;
  isLoading: boolean;
}

const initialState: SearchQuery = {
  query: "",
  offset: 0,
  pageSize: 10,
  historical: false,
  facetToExpand: "non_existing_facet",
  facets: {},
  filterOrder: [],
  sortBy: "0",
  isLoading: false,
};



const criteriaSlice = createSlice({
  name: "search",
  initialState,
  reducers: {

    urlState(state, {payload}:{payload:string}){
      return JSON.parse(payload.replaceAll('¼','/'));
    }

  },
});

export const {
  urlState
} = criteriaSlice.actions;
export default criteriaSlice.reducer;
