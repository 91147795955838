import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
// import { ChildNode } from "../../app/browse.types";
import { ChildNode } from "../../features/collectionbrowse/collection-browse-slice";
import PaginationControls, { PaginationControlsProps } from "../navigation/PaginationControls";
import ResultItem from "../navigation/ResultItem";
import BrowseGranules from "./BrowseGranules";
import browseUtils from "../../utility/browseUtils";

type CollectionBrowseProps = {
	results: ChildNode[],
	sortDirection: number,
	sortHandler: (e) => void,
}

type BrowsePaginationProps =
	| {
		showPagination: boolean,
		pagination?: PaginationControlsProps
	}

type CollectionBrowseResultsProps = CollectionBrowseProps & BrowsePaginationProps;

/*
 NOTE: Just checking the data returned for a given node is not sufficient to determine which nodes should have a fancy header (with a thumbnail and teaser text). The behavior is inconsistent.
 We may need to refactor and consolidate our REST endpoints to properly address these kinds of inconsistencies.
 As a temporary fix, I am introducing a list of collections where nodes should have a fancy header with a thumbnail and teaser text, but only if the corresponding data is available.
 */
const COLLECTIONS_WITH_GRANULES_AND_THUMBNAIL = ['budget'];

function CollectionBrowseResults(props: CollectionBrowseResultsProps) {
	const { collection } = useParams();
	// TODO: these two consts should get elevated for use app-wide 
	const contentDetailsBaseUrl = "/app/details/";
	const renditionBaseUrl = "/content/pkg/";
	const getSortClass = (): string => {
	const sortIcon = collection.toLowerCase() == 'cmr' ? 'date' : 'alpha';
	const sortDirection = props.sortDirection == undefined || props.sortDirection == 1 ? "desc" : "asc";
		return `${sortIcon}-${sortDirection}`;
	}
	const showGranules = (result: ChildNode) => {
		return result.nodeValue.hasgranules && result.nodeValue.hasgranules === "true" &&
			result.nodeValue.granuleid === "" && COLLECTIONS_WITH_GRANULES_AND_THUMBNAIL.includes(collection.toLowerCase());
	}

	const showBroseNodeThumbnail = (result: ChildNode) => {
		const thumbnail = browseUtils.getThumbnail(result.nodeValue);
		const showThumbnail = thumbnail !== "" && result.nodeValue.desc
			&& result.nodeValue.desc !== "" && COLLECTIONS_WITH_GRANULES_AND_THUMBNAIL.includes(collection.toLowerCase());
		return {showThumbnail, thumbnail};
	}
	const getTesearText = (result: ChildNode) => {
		return (result.nodeValue.desc && COLLECTIONS_WITH_GRANULES_AND_THUMBNAIL.includes(collection.toLowerCase())) ? result.nodeValue.desc : "";
	};

	return (
		<>
			{
				(props.showPagination)
					? <PaginationControls
							showPageSelector={true}
							offset={props.pagination.offset}
							pageCount={props.pagination.pageCount}
							pageSize={props.pagination.pageSize}
							next={props.pagination.next}
							prev={props.pagination.prev}
							gotoPage={props.pagination.gotoPage}
							onPageSizeChange={props.pagination.onPageSizeChange}
							pageSizeOptions={props.pagination.pageSizeOptions}
						/>
					: null
			}

			{
				/* TODO: CSS class for sort button hard-coded to alpha.
					Need to pass or otherwise determing sort type for collection.
				*/
				(props.results.length > 1)
					? <p className="browse-item-sort">
							<button
								type="button"
								className={`btn-browse-sort ${getSortClass()}`}
								onClick={(evt) => {
									props.sortHandler(evt);
								}}
								>
								Sort
							</button>
						</p>
					: null
			}

			{
				props.results.map((result, index) => {
					const itemRenditions = browseUtils.getRenditions(result.nodeValue, collection);
					// let thumbnail = browseUtils.getThumbnail(result.nodeValue);
					// let showThumbnail = thumbnail !== "" && result.nodeValue.desc && result.nodeValue.desc !== "";
					const {showThumbnail, thumbnail} = showBroseNodeThumbnail(result);

					let resultTitle = (result.nodeValue.browseline1)
					? result.nodeValue.browseline1
					: (result.nodeValue.title)
						? result.nodeValue.title
						: "";
					let resultSubTitle = (result.nodeValue.packageline2)
						? result.nodeValue.packageline2
						: (result.nodeValue.browseline2)
							? result.nodeValue.browseline2
							: "";

					/// HACK: This is a quick fix, but needs to be better thought out
					/// CMR, BUDGET, HMAN, SMAN, CPD, CFRPARTS, FR all use the title propery
					/// CREC uses the value property
					/// Some of those collections may have their own AppBrowseCollection and slice files, so may not be a huge deal
					/// Noting for future reference
					if (collection.toLowerCase() == "cmr") {
						resultTitle = result.nodeValue.title;
						resultSubTitle = result.nodeValue.publishdate;
					}

					// let showGranulesNode = result.nodeValue.hasgranules &&
					// 											 result.nodeValue.hasgranules === "true" &&
					// 									 		 result.nodeValue.granuleid === "";
					
					return (
						// (showGranulesNode)
						(showGranules(result))
							? <BrowseGranules node={result} fetchChildren={1} key={`${result.nodeValue.title}-${index}`} />
							: <ResultItem
									line1={resultTitle}
									line2={resultSubTitle}
									teaser={getTesearText(result)}
									packageid={result.nodeValue.packageid}
									url={`${contentDetailsBaseUrl}${result.nodeValue.packageid}${(result.nodeValue.granuleid) ? `/${result.nodeValue.granuleid}` : ''}`}
									// url={`${contentDetailsBaseUrl}${result.nodeValue.packageid}`}
									renditions={itemRenditions}
									showShare={true}
									hasThumbnail={showThumbnail}
									thumbnailSrc={(showThumbnail) ? thumbnail : null}
									key={`${result.nodeValue.browseline1}-${index}`}
								/>
					)
				})
			}
			{
				(props.showPagination)
					? <PaginationControls
							showPageSelector={true}
							offset={props.pagination.offset}
							pageCount={props.pagination.pageCount}
							pageSize={props.pagination.pageSize}
							next={props.pagination.next}
							prev={props.pagination.prev}
							gotoPage={props.pagination.gotoPage}
							onPageSizeChange={props.pagination.onPageSizeChange}
							pageSizeOptions={props.pagination.pageSizeOptions}
						/>
					: null
			}
		</>
	)
}

export default CollectionBrowseResults;
