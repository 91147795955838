import React,{useState} from 'react';
import ModalDownloadCMRTable from '../popups/ModalDownloadCMRTable';

function ButtonDownloadCMRTable() {
	const [showModal, setShowModal] = useState(false);
	const closeModal = () => {
		setShowModal(!showModal);
	}
	return (
		<>
			<button type="button" className="btn-ui"
				onClick={() => {
					setShowModal(true);
				}}
			>
				Download a Table of Congressionally Mandated Reports
			</button>

			<ModalDownloadCMRTable show={showModal} onClose={closeModal} />
		</>
	);
}

export default ButtonDownloadCMRTable;