import React, { useState, useEffect } from "react";
import {Navigator} from '../../features/search/search-results-slice'
import Collapse from 'react-bootstrap/Collapse';
import ButtonSort from "./ButtonSort";
import NavigatorToggle from "./NavigatorToggle";
import NavigatorFacet from './NavigatorFacet'
import ButtonShowMoreLess from "./ButtonShowMoreLess";
import urlUtil from '../../utility/urlUtil'
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

const NavigatorPanel = ({navigator}:{navigator:Navigator}) => {
	const navigatorName = `${navigator.displayName.toLowerCase().replaceAll(" ", "-")}`;
	const bucketLimit = 5;
	const [ showNavigatorPanel, setShowNavigatorPanel ] = useState(true);
	const criteria = useAppSelector((state)=> state.criteria)
	const navigate = useNavigate();
	const toggleNavigatorPanel = () => {
		setShowNavigatorPanel(!showNavigatorPanel);
	};

	const [activeSort, setActiveSort] = useState(() => {
		if (navigator.customSortEnabled) {
			return navigator.customSortName;
		}
		return  navigator.name == 'publishdatehier' ? 'date': 
		navigator.name == 'accodenav' ? 'alphabetical': 
		navigator.name == 'governmentauthornav' ? 'alphabetical': 
		navigator.name == 'companiesnav' ? 'alphabetical': 
		navigator.name == 'personnamesnav' ? 'alphabetical': 
		'count';
	});

	const toggleSort = ((whichSort:string) => {
		setActiveSort(whichSort);

		if(navigator.name == 'accodenav' && whichSort=='alphabetical'){
			navigate(`/app/search/${urlUtil.removeCustomSort(criteria, navigator.name)}`)
		}else
		if(navigator.name == 'publishdatehier' && whichSort=='date'){
			navigate(`/app/search/${urlUtil.removeCustomSort(criteria, navigator.name)}`)
		}else
		if(navigator.name == 'governmentauthornav' && whichSort=='alphabetical'){
			navigate(`/app/search/${urlUtil.removeCustomSort(criteria, navigator.name)}`)
		}else
		if(navigator.name == 'companiesnav' && whichSort=='alphabetical'){
			navigate(`/app/search/${urlUtil.removeCustomSort(criteria, navigator.name)}`)
		}else
		if(navigator.name == 'personnamesnav' && whichSort=='alphabetical'){
			navigate(`/app/search/${urlUtil.removeCustomSort(criteria, navigator.name)}`)
		}else
		if(whichSort=='count'){
			if(navigator.name == 'personnamesnav' || navigator.name == 'accodenav' || navigator.name == 'publishdatehier' || navigator.name == 'companiesnav' || navigator.name == 'governmentauthornav'){
				navigate(`/app/search/${urlUtil.addCustomSort(criteria, navigator.name)}`)
			}else{
				navigate(`/app/search/${urlUtil.removeCustomSort(criteria, navigator.name)}`)
			}
		}else{
			navigate(`/app/search/${urlUtil.addCustomSort(criteria, navigator.name)}`)
		}
	});
	const [showMoreLessToggle, setShowMoreLessToggle] = useState(
		(navigator.buckets.length > bucketLimit) ? "Less" : "More"
	);
	useEffect(() => {
		setShowMoreLessToggle((navigator.buckets.length > bucketLimit) ? "less" : "more");
	}, [navigator.buckets.length]);
	return(
		<article id={`navigator-panel-${navigatorName}`}
			className="navigator-panel">
			<header id={`navigator-panel-header-${navigatorName}`}
				className="navigator-panel-heading">
				<NavigatorToggle
					toggleOnClick={toggleNavigatorPanel} 
					toggleState={showNavigatorPanel}>
					{navigator.displayName}
				</NavigatorToggle>

				<ButtonSort
					type={navigator.customSortName}
					activeSort={activeSort}
					toggleSort={toggleSort} />
					 | 
					 { navigator.name == 'accodenav' ? 
						<ButtonSort type="alphabetical"
						activeSort={activeSort}
						toggleSort={toggleSort} /> :
						navigator.displayName == 'Date Published' ?
						<ButtonSort type="date"
						activeSort={activeSort}
						toggleSort={toggleSort} />: 						
						navigator.name == 'governmentauthornav' ?
						<ButtonSort type="alphabetical"
						activeSort={activeSort}
						toggleSort={toggleSort} />: 						
						navigator.name == 'companiesnav' ?
						<ButtonSort type="alphabetical"
						activeSort={activeSort}
						toggleSort={toggleSort} />: 
						navigator.name == 'personnamesnav' ?
						<ButtonSort type="alphabetical"
						activeSort={activeSort}
						toggleSort={toggleSort} />: 
						<ButtonSort type="count"
						activeSort={activeSort}
						toggleSort={toggleSort} />
					}
			</header>
			<Collapse
				
				in={showNavigatorPanel}>
				<section
					className=""
					aria-labelledby={`navigator-panel-header-${navigatorName}`}>
					<ul className="navigator-list-group">
						{
							navigator.buckets.map((facet, index) => {
								return(
									<li className={`navigator-list-item`} key={index}>
										<NavigatorFacet navigator={navigator.name} facet={facet}/>
									</li>
								)
							})
						}
					</ul>
					{
						// hasMore is false when all facets available are in the bucket
						// Still need to show button when all the facets > default display limit
						// TODO: May need to tweak this logic
						((navigator.hasMore) || (navigator.buckets.length > bucketLimit)) && (
							<p>
								<ButtonShowMoreLess navigator={navigator}/>
							</p>
						)
					}
				</section>
			</Collapse>
		</article>
	);
     
}

export default NavigatorPanel