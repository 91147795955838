import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LeafNodeValue } from "../../app/browse.types";
import PaginationControls, { PaginationControlsProps } from "../navigation/PaginationControls";
import ResultItem from "../navigation/ResultItem";

type BrowseCommonProps = {
	results: LeafNodeValue[],
	sortDirection: number,
	sortHandler: (e) => void,
}

type BrowsePaginationProps =
	| {
		showPagination: true,
		pagination: PaginationControlsProps
	}
	| {
		showPagination: false,
		pagination: never
	};

type BrowseResultsProps = BrowseCommonProps & BrowsePaginationProps;

function BrowseResults(props: BrowseResultsProps) {
	const contentDetailsBaseUrl = "/app/details/";
	const renditionBaseUrl = "/content/pkg/";

	return (
		<>
			{
				props.showPagination
					? <PaginationControls
							showPageSelector={true}
							offset={props.pagination.offset}
							pageCount={props.pagination.pageCount}
							pageSize={props.pagination.pageSize}
							next={props.pagination.next}
							prev={props.pagination.prev}
							gotoPage={props.pagination.gotoPage}
							onPageSizeChange={props.pagination.onPageSizeChange}
							pageSizeOptions={props.pagination.pageSizeOptions}
						/>
					: null
			}
			{/* TODO: Make into component and/or fold into navigator sort button component */}
			{
				<p className="browse-item-sort">
					<button
						className={`btn-browse-sort date-${props.sortDirection == 1 ? "asc": "desc"}`}
						onClick = {props.sortHandler}>
						Sort
					</button>
				</p>
			}
			{
				props.results.map((result, index) => {
					const nodeRenditions = Object.keys(result).filter((r) => r.includes("file"));
					const itemRenditions = [];
					
					if (nodeRenditions.length > 0) {
						nodeRenditions.forEach((f) => {
							const contentType = result[f].split(".")[1];
							if (contentType != "gif") {
								itemRenditions.push({
									url: `${renditionBaseUrl}${result[f]}`,
									format: contentType
								});
							}
						})
					}
					
					return (
						<ResultItem
							line1={result.browseline1}
							line2={`${(result.browseline2) ? result.browseline2 : ""}`}
							packageid={result.packageid}
							// url={`${contentDetailsBaseUrl}${result.packageid}${result.granuleid ? `/${result.granuleid}` : ''}`}
							url={`${contentDetailsBaseUrl}${result.packageid}`}
							renditions={itemRenditions}
							showShare={true}
							hasThumbnail={false}
							key={`${result.browseline1}-${index}`}
						/>
					)
				})
			}
			{
				props.showPagination
					? <PaginationControls
							showPageSelector={false}
							offset={props.pagination.offset}
							pageCount={props.pagination.pageCount}
							pageSize={props.pagination.pageSize}
							next={props.pagination.next}
							prev={props.pagination.prev}
							gotoPage={props.pagination.gotoPage}
							onPageSizeChange={props.pagination.onPageSizeChange}
							pageSizeOptions={props.pagination.pageSizeOptions}
						/>
					: null
			}
		</>
	)
}

export default BrowseResults;