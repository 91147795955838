import React, { useEffect, useState, useRef } from 'react';
import { useAppSelector } from '../../app/hooks';
import { AnimationRefs, handleScroll, scrollToPosition } from '../../utility/scrollUtil';

const ButtonCollapseAll = (props: any) => {
	const [ scrollPosition, setScrollPosition ] = useState(window.scrollY);
	const layout = useAppSelector((state) => state.layout);
	const animRefs = useRef<AnimationRefs>({
		requestAnimationRef: 0,
		duration: 800, // time in milliseconds
		currentIteration: 0,
		totalIterations: 0, // duration * 60 / 1000, // total frames (frame == 1/60 second)
		windowTopStart: 0,
		windowDelta: 0
	});
	animRefs.current.totalIterations = animRefs.current.duration * 60 / 1000, // total frames (frame == 1/60 second)
	useEffect(() => {
		document.addEventListener("scroll", () => setScrollPosition(handleScroll()), { passive: true });

		return () => {
			document.removeEventListener('scroll', () => setScrollPosition(handleScroll()));
		}
	}, []);

	return (
		<button
			type="button"
			className="btn-collapse-all"
			onClick={(evt) => {
				props.handleCollapseAll();
				scrollToPosition(0, animRefs, evt);
			}}
		>
			{
				(layout.slug !== "browse-frtoc")
					? <><i className="bi bi-dash-circle-fill"></i> Collapse All</>
					: (props.collapse)
						? <><i className="bi-dash-circle-fill"></i> Collapse All</>
						: <><i className="bi-plus-circle-fill"></i> Expand All</>
			}
		</button>
	)
}

export default ButtonCollapseAll;
