import React from "react";
// import { ResultLineItem } from "../../features/search/search-results-slice";
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { Modal } from "react-bootstrap";
import { Icon } from "@govinfo/react-components";
import ButtonClose from "../navigation/ButtonClose";
import ButtonDetails from "../navigation/ButtonDetails";

type ModalImageProps = {
	show: boolean,
	onClose: () => void,
	details: {
		url: string,
		title: string,
		subtitle?: string
	}
	thumbnailSrc: string,
	fullSizeSrc: string
}

const ModalImage = (props:ModalImageProps) => {
	
	return (
		<Modal
			show={props.show}
			onHide={props.onClose}
		>
			<ModalHeader closeButton>
				<h2 className="modal-title"><Icon id="picture" />Image Preview</h2>
			</ModalHeader>
			<ModalBody>
				<h1 className="result-title">
					<a href={props.details.url}>
						{props.details.title}
					</a>
				</h1>
				{
					(props.details.subtitle)
						? <h2 className="document-title">{props.details.subtitle}</h2>
						: null
				}

				<figure className="modal-figure">
					<img
						className="modal-figure-image"
						src={props.fullSizeSrc}
						alt={props.details.title}
						tabIndex ={0}  />
				</figure>
			</ModalBody>
			<ModalFooter as="footer">
				<ButtonDetails url={props.details.url} label="details" />
				
				<ButtonClose onClose={props.onClose} />
			</ModalFooter>
		</Modal>
	);
};

export default ModalImage;
