import React, { Fragment } from 'react'
import {NavigateFunction} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../app/hooks';
import browseUtils from './browseUtils';
import textUtils from './textUtils';
import {
	addActiveNodeKey,
	ChildNode,
	collapseAllNodes,
	removeActiveKey
} from '../features/collectionbrowse/collection-browse-slice';
import {Accordion} from 'react-bootstrap';
import {LoadingSpinner} from "@govinfo/react-components";
import BrowseHeader from '../components/browse/BrowseHeader';
import ButtonCollapseAll from '../components/browse/ButtonCollapseAll';
import createSimpleLeafNode from './createSimpleLeafNode';
import ViewEntireIssue from '../components/browse/ViewEntireIssue';

export function createBrowseTree(nodes:ChildNode[], collection:string, navigate:NavigateFunction, parent:ChildNode, sort:number , setSort:Function) {
	const browseState = useAppSelector((state) => state.collections);
	const layoutState = useAppSelector((state) => state.layout);
	const dispatch = useAppDispatch();
	const createEntireIssueSection = (node: ChildNode) => {
		
		const hasEntireIssueDetails = ('entireIssueDetails' in node.nodeValue);
		if (!hasEntireIssueDetails) {
			return;
		}
		const entireIssueProps = {
			entireIssueDetails: node.nodeValue.entireIssueDetails,
			books: node.books,
			nodeDisplayValue: node.nodeValue.displayValue
		}
		return (
			<ViewEntireIssue {...entireIssueProps} key={`issue-${node.nodeValue.browsePathAlias}`}></ViewEntireIssue>
		);
	};

	const createTree = (nodes: ChildNode[], collection: string, navigate: NavigateFunction, parent: ChildNode, sort:number, setSort:Function) => {
		if ((nodes == null || nodes.length == 0) && parent && parent.nodeValue) {
			let isCurrentBrowsePathForGivenNode = decodeURIComponent(browseUtils.getCollectionBrowsePath()).replace(/\/$/, '').endsWith(decodeURIComponent(parent.nodeValue.browsePathAlias));
			return (
				browseState.inProgress && isCurrentBrowsePathForGivenNode)
				? <LoadingSpinner size="node" />
				: "No results"
		}

		if(nodes.length==0){
			return
		}
		// check if they are leaf nodes
		let leafNode = false;
		if (nodes[0].nodeValue.packageid) {
			leafNode = true;
		}
		if (leafNode) {
			return createSimpleLeafNode(nodes, collection, navigate, parent, sort, setSort, dispatch, browseState, layoutState);
    }

		return nodes.map((node, index) => {
			const nodeBrowsePathAlias = (collection?.toLowerCase() == 'chrg')
				? decodeURIComponent(node.nodeValue.browsePathAlias)
				: node.nodeValue.browsePathAlias;
			const nodeSlug = textUtils.slugify(nodeBrowsePathAlias);

			return (
				<Fragment key={`fragment-${nodeSlug}`}>
					<Accordion
						as="section"
						className="browse-container" 
						defaultActiveKey={browseState.activeKeys}
						key={`acc-${nodeSlug}`}
						alwaysOpen
					>
						<Accordion.Item
							as="article"
							eventKey={nodeSlug}
							key={`item-${nodeSlug}`}
						>
							<BrowseHeader
								label={node.nodeValue.displayValue}
								onClick={(evt) => {
									evt.preventDefault();
									if (browseState.activeKeys.indexOf(nodeSlug) != -1 ) { // If the node is active (open), remove its key from the active node list.
										dispatch(removeActiveKey(nodeSlug));
									} else if (browseState.visitedKeys.indexOf(nodeSlug) != -1) { // If the node is not active (not open) but has been visited before, add its key to the active node list again.
										dispatch(addActiveNodeKey(nodeSlug));
									}
									if (layoutState.paginationEnabled) {
										let sortInfo = browseUtils.getCollectionBrowseSortInfo();
										sortInfo.pageSize = layoutState.pageSizes[0]
										sortInfo.offset = 0
										navigate(`/app/collection/${collection}/${nodeBrowsePathAlias}/${JSON.stringify(sortInfo)}`);
									} else {
										navigate(`/app/collection/${collection}/${nodeBrowsePathAlias}`);
									}
								}}
								key={`head-${nodeSlug}`}
							/>
							<Accordion.Body key={`body-${nodeSlug}`}>
								{createEntireIssueSection(node)}
								{createTree(node.childNodes, collection, navigate, node, sort, setSort)}
							</Accordion.Body>   
						</Accordion.Item>
					</Accordion>
				</Fragment>
			)
		});
	}
	return (
		<Fragment key={`fragment-collapseall-${collection}`}>
			{
				(browseState.activeKeys.length > 0)
					? <ButtonCollapseAll
							handleCollapseAll={() => {
								navigate(`/app/collection/${collection}`)
								dispatch(collapseAllNodes(null))
							}}
						/>
					: null
			}
			{
				createTree(nodes,collection,navigate, null, sort, setSort)
			}
		</Fragment>
	)
	
}

export function isOpen(node: ChildNode) {
	return window.location.pathname.indexOf(node.nodeValue.browsePath);
}
