import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './css/main.css';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppSearch from './AppSearch';
import AppSearchCurated from './AppSearchCurated';
import AppBrowseTopic from './AppBrowseTopic';
import AppBrowseAuthor from './AppBrowseAuthor';
import AppBrowseCategory from './AppBrowseCategory';
import AppBrowseDate from './AppBrowseDate';
import AppBrowseFRToC from './AppBrowseFRToC';
import AppBrowseCollection from './AppBrowseCollection';
import AppError from './AppError';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes >
          <Route path='/app/search/' element={<AppSearch slug="search-results" />}>
            <Route path='' element={<AppSearch slug="search-results" />}></Route>
            <Route path=':searchReq' element={<AppSearch slug="search-results" />}></Route>
          </Route>
          <Route path='/app/search/curated/:term?/:page?' element={<AppSearchCurated slug="search-results-curated" />}>
            <Route path='' element={<AppSearchCurated slug="search-results-curated" />}></Route>
            <Route path=':searchReq' element={<AppSearchCurated slug="search-results-curated" />}></Route>
          </Route>

          <Route path='/app/browse/author' element={<AppBrowseAuthor slug="browse-author" />}>
            <Route path='' element={<AppBrowseAuthor slug="browse-author" />}></Route>
          </Route>
          <Route path='/app/browse/category/' element={<AppBrowseCategory slug="browse-category" />}>
            <Route path=':category?' element={<AppBrowseCategory slug="browse-category" />}></Route>
          </Route>
          <Route path='/app/browse/date/' element={<AppBrowseDate slug="browse-date" />}>
            <Route path='' element={<AppBrowseDate slug="browse-date" />}></Route>
          </Route>

          {/* TODO: Look into refining/updating the react-router routing here
          to take better advantage of react-router's capabilities, especially as app expands with more browses. */}
          <Route path='/app/frtoc/:tocEdition' element={<AppBrowseFRToC slug="browse-frtoc"/>}>
            <Route path='' element={<AppBrowseFRToC slug="browse-frtoc"/>}></Route>
          </Route>

          <Route path='/app/browse/topic/:collection/:level1?/:level2?' element={<AppBrowseTopic slug="browse-topic" />}>
            <Route path='' element={<AppBrowseTopic slug="browse-topic" />}></Route>
          </Route>

          {/*
            Note: The following route will get all collection browse pages.
            Don't add collection-specific routes unless the collection requires its <AppBrowseCollectionX> component. (ex. <AppBrowseCollectionCFRParts>
          */}
          <Route path='/app/collection/:collection/*' element={<AppBrowseCollection slug="browse-collection"/>} >
            <Route path='' element={<AppBrowseCollection slug="browse-collection"/>}></Route>
          </Route>
          <Route path="*" element={<AppError slug="error" error="not-found" />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </StrictMode>
);
