import React from "react";
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import SearchResultItem from "./SearchResultItem"; // TODO: DEPRECATED Remove
import ResultItem from "../navigation/ResultItem";

const Results = () => {
  const results = useAppSelector((state)=> state.results.response);
	const criteria= useAppSelector((state)=> state.criteria);
	const contentDetailsBaseUrl = "/app/details/";
	const renditionBaseUrl = "/content/pkg/";

	return (
		<ol
			id="results-view"
			className="search-results-list"
			start={(criteria.offset * (criteria.pageSize ? criteria.pageSize :10 ) ) + 1 }
		>
			{
				results.resultSet && results.resultSet.map((item, index) => {
					const nodeRenditions = Object.keys(item.fieldMap).filter((r) => r.includes("file"));
					const itemRenditions = [];
					let hasThumbnail = false;
					let fullSizeSrc = null;
					let thumbnailSrc = null;
					
					if (nodeRenditions.length > 0) {
						nodeRenditions.forEach((f) => {
							const renditionsList = item.fieldMap[f].split(".");
							const contentType = renditionsList[renditionsList.length - 1];
					//		const contentType = item.fieldMap[f].split(".")[1];
							if ((contentType != "gif") && (!item.fieldMap[f].includes("thumbnails"))) {
								itemRenditions.push({
									url: `${renditionBaseUrl}${item.fieldMap.packageid}/${item.fieldMap[f]}`,
									format: ((contentType == "xml") && (item.fieldMap[f].includes("uslm"))) ? "uslm" : contentType
								});
							}
							hasThumbnail = (item.fieldMap[f].includes("thumbnails") || item.fieldMap[f].includes("graphics")) || item.fieldMap[f].includes("gif");
							if (hasThumbnail) {
								thumbnailSrc = `/wssearch/getthumbnail/${item.fieldMap.packageid}/${item.fieldMap[f]}`;
								fullSizeSrc = `/content/pkg/${item.fieldMap.packageid}/${item.fieldMap[f]}`;
							}
						});
					}

					return (
						<li className="results-row" key={`item-${index}`}>
							{/* <SearchResultItem item={item} /> */}
							<ResultItem
								line1={item.line1}
								line2={`${(item.line2) ? item.line2 : ""}`}
								packageid={item.fieldMap.packageid}
								url={`${contentDetailsBaseUrl}${item.fieldMap.packageid}${item.fieldMap.granuleid ? `/${item.fieldMap.granuleid}` : ''}`}
								teaser={item.fieldMap.teaser}
								renditions={itemRenditions}
								showShare={true}
								hasThumbnail={hasThumbnail}
								thumbnailSrc={thumbnailSrc}
								fullSizeSrc={fullSizeSrc}
								key={`${item.line1}-${index}`}
							/>
						</li>
					)
				})
			}
		</ol>
	);
};

export default Results;
