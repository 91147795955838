import React from "react";
import { EntireIssueDetail, Book } from "../../app/browse.types";
import ResultItem from '../../components/navigation/ResultItem';

export interface EntireIssueProps {
    books: Book[]
    entireIssueDetails: EntireIssueDetail[],
    nodeDisplayValue?: string
};

const BookLabel = {
    digestBook: "Daily Digest",
    exBook: "Extensions of Remarks Section",
    houseBook: "House Section",
    senateBook: "Senate Section",
}

const ViewEntireIssue = (props: EntireIssueProps) => {

    const entireIssueDetails = props.entireIssueDetails;
    const issues = [];
    let detailsLink = "Unavailable";
    const defaultEntireIssueTitle = "View Entire Section";

    entireIssueDetails.forEach((item) => {
        let renditions = [];
        item.renditions?.forEach((r) => {
            if (r.renditionType.toLowerCase() === 'details') { // Skip rendering the Details Rendition button and pass in the details URL instead to avoid having multiple Details buttons
                detailsLink = r.linkAddress
            } else {
                renditions.push({url: r.linkAddress, format: r.renditionType});
            }
        });

        if ((item.title || item.alternateTitle)) {
            const title = item.title ? item.title : item.alternateTitle;
            const shareTitle = (defaultEntireIssueTitle.toLowerCase() == title.toLowerCase() && props.nodeDisplayValue)
                ? `${title} - ${props.nodeDisplayValue}`: title
            issues.push(
                {
                    title: title,
                    shareTitle: shareTitle,
                    url: detailsLink,
                    renditions
                }
            );
        }
    });

    const renderEntireIssueDetailsSection = () => {
        return issues.map((item, index) => {
            return (
                <ResultItem
                    title={item.title}
                    shareTitle={item.shareTitle}
                    renditions={item.renditions}
                    showShare={true}
                    url={item.url}
                    hasThumbnail={false}
                    key={`${item.title}-${index}`}
                    className="entireissue-item"
                />
            )
        })
    };

    const renderBooksSection = () => {
        return props.books?.map((book, index) => {
            return (
                <>
                    {
                        (props.books.length > 1)
                            ? <ResultItem
                                title={book.title}
                                renditions={[{ url: book.book, format: 'pdf' }]}
                                showShare={false}
                                hasThumbnail={false}
                                key={`${book.title}-${index}`}
                                className="book-item"
                            />
                            : null
                    }
                    {
                        (book.houseBook)
                            ? <ResultItem
                                title={BookLabel.houseBook}
                                renditions={[{ url: book.houseBook, format: 'pdf' }]}
                                showShare={false}
                                hasThumbnail={false}
                                key={`${book.houseBook}-${index}`}
                                className="book-section-item house"
                            />
                            : null
                    }

                    {
                        (book.senateBook)
                            ? <ResultItem
                                title={BookLabel.senateBook}
                                renditions={[{ url: book.senateBook, format: 'pdf' }]}
                                showShare={false}
                                hasThumbnail={false}
                                key={`${book.senateBook}-${index}`}
                                className="book-section-item senate"
                            />
                            : null
                    }

                    {
                        (book.exBook)
                            ? <ResultItem
                                title={BookLabel.exBook}
                                renditions={[{ url: book.exBook, format: 'pdf' }]}
                                showShare={false}
                                hasThumbnail={false}
                                key={`${book.exBook}-${index}`}
                                className="book-section-item extensions"
                            />
                            : null
                    }

                    {
                        (book.digestBook)
                            ? <ResultItem
                                title={BookLabel.digestBook}
                                renditions={[{ url: book.digestBook, format: 'pdf' }]}
                                showShare={false}
                                hasThumbnail={false}
                                key={`${book.digestBook}-${index}`}
                                className="book-section-item digest"
                            />
                            : null
                    }
                </>
            );
        });
    };

    return (
        <>
            { renderEntireIssueDetailsSection() }

            <section className="books" key={`books`}>
                { renderBooksSection() }
            </section>
        </>
    );
}

export default ViewEntireIssue;