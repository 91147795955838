import React from 'react';
import { Icon } from '@govinfo/react-components';

const ButtonSort = (props: { type: String, activeSort: string, toggleSort: (sort: string) => void }) => {
	const sortButtonTypes: any = {
		"alphabetical": {
			"label": "Sort alphabetically, ascending",
			"content": "alpha",
			"icon": "sort-alpha-desc"
		},
		"count": {
			"label": "Sort by count",
			"content": "count",
		},
		"numerical": {
			"label": "Sort numerically, descending",
			"content": "count",
			"icon": "sort-numeric-desc"
		},
		"date": {
			"label": "Sort by date",
			"content": "date",
			
		},
		"congress": {
			"label": "Sort by Congress",
			"content": "Congress",
		}
	}

  return (
		<button type="button"
			className={`btn-custom-sort ${(props.activeSort === props.type) ? "active" : ""}`}
			aria-label={sortButtonTypes[props.type.toLowerCase()].label}
			data-facet="navigator-collection"
			onClick={() => {props.toggleSort(props.type.toLowerCase())}}
			>
			{
				(sortButtonTypes[props.type.toLowerCase()].icon)
					// ? <i className={`bi ${sortButtonTypes[props.type.toLowerCase()].icon}`} aria-hidden="true"></i>
					? <Icon id={sortButtonTypes[props.type.toLowerCase()].icon} />
					: sortButtonTypes[props.type.toLowerCase()].content
			}
		</button>
	)
}

export default ButtonSort