import React, { useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useAppSelector } from '../../app/hooks'
import urlUtil from '../../utility/urlUtil'
import { useNavigate } from "react-router-dom";

const HistoricalResultsToggle = () => {
	const hChecked = useAppSelector((state) => state.criteria.historical)
	const tooltipTarget = useRef<HTMLButtonElement>(null);
	const navigate = useNavigate();
	const criteria =useAppSelector((state) => state.criteria)

	return(
		<aside className='historical-results-toggle-group'>
			<input
				id='historical'
				name='historical-toggle'
				type='checkbox'
				role='checkbox'
				checked={hChecked}
				onChange={() => {
					navigate(`/app/search/${urlUtil.toggleHistorical(criteria)}`)
				}} />
			<label htmlFor='historical'>View Historical Results</label>
						
			<OverlayTrigger
				key='top'
				placement='top'
				trigger={['click']}
				rootClose={true}
				overlay={
					<Tooltip
						id='btn-tooltip-historical'
						className='tooltip-info-historical'>
						For select collections, only the most recent edition of the document is included in search results retrieved through the Basic Search box. For search results retrieved through Advanced Search, documents from all editions will be included. <a target='_blank' href='/help/search-results#historical-editions' rel="noopener nofollow">Read more.</a>'
					</Tooltip>
				}>
				<button type='button'
					className='btn-tooltip'
					tabIndex={0}
					role='tooltip'
					ref={tooltipTarget}
					>
					<i className='bi bi-info-circle-fill'>
						<span className='visually-hidden'>Historical results info</span>
					</i>
				</button>
			</OverlayTrigger>
		</aside>
	);
}

export default HistoricalResultsToggle
