import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios'

export interface CMRReportDownloadPayload {
    collectionCode?: string;
    congress?: string;
    contentType?: string;
    offset?: number;
    pageSize?: number;
};

const defaultCMRReportDownloadPayload: CMRReportDownloadPayload = {
    collectionCode: "CMR",
    offset: 0,
    pageSize: 1000,
}

export interface CMRReport {
    collectionCode: string,
    congress?: string,
    downloadCSVInProcess: boolean,
    downloadJSONInProcess: boolean,
}

const initialState: CMRReport = {
    collectionCode: "CMR",
    downloadCSVInProcess: false,
    downloadJSONInProcess: false,
}

export const downloadCSVCmrReport = createAsyncThunk('cmrReport/csvdownload', (congress: string) => {
    return axios.post('/wssearch/browse/downloadCMRReport', {
        ...defaultCMRReportDownloadPayload,
        contentType: 'csv',
        congress
    })
        .then((response) => {

            let fileName: string = "unnamed." + 'csv'
            if (response.headers['content-disposition']) {
                fileName = response.headers['content-disposition'].substring(response.headers['content-disposition'].indexOf("=") + 1)
            }

            var binaryData = [];
            binaryData.push(response.data);
            let a = document.createElement('a');
            const href = URL.createObjectURL(new Blob(binaryData, {type: 'application/csv'}));
            a.href = href;
            // Give filename you wish to download
            a.setAttribute('download', fileName);
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            // makes button clickable
            document.body.removeChild(a);
            URL.revokeObjectURL(href);
        })
});

export const downloadJSONCmrReport = createAsyncThunk('cmrReport/jsondownload', (congress: string) => {
    return axios.post('/wssearch/browse/downloadCMRReport', {
        ...defaultCMRReportDownloadPayload,
        contentType: 'json',
        congress
    })
        .then((response) => {
            let fileName: string = "unnamed." + 'json'
            if (response.headers['content-disposition']) {
                fileName = response.headers['content-disposition'].substring(response.headers['content-disposition'].indexOf("=") + 1)
            }
            var binaryData = [];
            binaryData.push(JSON.stringify(response.data));
            let a = document.createElement('a');
            const href = URL.createObjectURL(new Blob(binaryData, {type: 'application/json'}));
            a.href = href;
            // Give filename you wish to download
            a.setAttribute('download', fileName);
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            // makes button clickable
            document.body.removeChild(a);
            URL.revokeObjectURL(href);

        })
});


const cmrReportSlice = createSlice({
    name: 'cmrReport',
    initialState,
    reducers: {
        updateSelectedCongress(state, {payload}) {
            state.congress = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(downloadCSVCmrReport.pending, (state, action) => {
            state.downloadCSVInProcess = true
        }),
        builder.addCase(downloadCSVCmrReport.fulfilled, (state, action) => {
            state.downloadCSVInProcess = false
        }),
        builder.addCase(downloadCSVCmrReport.rejected, (state, action) => {
            state.downloadCSVInProcess = false
        }),
        builder.addCase(downloadJSONCmrReport.pending, (state, action) => {
            state.downloadJSONInProcess = true
        }),
        builder.addCase(downloadJSONCmrReport.fulfilled, (state, action) => {
            state.downloadJSONInProcess = false
        }),
        builder.addCase(downloadJSONCmrReport.rejected, (state, action) => {
            state.downloadJSONInProcess = false
        })
    }
})


export const {updateSelectedCongress} = cmrReportSlice.actions;
export default cmrReportSlice.reducer;
