import React from "react";
import { useNavigate } from "react-router-dom";
import {useAppDispatch} from '../../app/hooks';
import { SearchQuery, urlState } from "../../features/search/search-criteria-slice";
import urlUtil from '../../utility/urlUtil'

const ButtonClearNavigators =({criteria}:{criteria:SearchQuery})=>{
	const navigate = useNavigate();
	const dispatch  = useAppDispatch()
    return (
		<button type="button"
			id="clear-side-filters"
			className="btn-clear"
			onClick={e=>{
				navigate(`/app/search/${urlUtil.clearNavigators(criteria)}`)				

			}} >
			Clear
		</button>
	);
}

export default ButtonClearNavigators;