import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from './app/hooks';
import { updateLayout } from "./features/layout/layout-slice";
import { PageHeader, SiteFooter, LoadingSpinner } from "@govinfo/react-components";
import Icon from "./components/layout/SvgIcons";
import axios from 'axios'

export interface Authors {
	executive?: (string)[] | null;
	judicial?: (string)[] | null;
	legislative?: (string)[] | null;
}

function AppBrowseAuthor({ slug }: { slug: string }) {
	const [key, setKey] = useState('author');
	const mainContentLinkRef = useRef();
	const navigate = useNavigate();
	const [authors, setAuthors] = useState<Authors>({});
	const [apiInProgress, setApiInProgress] = useState(false);
	const dispatch = useAppDispatch();
	const layout = useAppSelector((state) => state.layout);

	useEffect(() => {
		dispatch(updateLayout(slug));
	}, []);

	// TODO: This needs to be refactored so any of the App files can use the same function
	const widgetCallback = (urlFrag: string) => {
		navigate('/app/search/' + encodeURIComponent(urlFrag.replaceAll('/', '¼')));
	}

	useEffect(() => {
		setApiInProgress(true);
		axios.get('/wssearch/browsegovernmentauthor/authors')
			.then(({ data }) => {
				setAuthors(data);
				setApiInProgress(false);
			})
			.finally(() => {
				setApiInProgress(false);
			})
	}, []);

	return (
		<>
			<PageHeader layout={layout} mainContentLinkRef={mainContentLinkRef} widgetCallback={widgetCallback} hasSearchWidget={true} widgetDefaultOpen={false} />

			{
				(apiInProgress)
					? <LoadingSpinner size="page" />
					: null
			}

			<main className="browse-author">
				<article className="author-section">
					<h2 className="section-title has-icon"><Icon inlineSvg={false} key="legislative" id="legislative" />Legislative Branch</h2>
					<p className="section-description">Browse authors including the House of Representatives, the U.S. Senate, Legislative commissions, Special committees and Legislative agencies</p>
					<ul className="browse-list">
						{
							(authors.legislative)
								? authors.legislative.sort().map((d, i) => (
									<li key={`${d}-${i}`}>
										<a href={'/app/search/'+encodeURIComponent(`{"query":"governmentauthor:(${d}) AND branch:(legislative)","offset":0,"sortBy":2}`)}> {d}</a>
									</li>))
								: null
						}
					</ul>
				</article>

				<article className="author-section">
					<h2 className="section-title has-icon has-icon"><Icon inlineSvg={false} key="executive" id="executive" />Executive Branch</h2>
					<p className="section-description">Browse authors including the Executive Office of the President, Office of Management and Budget, National Archives and Records Administration, Office of the Federal Register, Treasury Department</p>
					<ul className="browse-list">
						{
							(authors.executive)
								? authors.executive.sort().map((d, i) => (
									<li key={`${d}-${i}`}>
										<a href={'/app/search/'+encodeURIComponent(`{"query":"governmentauthor:(${d}) AND branch:(executive)","offset":0,"sortBy":2}`)}> {d}</a>
									</li>))
								: null
						}
					</ul>
				</article>

				<article  className="author-section">				
					<h2 className="section-title has-icon has-icon"><Icon inlineSvg={false} key="judicial" id="judicial" />Judicial Branch</h2>
					<p className="section-description">Browse authors including the U.S. Court of Appeals, U.S. District Courts, U.S. Court of International Trade, Bankruptcy Courts, and Judicial commissions</p>
					<ul className="browse-list">
						{
							(authors.judicial)
								? authors.judicial.sort().map((d, i) => (
									<li key={`${d}-${i}`}>
										<a href={'/app/search/'+encodeURIComponent(`{"query":"governmentauthor:(${d}) AND branch:(judicial)","offset":0,"sortBy":2}`)}> {d}</a>
									</li>))
								: null
							}
					</ul>
				</article>
			</main>

			<SiteFooter mainContentLinkRef={mainContentLinkRef} />
			
			<Icon inlineSvg={true} id="inline-svg-src" key="inline-svg-src" />
	</>
	
	
	)



}
export default AppBrowseAuthor;