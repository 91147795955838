import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { url } from "inspector";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { Data, getTableOfContents } from "./features/frtoc/frtoc-slice";
import { updateLayout, updateTitle } from "./features/layout/layout-slice";
import { PageHeader, SiteFooter, LoadingSpinner } from "@govinfo/react-components";
import CalendarDropdown from "./components/navigation/CalendarDropdown";
import BrowseNode from "./components/browse/BrowseNode";
import ResultItem from "./components/navigation/ResultItem";
import RenditionButttonGroup from "./components/navigation/RenditionButttonGroup";
import ButtonCollapseAll from "./components/browse/ButtonCollapseAll";
import Toasts from "./components/popups/Toasts";

function AppBrowseFRToC({ slug }: { slug: string }) {
	const mainContentLinkRef = useRef();
	let date = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const layout = useAppSelector((state) => state.layout);
	const toc = useAppSelector((state) => state.frtoc);
	const [activeKeys, setActiveKeys] = useState([]);

	useEffect(() => {
		dispatch(getTableOfContents(date.tocEdition));
	}, [navigate]);

	useEffect(() => {
		dispatch(updateLayout(slug));
	}, []);

	// useEffect(() => {
	// 	handleCollapseClick();
	// }, []);

	useEffect(() => {
		dispatch(updateTitle(toc.data.pageTitle));
	}, [toc.data.pageTitle]);

	// TODO: This needs to be refactored so any of the App files can use the same function
	const widgetCallback = (urlFrag:string) => {
		navigate('/app/search/'+encodeURIComponent(urlFrag.replaceAll('/', '¼')));
	}	

	const getRenditions = (links: any) => {
		let r = Object.keys(links)
			.filter((k) => (k !== "morelink") && (k !== "somefield") && links[k] !== null)
			.map((k) => {
				let format = k.slice(0, -4);
				format = (format == "txt") ? "text" : format;
				return {
					url: links[k],
					format: format
				}
			});
		
			r.sort((a, b) => {
				if (a.format < b.format) {
					return -1;
				}
				if (a.format > b.format) {
					return 1;
				}
				return 0;
			});

		return r;
	}

	const handleToggleClick = () => {
		const index = activeKeys.indexOf("0");
		if (index > -1) {
			activeKeys.splice(index, 1);
			setActiveKeys([...activeKeys]);
		} else {
			setActiveKeys(activeKeys.concat("0"));
		}
	}
	const handleSelect = (eventKey: AccordionEventKey) => setActiveKeys(eventKey as string[]);
	const handleCollapseClick = () => {
		setActiveKeys([]);
	}
	const handleExpandClick = () => {
		let keys = []
		toc.data.agencies.forEach((element, i) => {
			// keys.push(i)
			keys.push(`${element.agencyheading}-${i}`);
		});

		setActiveKeys(keys);
	}

	const headerRenditions = (toc.data.header.parts) ? getRenditions(toc.data.header.parts) : [];
	const cfrPartsRenditions = (toc.data.footer.parts) ? getRenditions(toc.data.footer.parts) : [];
	const readerAidsRenditions = (toc.data.readeraids.downloadlinks) ? getRenditions(toc.data.readeraids.downloadlinks) : [];

	return (
		<>
			<PageHeader layout={layout} mainContentLinkRef={mainContentLinkRef} widgetCallback={widgetCallback} hasSearchWidget={true} widgetDefaultOpen={false}>
				{
					<>
						<h1 className="page-title">
							{ layout.title }
							<CalendarDropdown currentDate={toc.currentDate} handleCollapseAll={handleCollapseClick} />
						</h1>
						
						<h2 className="page-sub-title">Table of Contents</h2>
						<RenditionButttonGroup
							details={{
								url: toc.data.downloadlinks.morelink,
								title: `${toc.data.pageTitle} - Table of Contents`
							}}
							renditions={headerRenditions}
							showShare={true}
							shareDetails={{
								url: location.pathname,
								title: `${toc.data.pageTitle} - Table of Contents`
							}}
						/>
						{
							(toc.startDate)
							? <Link
									to={`/app/frtoc/${toc.startDate}`}
									className="btn-previous"
									onClick={() => handleCollapseClick()}
								>
									<i className="visually-hidden">Previous Issue</i>
								</Link>
							: null
						}
						{
							(toc.endDate)
							? <Link
									to={`/app/frtoc/${toc.endDate}`}
									className="btn-next"
									onClick={() => handleCollapseClick()}
								>
									<i className="visually-hidden">Next Issue</i>
								</Link>
							: null
						}
					</>
					
				}
			</PageHeader>
			{
				(toc.inProgress)
					? <LoadingSpinner size="page" />
					: null
			}
			{
				<ButtonCollapseAll
					handleCollapseAll={() => {
						if (activeKeys.length > 0) {
							handleCollapseClick()
						} else {
							handleExpandClick()
						}
					}}
					collapse={activeKeys.length > 0 ? true : false}
				/>
			}

			<main>
				<Accordion
					as="section"
					className="browse-container"
					alwaysOpen
					activeKey={activeKeys} onSelect={handleSelect}
				>
					{
						(toc && toc.browse)
						? toc.browse.map((agency, agencyIndex) => {
								return (
									<BrowseNode
										label={agency.label as string}
										itemKey={`${agency.label}-${agencyIndex}`}
										itemEventKey={`${agency.label}-${agencyIndex}`}
										handleExpandLevel={() => {}}
										key={`${agency.label}-${agencyIndex}`}
									>
										{
											(agency.nodes && agency.nodes.length > 0)
												? agency.nodes.map((section, sectionIndex) => {
														return (
															<>
																{
																	(section.label && section.label !== "")
																		? <h2 className="section-title" key={`${section.label}-${sectionIndex}`}>{section.label}</h2>
																		: null
																}

																{
																	(section.nodes && section.nodes.length > 0)
																		? <section className="results-sub-section">
																				{
																					section.nodes.map((subject, subjectIndex) => {
																						const itemRenditions = getRenditions(subject.downloadlinks);

																						return (
																							<>
																								{
																									(subject.subjectheading && subject.subjectheading !== "")
																										? <h3 className="section-sub-title" key={`${subject.subjectheading}-${subjectIndex}`}>{subject.subjectheading}</h3>
																										: null
																								}
																								<ResultItem
																									line1={subject.subjecttitle}
																									line2={subject.pagenumberdetails}
																									url={subject.downloadlinks.morelink}
																									renditions={itemRenditions}
																									showShare={true}
																									hasThumbnail={false}
																									key={`${subject.subjecttitle}-${subjectIndex}`}
																								/>
																							</>
																						)
																					})
																				}
																			</section>
																		: null
																}
															</>
														)
												})
											: null
										}
									</BrowseNode>
								)
							})
						: null
					}

					{
						(toc.data.footer.parts)
							? <BrowseNode
									label="CFR Parts"
									itemKey={`cfr-parts-0`}
									itemEventKey={`cfr-parts-0`}
									handleExpandLevel={() => {}}
									key={`cfr-parts-0`}
								>
									<ResultItem
										line1="*Title page, contents and CFR parts affected (if available)*"
										url={""}
										renditions={cfrPartsRenditions}
										showShare={false}
										hasThumbnail={false}
										key={`cfr-parts-item-0`}
									/>
								</BrowseNode>
							: null
					}

					{
						(toc.data.readeraids.downloadlinks)
							? <BrowseNode
									label="Reader Aids"
									itemKey={`reader-aids-0`}
									itemEventKey={`reader-aids-0`}
									handleExpandLevel={() => {}}
									key={`reader-aids-0`}
								>
									<ResultItem
										line1="*Consult the Reader Aids section at the end of this issue for phone numbers, online resources, finding aids, reminders, and notice of recently enacted public laws.*"
										url={""}
										renditions={readerAidsRenditions}
										showShare={false}
										hasThumbnail={false}
										key={`reader-aids-item-0`}
									/>
								</BrowseNode>
							: null
					}
				</Accordion>

				<p>
					To subscribe to the Federal Register Table of Contents electronic mailing list, go to the <a href="https://public.govdelivery.com/accounts/USGPOOFR/subscriber/new">Federal Register Table of Contents Subscription Page</a> and enter your contact information.
				</p>
				<p>
					The FEDREGTOC-L list is hosted by the U.S. Government Publishing Office. To view an online archive of previous Federal Register issues, please browse the <a href="/app/collection/fr">Federal Register.</a>
				</p>

			</main>

			<SiteFooter />
			<Toasts />
		</>
	)

}

export default AppBrowseFRToC;
