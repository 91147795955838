import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Pagination from 'react-bootstrap/Pagination';
import PageSizeSelector from "./PageSizeSelector";
import urlUtil from '../../utility/urlUtil'
import { useNavigate } from "react-router-dom";

export type PaginationControlsProps = {
  showPageSelector: boolean,
  pageCount: number,
  offset: number,
  pageSize: number,
  next: (e) => void,
  prev: (e) => void,
  gotoPage: (e, pageNum: number)=>void,
  onPageSizeChange: (e, pSize)=>void,
  pageSizeOptions?: number[]
}

const PaginationControls = (props: PaginationControlsProps) => {
  const navigate = useNavigate();
  const handleNext = (e: React.MouseEvent<HTMLAnchorElement>) => {
    props.next(e)
  };
  const handlePrevious = (e: React.MouseEvent<HTMLAnchorElement>) => {
    props.prev(e)
  };

  const handlePage = (e: React.MouseEvent<HTMLElement>, pageIndex: number) => {
    props.gotoPage(e,pageIndex);
  };
  const handleLast = (e: React.MouseEvent<HTMLAnchorElement>) => {

    // navigate(`/app/search/${urlUtil.pageSelection(criteria,
    //   result.iTotalCount % criteria.offset != 0
    //   ? Math.floor(result.iTotalCount / (criteria.pageSize ? criteria.pageSize:10 ))
    //   : Math.floor(result.iTotalCount / (criteria.pageSize ? criteria.pageSize:10 )) - 1)}`);
  };

  const getButtonStyle = () => {
    props.offset == 2 ? "pager-item pager-current" : "";
  };

  const nextPageAvailable = () => {
    props.offset + 1 * (props.pageSize ? props.pageSize : 10) > props.pageCount;
  };

  const makePaginationDataStructure = (count: number, currentPage: number, pageSize: number = 10) => {
    let first4 = [1, 2, 3, 4].map((i) => {
      const isActive = (i == currentPage) || (currentPage == 0 && i == 1);
      return (
        <Pagination.Item key={i} active={isActive} onClick={(isActive) ? null : (e) => handlePage(e, i)}>{i}</Pagination.Item>
      )
    });
    let first2 = [1, 2].map((i) => {
      const isActive = (i == currentPage) || (currentPage == 0 && i == 1);
      return (<Pagination.Item key={i} active={isActive} onClick={(isActive) ? null : (e) => handlePage(e, i)}>{i}</Pagination.Item>)
    });
    let numPages = 0;
    count % pageSize == 0
      ? (numPages = Math.floor(count / pageSize))
      : (numPages = Math.floor(count / pageSize) + 1);

    let page: any = [];
    if (numPages <= 6) {
      for (let i = 1; i <= numPages; i++) {
        const isActive = (i == currentPage) || (currentPage == 0 && i == 1);
        page.push(<Pagination.Item key={i} active={isActive} onClick={(isActive) ? null : (e) => handlePage(e, i)}>{i}</Pagination.Item>);
      }
      return page;
    }
    // decide 4 or 2
    page = [...first4];
    if (currentPage >= 6) {
      page = [...first2];
      page.push(<Pagination.Ellipsis key={`ellipsisLeft`} />);
    }
    currentPage < 4
      ? page.push(<Pagination.Ellipsis key={`ellipsisLeft`} />)
      : null;

    if (currentPage >= 4) {
      currentPage - 2 > 3 && page.push(<Pagination.Item key={currentPage - 2} onClick={(e) => handlePage(e, currentPage - 2)}>{currentPage - 2}</Pagination.Item>);
      currentPage - 1 > 4 &&
        currentPage - 1 != numPages - 1 &&
        page.push(<Pagination.Item key={currentPage - 1} onClick={(e) => handlePage(e, currentPage - 1)}>{currentPage - 1}</Pagination.Item>);
      currentPage < numPages - 1 && currentPage > 4 && page.push(<Pagination.Item key={currentPage} active={true}>{currentPage}</Pagination.Item>);
      currentPage + 1 < numPages - 1 && page.push(<Pagination.Item key={currentPage + 1} onClick={(e) => handlePage(e, currentPage + 1)}>{currentPage + 1}</Pagination.Item>);
      currentPage + 1 < numPages - 2 && page.push(<Pagination.Ellipsis key={`ellipseRight`} />);
    }

    page = [...page, <Pagination.Item key={numPages - 1} active={(numPages - 1) == currentPage} onClick={(e) => handlePage(e, numPages - 1)}>{numPages - 1}</Pagination.Item>];
    page = [...page, <Pagination.Item key={numPages} active={numPages == currentPage} onClick={(e) => handlePage(e, numPages)}>{numPages}</Pagination.Item>];

    return page;
  };

  let pageNumbers: any[] = makePaginationDataStructure(
    props.pageCount > 10000 ? 10000 : props.pageCount,
    props.offset + 1,
    (props.pageSize ? props.pageSize : 10)
  );

  return (
    <nav className="pagination-controls" aria-label="Results pagination">
      <Pagination>
        <Pagination.Prev disabled={props.offset == 0} onClick={handlePrevious} key='prev'>Previous</Pagination.Prev>
        {pageNumbers}
        <Pagination.Next
          disabled={(props.offset + 1) * (props.pageSize ? props.pageSize : 10) >= props.pageCount}
          key='next'
          onClick={handleNext}
        >
          Next
        </Pagination.Next>
      </Pagination>
      {
        (props.showPageSelector)
        ? <PageSizeSelector onChange={(e,pageSize:number) => {props.onPageSizeChange(e,pageSize)}} pageSize={props.pageSize} pageSizeOptions={props.pageSizeOptions}/>
        : null
      }
    </nav>
  );
};
export default PaginationControls;
