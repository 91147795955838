import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetBreakpoint } from "../../app/useGetBreakpoint";

type categoryProps = {
	label: string,
	slug: string,
	items: string[]
};

type collectionsProps = {
	label: string,
	slug: string,
	code: string,
	url: string
}[];

function TabsCategoryBrowse({ category, collections, isActive }: { category: categoryProps, collections: collectionsProps, isActive: boolean }) {
	const breakpoint = useGetBreakpoint();
	const collapsable = isActive && breakpoint.includes("small");
	const [isCollapsed, setIsCollapsed] = useState(false);

	const togglePanes = (evt) => {
		if (breakpoint.includes("small")) {
			if (isActive) {
				// evt.preventDefault();
				setIsCollapsed(!isCollapsed);
			}
		}
	}
	
	return (
		<>
			<p className={`category-tab${(isActive ? " active" : "")}${(collapsable) ? " collapsable" : ""}${(isCollapsed) ? " is-collapsed" : ""}`}>
				<Link
					to={category.slug}
					aria-expanded={!isCollapsed}
					aria-controls={`category-${category.slug}`}
					onClick={togglePanes}
				>
					{category.label}
				</Link>
			</p>
			<article id={`category-${category.slug}`} className={`category-tab-content${(isActive ? " active" : "")}${(collapsable) ? " collapsable" : ""}${(isCollapsed) ? " is-collapsed" : ""}`}>
				<h2 className="section-title">{category.label}</h2>
				<nav className="browse-category-nav">
					<ul>
						{
							collections.map((collection, collectionIndex) => {
								return (
									<li key={`${category.slug}-${collection.slug}-${collectionIndex}`}>
										<a href={collection.url}>{collection.label}</a>
									</li>
								)
							})
						}
					</ul>
				</nav>
			</article>
		</>
	)
}

export default TabsCategoryBrowse;