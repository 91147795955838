import React from "react";

const NavigatorSectionToggle = (props:{toggleOnClick:()=>void, toggleState:boolean,controls?:any,children?:JSX.Element|JSX.Element[]})=>{
    return (
		<button type="button"
			className="navigator-section-toggle"
			onClick={props.toggleOnClick}
			aria-expanded={props.toggleState}
			aria-controls={props.controls}
			aria-haspopup="true">
			{props.children}
		</button>


	);
}
export default NavigatorSectionToggle