import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import { BrowseItem, ResultItem } from "../../app/browse.types";
import Accordion from 'react-bootstrap/Accordion';
import { LoadingSpinner } from "@govinfo/react-components";
import BrowseNode from "./BrowseNode";
import BrowseResults from "./BrowseResults";
import ButtonCollapseAll from "./ButtonCollapseAll";
// import Spinner from "../popups/Spinner";

type BrowseProps = {
	activeKeys?: number[],
	nodeLevelIndex?: number,
	isLoading: boolean,
	collapseAll?:()=>void,
	//handleSelects: Function[],
	handleExpandLevels?: Function[],
	paginationHandlers?: {
		sort: Function,
		next: Function,
		prev: Function,
		gotoPage: Function,
		updatePageSize: Function
	}
	nodes: ResultItem[] | BrowseItem[]
}

function BrowseContainer(props: BrowseProps) {
	let navigate = useNavigate();
	const getDefaultKey =()=>{
		let { level1, level2 } = useParams();
		if(props.nodeLevelIndex == 0){
			return [level1? level1.replaceAll(' ', '_'):null]
		}else if(props.nodeLevelIndex == 1){
			return [level2 ? level2.replaceAll(' ', '_'):null]
		}
	}

	return(
		<>
			{
				(props.nodeLevelIndex > 0)
					? <ButtonCollapseAll handleCollapseAll={() => {
						props.collapseAll();
						//props.handleSelects[0](props.activeKeys[0])
					}} />
					: null
			}

			<Accordion
				//activeKey={`${props.activeKeys[props.nodeLevelIndex]}`}
				defaultActiveKey={getDefaultKey()}
				//activeKey={'A'}
				as="section"
				className="browse-container"
				onSelect={(key, evt) => {
					evt.preventDefault();
					//props.handleSelects[props.nodeLevelIndex](key);
				}}
				alwaysOpen
			>
				{
					props.nodes.map((node, index) => {
						return (
							<BrowseNode
								label={node.label}
								itemKey={`level-${props.nodeLevelIndex}-key-${index}`}
								itemEventKey={`${node.label.replaceAll(' ','_')}`}
								handleExpandLevel={() => {
									props.handleExpandLevels[props.nodeLevelIndex](node.label);
								}}
								key={`${node.label}-${index}`}>
									{
										(node.nodes && node.nodes.length > 0)
											?	<BrowseContainer
													// activeKeys={props.activeKeys}
													nodeLevelIndex={props.nodeLevelIndex + 1}
													isLoading={props.isLoading}
													//handleSelects={props.handleSelects}
													handleExpandLevels={props.handleExpandLevels}
													paginationHandlers={props.paginationHandlers}
													nodes={node.nodes}
													collapseAll={props.collapseAll}
													
												/>
											: null
									}
									{
										(props.isLoading)
										? <p><LoadingSpinner size="node" /> Loading...</p>
										: (node.results && node.results.length > 0)
											? <BrowseResults
													results={node.results}
													sortDirection={node.sortDirection}
													sortHandler={(e) => {
														e.preventDefault();
														props.paginationHandlers.sort(node);
													}}
													showPagination={node.showPagination}
													pagination={{
														showPageSelector: true,
														pageCount: node.pagination.pageCount,
														offset: node.pagination.offset,
														pageSize: node.pagination.pageSize,
														next: (e) => {
															e.preventDefault();
															props.paginationHandlers.next(node);
														},
														prev: (e) => {
															e.preventDefault();
															props.paginationHandlers.prev(node);
														},
														gotoPage: (e, pageNum) => {
															e.preventDefault();
															props.paginationHandlers.gotoPage(node, pageNum);
														},
														onPageSizeChange: (e, pageSize) => {
															e.preventDefault();
															props.paginationHandlers.updatePageSize(node, pageSize);
														},
														pageSizeOptions: node.pagination.pageSizeOptions
													}}
												/>
											: null
									}
									{

									}
								</BrowseNode>
						)
					})
				}
			</Accordion>
		</>
	)
}

export default BrowseContainer;