import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type DateBrowseState = {
	activeTab: "timeframe" | "range",
	timeframeSelection: "24-hours" | "7-days" | "30-days" | "180-days" | "365-days" | null,
	rangeSelection: "AD" | "DI" | "DA" | "DB" | "DBT",
	dateFrom: string | null,
	dateTo: string | null
}

const initialState = {
	activeTab: "timeframe",
	timeframeSelection: null,
	rangeSelection: "AD",
	dateFrom: null,
	dateTo: null
}

const dateBrowseSlice = createSlice({
	name: 'dateBrowse',
	initialState,
	reducers: {
		updateTab(state, action) {
			state.activeTab = action.payload;
		},
		updateTimeFrame(state, action) {
			state.timeframeSelection = action.payload;
		},
		updateRange(state, action) {
			state.rangeSelection = action.payload;
		},
		updateFromDate(state, action) {
			state.dateFrom = action.payload;
		},
		updateToDate(state, action) {
			state.dateTo = action.payload;
		}
	}
});

export const { updateTab, updateTimeFrame, updateRange, updateFromDate, updateToDate } = dateBrowseSlice.actions;
export default dateBrowseSlice.reducer;