import React, { useState, useEffect} from "react";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { getResults, setSearchInputQuery } from "../../features/search/curated-search-slice";
import Form from 'react-bootstrap/Form';
import { InputGroup } from 'react-bootstrap';
import { Icon } from '@govinfo/react-components';

function CuratedSearchForm() {
	const curatedState = useAppSelector((state) => state.curatedSearch);
	const dispatch = useAppDispatch();
	const [curatedSearchValue, setCuratedSearchValue] = useState<string>(curatedState.query.q);
	const browseBaseUrl = "/app/search/curated/";
	let navigate = useNavigate();
	
	function handleCuratedSearch() {
		navigate(`${browseBaseUrl}${curatedState.query.q}/`);
	};

	return (
		<article className='curated-search'>
				<Form className='curated-search-form'>
					<Form.Label htmlFor="input-curated-search" className='curated-search-label'>Search About, Features, and Help pages</Form.Label>
					<InputGroup>
						<Form.Control
							id="input-curated-search"
							as="input"
							type="text"
							placeholder="Search curated content"
							aria-label="Search word"
							value={curatedState.query.q}
							onChange={e => { dispatch(setSearchInputQuery(e.target.value)) }}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									handleCuratedSearch();
								}
							}}
						/>
						<button
							type="button"
							className="btn-search"
							onClick={ handleCuratedSearch }
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
									handleCuratedSearch();
								}
							}}>
								<Icon id="search" />
								<span className="visually-hidden">Search</span>
						</button>
					</InputGroup>		
				</Form>
			</article>
	)
}

export default CuratedSearchForm