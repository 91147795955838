/// This data set is (for now) intended to hold the static content for Govinfoapp pages.
/// A page "slug" value is passed to the main app page from the router in main.tsx (e.g. <AppBrowseTopic slug="browse-topic-serialset" />)
/// The slug is a unique identifier for a page--analagous to slugs used in CMSs and SSGs.
/// The app page (e.x. AppBrowseTopic) sends the slug value to Redux via the layoutUpdated reducer (see layout-slice.ts).
/// The reducer looks for the slug in this list and assigns the sibling properties to the layout state in the Redux store.
/// The description property is an optional markdown string and used by the PageDescription component, which in turn, uses react-markdown to parse the markdown.
/// Markdown paragraphs are not preceded by multiple spaces or tabs--otherwise it gets parsed as <pre> text.
/// The relatedResources property is an optional array of string slugs/identifiers.
/// The layoutUpdated reducer looks for these slugs in the relatedResources.ts data file and adds the accompanying data to the layout state.
/// Related Resources will be displayed in the order they are listed here.
/// TODO: Investigate if it makes for sense for this to be separate files--one per "page"
/// NOTE: The page title, descriptions, help, RSS, thumbnails and related resources for collection browse pages are pulled from the govinfo static resources repo.
/// The collection browse pages use the generic slug "browse-collection". This one has template info and placeholder info.
/// If the placeholder info displays, then that indicates and issue with retrieving the static resources ui-fragment.
///

export default {
	details: [
		{
			slug: "browse-author",
			template: "browse",
			title: "Browse by Government Author",
			help: "/help/browsing",
			helpButtonLocation: "masthead"
		},
		{
			slug: "browse-category",
			template: "browse",
			title: "Browse by Category",
			help: "/help/browsing",
			helpButtonLocation: "masthead",
			description: "Select a category below to view document collections by type."
		},
		{
			slug: "browse-date",
			template: "browse",
			title: "Browse by Date",
			help: "/help/browsing",
			helpButtonLocation: "masthead",
			description: "View documents by selecting a timeframe or specific date range."
		},
		{
			slug: "browse-frtoc",
			template: "browse",
			title: "Table of Contents"
		},
		{
			slug: "browse-collection",
			template: "browse",
			title: "Collection Browse",
			help: "/help",
			rss: "/rss",
			thumbnail: {}
		},
		{
			slug: "browse-topic-serialset",
			template: "browse",
			title: "Congressional Serial Set - Browse by Topic",
			help: "/help/serial-set",
			description: `This topic-based browse allows users to explore the United States Congressional Serial Set based on topics organized by the first letter of the topic. The assignment and indexing of Library of Congress Subject Headings (LCSH) to documents included in the United States Congressional Serial Set collection, which was digitized by the Law Library of Congress, and is made publicly available through a partnership between the U.S. Government Publishing Office and the Law Library of Congress, makes browsing the content by topic possible.

LCSH are words or phrases that are used to indicate the topics of resources. They are used by most libraries in the United States. LCSH categorizes topics into logical arrangements and maintains consistency by controlling synonyms, variant spellings, and homographs. This allows all resources on a topic to be grouped together which allows users to find them easily. For more information on LCSH, please visit the [Library of Congress Subject and Genre/Form Headings page](https://www.loc.gov/aba/cataloging/subject/) .`,
			relatedResources: [
				"browse-congressional-serial-set",
				"congressional-reports",
				"congressional-committees",
				"congressional-documents",
				"house-journals",
				"featured-house-publications",
				"senate-journals",
				"featured-senate-journals",
				"contact-local-federal-depository-library"
			]
		},
		{
			slug: "browse-topic-cmr",
			template: "browse",
			title: "Congressionally Mandated Reports - Browse by Topic",
			help: "/help/cmr",
			description: `This topic-based browse allows users to explore the Congressionally Mandated Reports collection based on topics organized by the first letter of the topic. Topics are assigned to these reports by the agency that submits each report to GovInfo. The available terms are part of the [Legislative Subject Terms](https://www.congress.gov/help/field-values/legislative-subject-terms) source list that is maintained by the Library of Congress' [Congress.gov](http://congress.gov/) website, and support the ability for users to browse these reports by topic. To browse by agency, go to the main [Congressionally Mandated Reports browse page](/app/collection/CMR).

Congressionally mandated reports are defined as those reports that are required by statute to be submitted by Federal agencies to the Senate, the House of Representatives, or to congressional committees or subcommittees. Agencies must now also submit these reports electronically to the U.S. Government Publishing Office (GPO) after first submitting to Congress, committees, or subcommittees as they do currently.
			
Additional resources related to congressionally mandated reports for Federal agencies can be found at [Congressionally Mandated Reports](https://www.gpo.gov/congressionally-mandated-reports).`,
			relatedResources: [
				"browse-by-government-author",
				"browse-statutes-at-large",
				"congressionally-mandated-reports-guidance",
				"browse-by-congressional-committees",
				"browse-the-congressional-record-daily",
				"browse-the-congressional-record-index",
				"browse-public-and-private-laws",
				"browse-the-us-code",
				"contact-local-federal-depository-library"
			]
		},
		{
			slug: "error-not-found",
			template: "error",
			title: "Page Not Found",
			help: "/help/searching"
		},
		{
			slug: "search-results",
			template: "search-results",
			title: "Search Results",
			help: "/help/search-results",
			helpButtonLocation: "masthead"
		},
		{
			slug: "search-results-curated",
			template: "search-results-curated",
			title: "Search Results",
			help: "/help/finding-info#cs-search"
		}
	]
};
