import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from './app/hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { PageDetails, ResultItem } from "./app/browse.types";
import { getAZLevelResults, getSubjectLevelResults, getResultsLevel, setCollectionCodeForQuery, toggleAZLevelNode, toggleSubjectLevelNode } from "./features/topicbrowse/topic-browse-slice";
import { updateLayout } from "./features/layout/layout-slice";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import { PageHeader, SiteFooter, LoadingSpinner } from "@govinfo/react-components";
import PageDescription from "./components/layout/PageDescription";
import BrowseContainer from "./components/browse/BrowseContainer";
import RelatedResources from "./components/layout/RelatedResources";
import Toasts from "./components/popups/Toasts";

function AppBrowseTopic({ slug }: { slug: string }) {
	const mainContentLinkRef = useRef();
	const dispatch = useAppDispatch();
	const topicsState = useAppSelector((state) => state.topics);
	const layoutState = useAppSelector((state) => state.layout);
	let navigate = useNavigate();
	let { level1, level2, collection } = useParams();
	const browseBaseUrl = `/app/browse/topic/${collection}/`;
	let [containerKey,setContainerKey]= useState(0)

	useEffect(() => {
		dispatch(updateLayout(`${slug}-${collection}`));
		dispatch(setCollectionCodeForQuery(collection.toUpperCase()));
	}, []);
	
	useEffect(() => {
		initBrowse();
	}, [navigate]);

	const initBrowse = () => {	
		dispatch(getAZLevelResults())
			.then((data) => {
				if (level1) {
					dispatch(getSubjectLevelResults(level1)).then(() => {
						if (level2) {
							dispatch(getResultsLevel(
								{
									level1: level1,
									level2: level2,
									pageSize: 100,
									offset: 0,
									sortDirection: 0
								}
							));
						}
					})
				}
			})
	}

	const expandSubjectLevel = (nodeVal: string) => { 
		const index = topicsState.topicBrowse.findIndex(e => e.label == nodeVal)
		if(topicsState.topicBrowse[index].nodes.length==0){
			navigate(`${browseBaseUrl}${nodeVal}`);
		}
	}

	const expandResultsLevelByLabel = (val: string) => {
		// do not navigate if the node is already expanded before. 

		if(topicsState.topicBrowse ){
			let topNodeIndex = topicsState.topicBrowse.findIndex(e => e.label ==val.charAt(0) )
			//@ts-ignore
			let targetNode =topicsState.topicBrowse[topNodeIndex].nodes.find((e)=>{
					return e.label == val
			});
			if((targetNode as ResultItem).results.length==0){
				navigate(`${browseBaseUrl}${val[0]}/${val}`);
			}

		}
	}
	const expandResultsLevel = (page: PageDetails) => {
		dispatch(getResultsLevel(page));
	}

	const handleOnSelectAZNode = (key) => {
		if (`${topicsState.azLevelNodeIndex}` == key) {
			dispatch(toggleAZLevelNode(null));
			dispatch(toggleSubjectLevelNode(null));
			navigate(`${browseBaseUrl}`);
		}
	}

	const handleOnSelectSubjectNode = (key: AccordionEventKey, evt) => {
		const nodeIndex = key[0].substring(key[0].lastIndexOf('-') + 1)
		if (`${topicsState.subjectLevelNodeIndex}` == nodeIndex) {
			dispatch(toggleSubjectLevelNode(null));
			navigate(`${browseBaseUrl}${topicsState.topicBrowse[topicsState.azLevelNodeIndex].label}`);
		} else {
			navigate(`${browseBaseUrl}${topicsState.topicBrowse[topicsState.azLevelNodeIndex].label}/${topicsState.topicBrowse[topicsState.azLevelNodeIndex].nodes[nodeIndex].label}`);
		}
	}
	const incrementKey= ()=>{
		navigate(browseBaseUrl)
		setContainerKey(++containerKey)
	}

	const handleSelects = [handleOnSelectAZNode, handleOnSelectSubjectNode];
	const handleExpandLevels = [expandSubjectLevel, expandResultsLevelByLabel]
	const activeKeys = [topicsState.azLevelNodeIndex, topicsState.subjectLevelNodeIndex];
	const paginationHandlers = {
		sort: (node) => {
			expandResultsLevel({
				level1: node.label.charAt(0),
				level2: node.label,
				pageSize: node.pagination.pageSize,
				offset: node.pagination.offset,
				sortDirection: (node.sortDirection == 0) ? 1 : 0
			})
		},
		next: (node) => {
			expandResultsLevel({
				level1: node.label.charAt(0),
				level2: node.label,
				pageSize: node.pagination.pageSize,
				offset: node.pagination.offset + 1,
				sortDirection: node.sortDirection
			})
		},
		prev: (node) => {
			expandResultsLevel({
				level1: node.label.charAt(0),
				level2: node.label,
				pageSize: node.pagination.pageSize,
				offset: node.pagination.offset - 1,
				sortDirection: node.sortDirection
			})
		},
		gotoPage: (node, pageNum) => {
			expandResultsLevel({
				level1: node.label.charAt(0),
				level2: node.label,
				pageSize: node.pagination.pageSize,
				offset: pageNum - 1,
				sortDirection: node.sortDirection
			})
		},
		updatePageSize: (node, pageSize) => {
			expandResultsLevel({
				level1: node.label.charAt(0),
				level2: node.label,
				pageSize: pageSize,
				offset: node.pagination.offset,
				sortDirection: node.sortDirection
			})
		}
	}

	const widgetCallback = (urlFrag: string) => {
		navigate('/app/search/' + encodeURIComponent(urlFrag.replaceAll('/', '¼')));
	}

	return (
		<>
			<PageHeader layout={layoutState} mainContentLinkRef={mainContentLinkRef} hasSearchWidget={true} widgetCallback={widgetCallback} widgetDefaultOpen={false}>
				{
					(layoutState.slug === "browse-topic-cmr")
						? <aside className="masthead-button-group">
								<a className="btn-ui" href="/app/collection/cmr">Browse by Agency</a>
							</aside>
						: null
				}
				{
					(layoutState.slug === "browse-topic-serialset")
						? <aside className="masthead-button-group">
								<a className="btn-ui" href="/app/collection/serialset">Browse by Congress</a>
							</aside>
						: null
				}
			</PageHeader>

			<PageDescription />

			{
				(topicsState.inProgress && topicsState.topicBrowse.length == 0)
					? <LoadingSpinner size="page"/>
					: null
			}

			<main>
				<h2 className="section-title">{`Browse ${layoutState.hasTheInHeading ? "the " : ""}${layoutState.title.replace("- Browse", "")}`}</h2>
				<Fragment key={containerKey}>
					<BrowseContainer
						// activeKeys={activeKeys}
						nodeLevelIndex={0}
						isLoading={topicsState.inProgress}
						//handleSelects={handleSelects}
						handleExpandLevels={handleExpandLevels}
						paginationHandlers={paginationHandlers}
						nodes={topicsState.topicBrowse}
						collapseAll={incrementKey}
						key={slug}
					/>
				</Fragment>
			</main >

			<RelatedResources />

			<SiteFooter mainContentLinkRef={mainContentLinkRef} />
			<Toasts />
		</>
	);
}
export default AppBrowseTopic;