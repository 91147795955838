import React from 'react';
import { LoadingSpinner } from "@govinfo/react-components";
import CollectionBrowseResults from "../components/browse/CollectionBrowseResults";
import browseUtils from './browseUtils';
import textUtils from './textUtils';
import { NavigateFunction } from 'react-router-dom';
import { getBrowseResults, ChildNode, removeVisitedKey, removeActiveKey } from '../features/collectionbrowse/collection-browse-slice';
// import { ChildNode } from '../app/browse.types';

function createSimpleLeafNode(nodes:ChildNode[], collection:string, navigate:NavigateFunction, parent:ChildNode, sort:number, setSort:Function, dispatch:Function, browseState, layoutState){
	let slug = textUtils.slugify(browseUtils.getCollectionBrowsePath());
	function navAndDispatch(sortInfo) {
		navigate(`/app/collection/${collection}/${parent.nodeValue.browsePathAlias}/${JSON.stringify(sortInfo)}`);
	}

	function showSpinner() {
		// TODO: This is a temporary change. Review the significance and proper usage of this method, and refactor accordingly.
		// NOTE: 
		if (parent.nodeValue.collectionCode.toLowerCase() === 'budget') {
		    return false;
		}
		// NOTE: this logic may not work with budget...
		const browsePath = decodeURIComponent(browseUtils.getCollectionBrowsePath());
		let parentBrowsePathAlias = decodeURIComponent(parent.nodeValue.browsePathAlias);

		const showSpinnerBol = browsePath.includes(parentBrowsePathAlias) && browseState.inProgress;
		return showSpinnerBol;
	}
	return (
		(showSpinner())
			? <LoadingSpinner size="node" />
			: <CollectionBrowseResults
					results={nodes}
					sortDirection={parent.nodeValue.sort}
					sortHandler={(e) => {
						e.preventDefault();
						if (collection == "budget" && browseUtils.getCollectionBrowsePath().includes("/BUDGET")) {
							let sortInfo = browseUtils.getCollectionBrowseSortInfo();
							sortInfo.sortDirection = (parent.nodeValue.sort == 0) ? 1 : 0;
							dispatch(removeVisitedKey(slug));
							navigate(`/app/collection/${collection}/${parent.nodeValue.browsePathAlias}?sortDirection=${sortInfo.sortDirection}`);
						} else {
							dispatch(
								getBrowseResults({
									browsePath: `${collection}/${parent.nodeValue.browsePathAlias}`,
									childrenOnly: 1,
									sortDirection: (parent.nodeValue.sort == 0) ? 1 : 0
								}));
						}
					}}
					key={`leaf-${parent.nodeValue.browsePathAlias.replaceAll(/\//g,'-').replaceAll(/\s/g,'')}`}
					showPagination={ (parent.nodeValue.docCount && parent.nodeValue.docCount > layoutState.pageSizes[0]) && layoutState.paginationEnabled }
					pagination={{
						showPageSelector:true,
						next:() => {
							let sortInfo = browseUtils.getCollectionBrowseSortInfo();
							sortInfo.offset = sortInfo.offset + 1;
							navAndDispatch(sortInfo)
						},
						prev:() => {
							let sortInfo = browseUtils.getCollectionBrowseSortInfo();
							sortInfo.offset = sortInfo.offset - 1;
							navAndDispatch(sortInfo)
						},
						pageSize: parent.nodeValue.pageSize ? parent.nodeValue.pageSize : layoutState.pageSizes[0],
						offset: parent.nodeValue.pageNumber ? parent.nodeValue.pageNumber : 0,
						gotoPage: (e, pagenum: number) => {
							let sortInfo = browseUtils.getCollectionBrowseSortInfo();
							sortInfo.offset = pagenum - 1;
							navAndDispatch(sortInfo);
						},
						pageCount: parent.nodeValue.docCount,
						pageSizeOptions: layoutState.pageSizes,
						onPageSizeChange: (e, pSize) => {
							let sortInfo = browseUtils.getCollectionBrowseSortInfo();
							sortInfo.pageSize = pSize;
							sortInfo.offset=0
							navAndDispatch(sortInfo);
						}
					}}
			/>
	)
}


export default createSimpleLeafNode;