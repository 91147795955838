import React, { MutableRefObject } from 'react';

export interface AnimationRefs {
	requestAnimationRef: number | null,
	duration: number | null, // time in milliseconds
	currentIteration: number | null,
	totalIterations: number | null, // total frames (frame == 1/60 second)
	windowTopStart: number | null,
	windowDelta: number | null,
}

function easeInOutSine (iteration: number, start: number, delta: number, duration: number) {
	// Easing function based on Robert Penner's easing equations
	// with help from https://www.kirupa.com/html5/animating_with_easing_functions_in_javascript.htm
	// and https://www.kirupa.com/js/easing.js
	// and https://easings.net/ (good visualizations on easing equations)

	return delta * (1 - Math.cos(iteration / duration * (Math.PI / 2))) + start;
}

export function handleScroll() {
	const position = window.scrollY;
	return position;
}

export function scrollToPosition(position: number, refs:MutableRefObject<AnimationRefs>, evt:React.MouseEvent<HTMLButtonElement>) {
	const scrollWindow = (timestamp: number) => {
		const windowTop = window.scrollY;
		let deltaTop = position;
		if (timestamp) {
			deltaTop = easeInOutSine(
				refs.current.currentIteration,
				refs.current.windowTopStart,
				refs.current.windowDelta,
				refs.current.totalIterations
			);
			window.scrollTo(position, deltaTop);
		}
		if ((windowTop <= position)) {
			cancelAnimationFrame(refs.current.requestAnimationRef);
		} else {
			refs.current.requestAnimationRef = window.requestAnimationFrame(scrollWindow);
		}
		refs.current.currentIteration += 1;
	}

	refs.current.windowTopStart = window.scrollY;
	refs.current.windowDelta = position - window.scrollY;
	refs.current.currentIteration = position;
	refs.current.requestAnimationRef = window.requestAnimationFrame(scrollWindow);

	evt.stopPropagation();
}