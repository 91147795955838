import { createSlice } from '@reduxjs/toolkit';

interface ToastState {
    show: boolean
}

const initialState: ToastState = {
    show: false
}

const toastSlice = createSlice({
    name:'toast',
    initialState,
    reducers: {
        toggleToast: (state) => {
            state.show =! state.show
        },
    }
});

export default toastSlice.reducer;
export const {toggleToast} = toastSlice.actions;
