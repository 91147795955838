import React, { useState, forwardRef, ReactNode } from "react";
import { useNavigate } from "react-router";
import { Dropdown } from "react-bootstrap";
import { FRCalendar } from "@govinfo/react-components";

const calendarToggle = forwardRef(({children, onClick}: {children?:ReactNode, onClick:(evt) => void;}, ref:any) => {
	return (
		<button
			ref={ref}
			type="button"
			className="btn-calendar"
			onClick={(evt) => {
				evt.preventDefault();
				onClick(evt);
			}}
			title="Select Issue date"
		>
			{children}
		</button>
	)
});

function CalendarDropdown({currentDate, handleCollapseAll}: {currentDate: string, handleCollapseAll?:() => void;}) {
	const [showCalendarDropdown, setShowCalendarDropdown] = useState(false);
	const navigate = useNavigate();
	const toggleCalendarDropdown = (evt) => {
		setShowCalendarDropdown(!showCalendarDropdown);
	}

	return (
		<Dropdown
			autoClose="outside"
			className="calendar-dropdown"
			show={showCalendarDropdown}
			onToggle={toggleCalendarDropdown}
		>
			<Dropdown.Toggle
				as={calendarToggle}
				id="fr-calendar-dropdown"
				tabIndex={0}
				aria-label="Select date"
				aria-haspopup="true"
				onClick={toggleCalendarDropdown}
			>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item>
					<FRCalendar
						selectedDateCallback={(e) => {
							handleCollapseAll();
							toggleCalendarDropdown(e);
							navigate(`/app/frtoc/${e}`);
						}}
						startDate={currentDate}
					/>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default CalendarDropdown;