import React, { useState, useRef } from "react";
import Collapse from "react-bootstrap/esm/Collapse";
import { Navigator, Bucket } from "../../features/search/search-results-slice";
import { useAppDispatch, useAppSelector} from '../../app/hooks';
import {useNavigate, useParams} from 'react-router-dom';
import urlUtil from '../../utility/urlUtil';

const NavigatorFacet = ({navigator, facet}:{navigator:string, facet:Bucket}) => {
  const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const navigatorName = `${navigator.toLowerCase().replaceAll(" ", "-")}`;
	const criteria = useAppSelector((state)=> state.criteria);
	const uriState = useParams();
  let currentFacetSelections= (criteria.facets && criteria.facets[navigator] ) ? criteria.facets[navigator] : [];
	currentFacetSelections = currentFacetSelections.map((entry)=> entry.toString());

	const facetCheckboxRef = useRef(null);

	let expand = false;
	if (navigator) {
		expand = currentFacetSelections.some((value)=> {
			return value !== facet.value && value.indexOf(facet.value) != -1
		});

		if (expand && facetCheckboxRef && facetCheckboxRef.current && !facetCheckboxRef.current.checked) {
			facetCheckboxRef.current.indeterminate = true;
		}

	}
	const [showTreeBucket, setshowTreeBucket] = useState(expand);
	const toggleTreeBucket = () => {
		setshowTreeBucket(!showTreeBucket);
	};

	return (
		<>
			<header id={`navigator-header-${facet.value}`} className="navigator-facet-heading">
				<input
					data-value={facet.value}
					data-displayvalue={navigator}
					id={`navigator-item-${navigator}-${facet.value}`}
					value={`navigator-item-${facet.value}`}
					name="navigator-item"
					type="checkbox"
					ref={facetCheckboxRef}
					checked={currentFacetSelections.includes(facet.value.toString())}
					onChange={(e) => {
						
						if (e.target.checked) {
							navigate(`/app/search/${urlUtil.addNavigator(criteria, navigator, facet.value)}`);
						} else {
							navigate(`/app/search/${urlUtil.removeNavigator(criteria, {nav:navigator, value:facet.value})}`);
						}
					}}
				/>
				<label
					htmlFor={`navigator-item-${navigator}-${facet.value}`}
					className="navigator-facet-label">
						{`${facet.displayValue} (${facet.count})`}
				</label>
				{
					(facet.treeBuckets) &&
						// TODO: combine with other navigator toggles to resuse same component
						<button type="button"
							className="navigator-facet-toggle"
							onClick={toggleTreeBucket}
							aria-expanded={showTreeBucket}
							aria-haspopup="true"
							aria-label={`Refine By ${navigator} in ${facet.displayValue} Filter`}
							aria-controls={`${navigatorName}`}>
							<i className="bi navigator-facet-toggle-caret"></i>
						</button>
				}
			</header>

			{
				(facet.treeBuckets) &&
					<Collapse in={showTreeBucket}>
						<div
							className="navigator-facet-sub-content"
							aria-labelledby={`navigator-header-${navigatorName}-{props.facet.value}`}>
								<ul className="navigator-list-group">
								{
									facet.treeBuckets.map((subFacet) => {
										return(
											<li className={`navigator-list-item`} key={subFacet.value}>
												<NavigatorFacet navigator={navigator} facet={subFacet} />
											</li>
										)
									})
								}
								</ul>
						</div>
					</Collapse>
				}
		</>
	);
}

export default NavigatorFacet