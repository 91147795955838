import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Results from "./Results";
import NoSearchResults from "./NoResultsPanel";
import PaginationControls from "../navigation/PaginationControls";
import { useNavigate, useNavigation } from "react-router-dom";
import urlUtil from "../../utility/urlUtil";

const ResultsContainer = () => {
  const criteria = useAppSelector((state) => state.criteria); // BUG: criteria not receiving pageSize value after search, causing pagination condition eval to false
  const result = useAppSelector((state) => state.results.response);
  const apiError = useAppSelector((state) => state.results.apiError);
  const inprogress = useAppSelector((state) => state.results.inProgress);
  const navigate = useNavigate();
  
  return (
    <section id="search-results">
      {
        (result.iTotalCount / (criteria.pageSize ? criteria.pageSize : 10) > 0)
          ? <PaginationControls
              offset={criteria.offset}
              pageSize={criteria.pageSize}
              pageCount={result.iTotalCount}
              pageSizeOptions={[10, 50, 100]}
              next={(e) => { e.preventDefault(); navigate(`/app/search/${urlUtil.pageSelection(criteria, criteria.offset + 1)}`) }}
              prev={(e) => { e.preventDefault(); navigate(`/app/search/${urlUtil.pageSelection(criteria, criteria.offset - 1)}`) }}
              gotoPage={(e, pageNum) => { e.preventDefault(); navigate(`/app/search/${urlUtil.pageSelection(criteria, pageNum - 1)}`); }}
              onPageSizeChange={(e, psize) => { navigate(`/app/search/${urlUtil.selectPageSize(criteria, parseInt(e.target.value))}`) }}
              showPageSelector={true}
            />
          : null
      }

      {
        ((result.iTotalCount == 0 || apiError) && !inprogress)
          ? <NoSearchResults />
          : <Results />
      }

      {
        (result.iTotalCount / (criteria.pageSize ? criteria.pageSize : 10) > 0)
          ? <PaginationControls
              offset={criteria.offset}
              pageSize={criteria.pageSize}
              pageCount={result.iTotalCount}
              pageSizeOptions={[10, 50, 100]}
              next={(e) => { e.preventDefault(); navigate(`/app/search/${urlUtil.pageSelection(criteria, criteria.offset + 1)}`) }}
              prev={(e) => { e.preventDefault(); navigate(`/app/search/${urlUtil.pageSelection(criteria, criteria.offset - 1)}`) }}
              gotoPage={(e, pageNum) => { e.preventDefault(); navigate(`/app/search/${urlUtil.pageSelection(criteria, pageNum - 1)}`); }}
              onPageSizeChange={(e, psize) => { navigate(`/app/search/${urlUtil.selectPageSize(criteria, parseInt(e.target.value))}`) }}
              showPageSelector={false}
            />
          : null
      }
    </section>
  );
};

export default ResultsContainer;
