import React from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import ToastBasic from './ToastBasic';
import { toggleToast } from '../../features/popups/toast-slice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

function Toasts(props: any) {
  const dispatch = useAppDispatch();
	// const toastState = useAppSelector((state)=> state.toast.show);

	const toggle = () => {
		dispatch(toggleToast());
	}
	// The HTML is set up to hold multiple toasts.
	// TODO: Need to make adjustments to the toggle and state values to also handle multiple toats.

	return (
		<ToastContainer className='toast-info-container'>
			<ToastBasic icon="clipboard" title="Copy URL">URL copied to clipboard.</ToastBasic>
		</ToastContainer>
	);
}

export default Toasts;
