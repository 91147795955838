import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import Markdown from 'react-markdown';
import { Thumbnail } from '@govinfo/react-components';
import ContentSection from './ContentSection';

function PageDescription() {
	const layoutState = useAppSelector((state) => state.layout);
	const { collection } = useParams();

	return (
		<section id="page-summary" className="page-description">
			<ContentSection
				collapsable={true}
				defaultOpen={false}
				helpRibbon={true}
				helpUrl={layoutState.help}
				sectionTitle={`About ${layoutState.hasTheInHeading ? "the " : ""}${layoutState.title}`}
			>
				{
					(layoutState.thumbnail)
						? <Thumbnail thumbnail={layoutState.thumbnail} pageTitle={layoutState.title} />
						: null
				}
				<Markdown
					components={{
						a(props) {
							const {node, ...rest} = props;
							return <a {...rest} target='_blank'></a>
						}
					}}>
					{layoutState.description}
				</Markdown>
			</ContentSection>
		</section>
	)
}

export default PageDescription;