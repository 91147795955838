import React, { useState } from "react";
import { ShareDetailsType } from "@govinfo/react-components";
// import { ResultLineItem } from "../../features/search/search-results-slice";
import ModalShareResultItem from "../popups/ModalShareResultItem";


const ButtonDetailsShare = ({details}: {details: ShareDetailsType}) => {
	const [showModal, setShowModal] = useState(false);
	const closeModal = () => {
		setShowModal(!showModal);
	}

	return (
		<>
			<button type="button" className="btn-details-share"
				onClick={() => {
					setShowModal(true);
				}}>
				Share
			</button>

			<ModalShareResultItem
				details={details}
				show={showModal}
				onClose={closeModal} />
		</>
	);
}

export default ButtonDetailsShare