import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FacetsSummary from "./FacetsSummary";
import SearchSummaryHeader from "./SearchSummaryHeader";
import SearchSummaryDetails from "./SearchSummaryDetails";

function SearchSummary() {
	const criteria = useAppSelector((state) => state.criteria);
	const results = useAppSelector((state) => state.results.response);
	
	return (
		<>
			<section id="search-summary">
					{/* TODO: replace clearQueryUrl string with correct path. Maybe get path from router */}
					{
						criteria.query
							? <SearchSummaryHeader
									searchQuery={criteria.query}
									clearQueryUrl="/app/search/" />
							: null
					}
				
				{
					(criteria.filterOrder && criteria.filterOrder.length > 0)
						? <FacetsSummary key='facet-summary'/>
						: null
				}

				<hr />

				<SearchSummaryDetails
					recordCount={results && results.iTotalCount && results.iTotalCount.toLocaleString("en-US")}
					hasHistoricalToggle={true}
					hasSortSelect={true}
				/>

				<hr />
			</section>
		</>
	);
}

export default SearchSummary;
