import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios'
import { SearchQuery } from './search-criteria-slice';


export interface Bucket {
    value: string;
    displayValue: string;
    selected: boolean;
    count: number;
    valid: boolean;
    totalCount: number;
    treeBuckets: Bucket[];
}

export interface Navigator {
    name: string;
    displayName: string;
    customSortName: string;
    customSortEnabled: boolean;
    type: number;
    tree: boolean;
    selected: boolean;
    collectionSpecific: boolean;
    hasMore: boolean;
    buckets: Bucket[];
}

export interface FieldMap {
    granuleid: string;
    packageid: string;
    htmlfile: string;
    htmlsize: string;
    pdffile:string;
    xmlfile:string;
    other1file:string;
    other1mime:string;
    other2file:string;
    other2mime:string;
    collectionCode: string;
    title: string;
    url: string;
    teaser: string;
}

export interface ResultLineItem {
    index: string;
    line1: string;
    line2: string;
    fieldMap: FieldMap;
}

export interface SearchResult {
    st?: string;
    currentPage?: number;
    iTotalCount: number;
    nextPage?: number;
    previousPage?: number;
    navigators?: Navigator[];
    resultSet?: ResultLineItem[];

}

 export interface SearchResultState {
    response : SearchResult;
    inProgress:boolean;
    downloadProgressCSV:boolean;
    downloadProgressJSON:boolean;
    apiErrorCode:number;
    errorResponse?:string|undefined
    apiError:boolean

};

const initialState:SearchResultState ={
    response:{iTotalCount:0},
    inProgress:false,
    downloadProgressCSV:false,
    downloadProgressJSON:false,
    apiErrorCode:0,
    apiError:false
}

const errorState:SearchResultState ={
    response:{iTotalCount:0},
    inProgress:false,
    downloadProgressCSV:false,
    downloadProgressJSON:false,
    apiErrorCode:0,
    apiError:true
}


export const getResults = createAsyncThunk('results/search',(searchPayload:SearchQuery)=>{
    if(!searchPayload.facetCustomSorts){
        
    }
    return axios.post('/wssearch/search',searchPayload).then(({data})=>data).catch((error) =>{ 
        initialState.apiError=true
        return initialState
    })
})

export const downloadCSVResults = createAsyncThunk('results/csvdownload',(searchPayload:SearchQuery)=>{
    return axios.post('/wssearch/search/download?contentType=csv',searchPayload).then((response)=>{

        let fileName:string="unnamed." + 'csv'
        if(response.headers['content-disposition']){
            fileName = response.headers['content-disposition'].substring(response.headers['content-disposition'].indexOf("=")+1)
        }

        var binaryData = [];
        binaryData.push(response.data);
        let a = document.createElement('a');
        const href = URL.createObjectURL(new Blob(binaryData, {type:'application/csv'}));
        a.href =href;
        // Give filename you wish to download
        a.setAttribute('download',fileName) ;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        // makes button clickable
        document.body.removeChild(a);
        URL.revokeObjectURL(href);
    })
});

export const downloadJSONResults = createAsyncThunk('results/jsondownload',(searchPayload:SearchQuery)=>{
    return axios.post('/wssearch/search/download?contentType=json',searchPayload).then((response)=>{
        let fileName:string="unnamed." + 'json'
        if(response.headers['content-disposition']){
            fileName = response.headers['content-disposition'].substring(response.headers['content-disposition'].indexOf("=")+1)
        }
        // Create Blob with correct JSON MIME type
        const blob = new Blob([JSON.stringify(response.data, null, 2)], { 
            type: 'application/json' 
        });
        var binaryData = [];
        binaryData.push(JSON.stringify(response.data));
        let a = document.createElement('a');        
        const href = URL.createObjectURL(blob);
        a.href =href;
        // Give filename you wish to download
        a.setAttribute('download',fileName) ;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        // makes button clickable
        document.body.removeChild(a);
        URL.revokeObjectURL(href);

   })
});




const resultsSlice = createSlice({
    name:'results',
    initialState,
    reducers:{

        doSearch(state,{payload}){
            // do the api call and do the asyncThunk
          
        }

    },
    extraReducers:(builder) =>{
            builder.addCase(getResults.pending,(state, action) =>{
                state.inProgress=true
             }),
            builder.addCase(getResults.rejected,(state, action) =>{

                return errorState
             }),             
            builder.addCase(getResults.fulfilled,(state, action) =>{
                // state.iTotalCount =(action.payload as SearchResult).iTotalCount
                // state.resultSet =(action.payload as SearchResult).resultSet
                state.apiError=false
                state.response=action.payload
                state.inProgress=false;
                return state
            }),
            builder.addCase(downloadJSONResults.pending,(state, action) =>{
                state.downloadProgressJSON=true
             }),
             builder.addCase(downloadJSONResults.fulfilled,(state, action) =>{
                state.downloadProgressJSON=false
             }),
             builder.addCase(downloadCSVResults.pending,(state, action) =>{
                state.downloadProgressCSV=true
             }),
             builder.addCase(downloadCSVResults.fulfilled,(state, action) =>{
                state.downloadProgressCSV=false
             })
             

    }
})


export const {doSearch} = resultsSlice.actions;
export default resultsSlice.reducer;
