import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from './app/hooks';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getResults, setPageSize } from "./features/search/curated-search-slice";
import { updateLayout } from "./features/layout/layout-slice";
import { PageHeader, SiteFooter, LoadingSpinner } from "@govinfo/react-components";
import CuratedSearchSummary from "./components/curatedSearchResults/CuratedSearchSummary";
import CuratedSearchResults from "./components/curatedSearchResults/CuratedSearchResults";
import NoSearchResults from "./components/searchResults/NoResultsPanel";
import Toasts from "./components/popups/Toasts";

function AppSearchCurated({ slug }: { slug: string }) {
	const mainContentLinkRef = useRef();
	const dispatch = useAppDispatch();
	const topicsState = useAppSelector((state) => state.topics);
	const curatedState = useAppSelector((state) => state.curatedSearch);
	const layout = useAppSelector((state) => state.layout);
	const [ currentPage, setCurrentPage ] = useState<number>(1);
	let navigate = useNavigate();
	let location = useLocation();
	let { term, page } = useParams();
	let pageSize = 10; // HACK: Temporary value. Change this to a state or redux value for page size select
	const baseUrl = "/app/search/curated/";

	useEffect(() => {
		dispatch(updateLayout(slug));
	}, []);

	useEffect(() => {
		setCurrentPage((page != undefined || page == "0") ? (parseInt(page)) : 0);
	}, [location.key]);
	
	useEffect(() => {
		const urlQueryParams = {
			q: (term != undefined ? term : ""),
			start: ((page != undefined || page == "0") ? (parseInt(page) - 1) * pageSize : 0)
		}
		dispatch(getResults(urlQueryParams));
	}, [location.key]);

	return (
		<>
			<PageHeader layout={layout} mainContentLinkRef={mainContentLinkRef} hasSearchWidget={false} widgetDefaultOpen={false} />

			{
				(curatedState.inProgress)
					? <LoadingSpinner size="page" />
					: null
			}

			<CuratedSearchSummary />

			<main>
				{
					(curatedState.response && curatedState.response.numFound > 0)
						? <CuratedSearchResults
								results={curatedState.response}
								pagination={{
									showPageSelector: false,
									pageCount: curatedState.response.numFound,
									offset: currentPage - 1,
									pageSize: 10,
									next: (evt) => {
										evt.preventDefault();
										navigate(`${baseUrl}${curatedState.responseHeader.params.q}/${(currentPage? currentPage:1) + 1}`);
									},
									prev: (evt) => {
										evt.preventDefault();
										navigate(`${baseUrl}${curatedState.responseHeader.params.q}/${(currentPage? currentPage:1) - 1}`);
									},
									gotoPage: (evt, pageNum) => {
										evt.preventDefault();
										navigate(`${baseUrl}${curatedState.responseHeader.params.q}/${pageNum}`);
									},
									onPageSizeChange: (e, pSize) => {
										setPageSize(pSize)
									},
									pageSizeOptions: [10, 50, 100]
								}} />
						: <NoSearchResults />
				}
			</main >

			<SiteFooter mainContentLinkRef={mainContentLinkRef} />
			<Toasts />
		</>
	);
}
export default AppSearchCurated;