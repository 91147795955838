import React from "react";
import { useAppSelector } from '../../app/hooks';
import { CuratedResponse } from "../../features/search/curated-search-slice";
import PaginationControls, { PaginationControlsProps } from "../navigation/PaginationControls";
import ResultItem from "../navigation/ResultItem";

function CuratedSearchResults({ results, pagination }: { results: CuratedResponse, pagination: PaginationControlsProps} ) {
	const curatedState = useAppSelector((state) => state.curatedSearch);

	return (
		<>
			{
				(results.numFound > 0)
					? <PaginationControls
							offset={pagination.offset}
							pageSize={pagination.pageSize}
							pageCount={results.numFound}
							pageSizeOptions={[10, 50, 100]}
							next={pagination.next}
							prev={pagination.prev}
							gotoPage={pagination.gotoPage}
							onPageSizeChange={pagination.onPageSizeChange}
							showPageSelector={false}
						/>
					: null
			}
			{/* TODO: Make into component and/or fold into navigator sort button component */}
			{/* {
				<p className="browse-item-sort">
					<button
						className={`btn-browse-sort numeric-${props.sortDirection == 1 ? "asc": "desc"}`}
						onClick = {props.sortHandler}>
						Sort
					</button>
				</p>
			} */}
			{
				(results.docs && results.docs.length > 0)
					? <ol
							id="results-view"
							className="search-results-list"
							start={results.start + 1}
						>
							{
								results.docs.map((item, index) => {
									return (
										<li className="results-row" key={`item-${index}`}>
											<ResultItem
												title={`${item.title} *${item.docclass}*`}
												packageid={item.packageid}
												url={item.url}
												teaser={item.teaser}
												hasThumbnail={false}
												key={`${item.packageid}-${index}`}
												hasPill={true}
											/>
										</li>
									)
								})
							}
						</ol>
					: null
			}
			
			{
				(results.numFound > 0)
					? <PaginationControls
							offset={pagination.offset}
							pageSize={pagination.pageSize}
							pageCount={results.numFound}
							pageSizeOptions={[10, 50, 100]}
							next={pagination.next}
							prev={pagination.prev}
							gotoPage={pagination.gotoPage}
							onPageSizeChange={pagination.onPageSizeChange}
							showPageSelector={false}
						/>
					: null
			}
		</>
	)
}

export default CuratedSearchResults;