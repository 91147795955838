import React from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { Navigator, Bucket } from "../../features/search/search-results-slice";
import urlUtil from '../../utility/urlUtil'
import { useNavigate } from "react-router-dom";

const FacetField = (props: { facetValues: string[]; navigator: Navigator }) => {
	const findDateBucket = (buckets: Bucket[], fragment: string): Bucket => {
		return buckets.filter((val) => val.value == fragment)[0];
	};
	const navigate = useNavigate();
	const criteria = useAppSelector((state) => state.criteria)
	const removeNavigatorVal = (e: React.MouseEvent<HTMLButtonElement>, body: { nav: string, value: string }) => {
		navigate(`/app/search/${urlUtil.removeNavigator(criteria, body)}`)
	}
	const formatDateDisplay = (dateVals) => {
		const facetDate = new Date(dateVals.join("-") + " 00:00");
		const monthYearFormat = Intl.DateTimeFormat("en-us", {month: "long", year: "numeric"});
		const monthDayYearFormat = Intl.DateTimeFormat("en-us", {month: "long", day: "numeric", year: "numeric"});

		if (dateVals.length == 1) {
			return dateVals.toString();
		} else if (dateVals.length == 2) {
			return monthYearFormat.format(facetDate);
		} else {
			return monthDayYearFormat.format(facetDate);
		}
		
		return null;
	}

	return (
		<>
			{
				props.facetValues && props.facetValues.map((val, index) => {
					let b = {} as Bucket;
					//if (props.navigator.name == "publishdatehier" || props.navigator.name == 'dochierarchy') {
					let nav_fragments: string[] = val && val.toString().indexOf('/') != -1 ? val.toString().split("/") : [val];
					let bs: Bucket[] = props.navigator.buckets;
					let f;
					let facetDate = (props.navigator.name == "publishdatehier")
													?	formatDateDisplay(nav_fragments)
													:	null;
					
					for (let i: number = 0; i < nav_fragments.length && bs ; i++) {
						if (i > 0) {
							f = f + "/" + nav_fragments[i];
						} else {
							f = nav_fragments[i];
						}
						b = findDateBucket(bs, f);
						bs = b && b.treeBuckets;

						!b && 
						console.log('f is '+ f)
					}

					return (
						<dd className="facet-field" key={`${val}-${index}`}>
							<button
								type="button"
								className="facet-field-value"
								data-value={val.toString().replaceAll("/", "-")}
								onClick={e => { removeNavigatorVal(e, { nav: props.navigator.name, value: (b) ? b.displayValue : f }) }}
							>
								{
									(facetDate)
										? facetDate
										: (b)
											? b.displayValue
											: nav_fragments.join("/")
								}
							</button>
						</dd>
					
					);
					// } else {
					// 	// for other navigators
					// 	b = findDateBucket(props.navigator.buckets, val);
					// 	if (b && b.displayValue) {
					// 		return (
					// 			<dd className="facet-field" key={`${val}-${index}`}>
					// 				<button
					// 					type="button"
					// 					className="facet-field-value"
					// 					data-value={val}
					// 					onClick={e => { removeNavigatorVal(e, { nav: props.navigator.name, value: b.displayValue }) }}
					// 				>
					// 					{b.displayValue} <i className="filter-destroy bi bi-x"></i>
					// 				</button>
					// 			</dd>
					// 		);
					// 	}
					// }
				})}
		</>
	);
};
export default FacetField