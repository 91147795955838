import React from "react";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';


const NavigatorToggle =(props:any)=>{
    const togglePanel = useAccordionButton(props.eventKey, props.toggleOnClick);

	return (

		<button type="button"
			className="navigator-panel-toggle"
			onClick={togglePanel}
			aria-expanded={props.toggleState}
			aria-label={`Refine by ${props.children} Filter`}
			aria-haspopup="true">
			{props.children}
		</button>
	);
}

export default NavigatorToggle