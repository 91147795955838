import React from 'react'

function ButtonClose(props:{onClose:()=>void}) {

	return (
		<button type='button'
			className='btn-ui'
			onClick={props.onClose}>
			Close
		</button>
	);
}

export default ButtonClose;