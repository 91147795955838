import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import browseUtils from '../../utility/browseUtils';
import { RootState } from '../../app/store';
import { NodeValue } from '../../app/browse.types';

export interface BrowseTree {
	childNodes: ChildNode[]
	nodeValue?: NodeValue
	books: any[]
	inProgress: boolean
}

export interface ChildNode {
	childNodes: ChildNode[]
	nodeValue: NodeValue
	books: any[]
	inProgress: boolean
	activeKeys:string[]
	visitedKeys:string[]
	visitedPaths:string[]
	containerKey: number
}

export const getBrowseResults = createAsyncThunk('collectionBrowse/getResults', (payload: { browsePath: string, childrenOnly: number, sortDirection?: number }, { getState }) => {
	const state = getState() as RootState;
	// check if data is already present for this path.
	// when pressing the back button, we dont have the make the api call (except for the leaf nodes :) 
	// If it is not a leaf node do not make api call, but remove the active keys(for child paths) for leaf nodes to close them
	// debugger;
	let targetNode:ChildNode = findNode(state.collections, getBrowseAlias(payload.browsePath).split('/').filter((t) => t != ''));
	if (targetNode.childNodes.length == 0 || isLeafNode(targetNode)) {
		let fetchChildrenOnly = isPartialLoad(payload.browsePath, state.collections);
		let path = `/wssearch/rb/${decodeURIComponent(payload.browsePath)}?fetchChildrenOnly=${fetchChildrenOnly}&sortDirection=${(payload.sortDirection != undefined) ? payload.sortDirection : 1}`.replace("/?", "?");
		let sortInfo = browseUtils.getCollectionBrowseSortInfo();
		if (sortInfo && state.layout.paginationEnabled) { // has sort info in the url
			path = `${path}&pageSize=${(sortInfo.pageSize && sortInfo.pageSize != 0) ? sortInfo.pageSize : state.layout.pageSizes[0]}&offset=${(sortInfo.offset) ? sortInfo.offset : 0}`;
		}
		return axios.get(path)
			.then(({ data }) => {
				return {
					response: data,
					fetchChildrenOnly: `${fetchChildrenOnly}`,
				};
			});
	}
})

// this function is used to determine fetchChildrenOnly=1/0 1 for partial load 0 for complete tree load
// It is determined by the state. check the vinited keys (its a record of accordion panels that were opend/visited before)
// for example if user opens /bills/118/s/[0-99] you only need to do the partial load of [0-99] as the state for /bills/118/s/ be already presnt

function isPartialLoad(browsePath: string, state: ChildNode) {
	// return 0 if child nodes of top level state are empty
	if (state.childNodes.length === 0) {// no tree in the state at all
		return 0;
	}
	browsePath = getBrowseAlias(browsePath);
	const collectionCode = state.childNodes[0].nodeValue.collectionCode.toUpperCase();
	switch (true) {
		case browsePath.indexOf('/') === -1: // adjustment for top node
			return 1;

		case state.visitedPaths.includes(browsePath):
			return 1;

		case collectionCode === 'CCAL' || collectionCode === 'CREC':
			return state.visitedPaths.length > 0 ? 1 : 0;

		default: {
			browsePath = browsePath.endsWith("/") ? browsePath.slice(0, -1) : browsePath;
			browsePath = browsePath.slice(0, browsePath.lastIndexOf('/'));

			return state.visitedPaths.includes(browsePath) ? 1 : 0;
		}
	}
}
// this function is removing collection and trailing slash. Example /bills/117 --> 117 
// bills/117/s ---> 117/s
function getBrowseAlias(browsePath:string) {
	if(browsePath.lastIndexOf('/') == browsePath.length-1) {
		browsePath=browsePath.slice(0,-1);
	}
	if (!browsePath.startsWith('/')) {
		browsePath = browsePath.slice(browsePath.indexOf('/') + 1);
	}
	return browsePath;
}

// this function is to know if the node is leafnode
// when user revisits the leafnode again we are still going to get the data from the api.
// Reason: complexity. you need to maintain the pagination,sort and page size info, it gets too complicated.
function isLeafNode(targetNode: ChildNode) {
	return targetNode && targetNode.childNodes && targetNode.childNodes.length >0 && targetNode.childNodes[0].nodeValue.packageid
}

const initialState: ChildNode = {
	childNodes: [],
	nodeValue: {},
	books: [],
	inProgress: false,
	activeKeys:[],
	visitedKeys:[],
	visitedPaths:[],
	containerKey:1
}

const collectionsSlice = createSlice({
	name: 'collectionBrowse',
	initialState,
	reducers: {
		removeActiveKey(state, action){
			const index = state.activeKeys.indexOf(action.payload);
			if (index > -1) {
				state.activeKeys.splice(index, 1);
			}
		},
		addActiveNodeKey(state, action) {
			const nodeKeyIndex = state.activeKeys.indexOf(action.payload);
			if (nodeKeyIndex == -1) {
				updateActiveKeys([action.payload], state);
			}
		},
		removeVisitedKey(state, action) {
			let visitedKeys = state.visitedKeys;
			let visitedKeyIndex = state.visitedKeys.indexOf(action.payload);
			if (visitedKeyIndex !== -1) {
				visitedKeys.splice(visitedKeyIndex, 1);
			}
			state.visitedKeys = [...visitedKeys];
		},
		collapseAllNodes(state,action){
			state.activeKeys.splice(0,state.activeKeys.length)
			state.containerKey= state.containerKey+1
		}
		
	},
	extraReducers: (builder) => {
		builder.addCase(getBrowseResults.fulfilled, (state, action) => {
			updateBrowseTree(state, action);
			if (action.payload) {
				state.books = action.payload.response.books
			}
			state.inProgress = false;
		})
		.addCase(getBrowseResults.pending, (state, action) => {
			//let targetNode:ChildNode = findNode(state.collectionBrowse, getBrowseAlias(payload.browsePath).split('/').filter((t)=>t!=''))
			state.inProgress = true
		})
		.addCase(getBrowseResults.rejected, (state, action) => {
			state.inProgress = false
		})
	}
})

const updateBrowseTree = (state: ChildNode, action) => {
	let pathTokens:string[] = action.meta.arg.browsePath.split("/");
	pathTokens = pathTokens.filter((t) => t != '').filter((t, i) => i != 0);
	//// this is needed for the double submit. refactor 3rd parameter to createAsyncThunk  using https://redux-toolkit.js.org/api/createAsyncThunk
	// condition: (userId, { getState, extra }) => {

	if (action.payload && action.payload.fetchChildrenOnly && action.payload.fetchChildrenOnly == '0') {
		// HACK: Correction for weird edge case in budget. This should probably be revisited in a more holistic manner.
		// This assumes the top level nodes are always sortDirection=1 and the user cannot change that sort order.
		if (action.meta.arg.sortDirection == 0 && action.meta.arg.browsePath.includes("budget/") && action.meta.arg.browsePath.length > "budget/".length) {
			if (parseInt(action.payload.response.childNodes[0].nodeValue.browsePathAlias) < parseInt(action.payload.response.childNodes[action.payload.response.childNodes.length - 1].nodeValue.browsePathAlias)) {
				state.childNodes = action.payload.response.childNodes.reverse();
			}
		} else {
			state.childNodes = action.payload.response.childNodes;
		}
		
		updateActiveKeys(pathTokens,state);
		return;
	}

	if (action.payload && action.payload.response) { // action.payload is null if the api call is skipped. It happens when back button is pressed
		let parentNode:ChildNode = findNode(state, pathTokens); //.childNodes=action.payload.childNodes
		parentNode.childNodes = action.payload.response.childNodes;
		parentNode.nodeValue = {...parentNode.nodeValue, ...action.payload.response.nodeValue};
		parentNode.books = action.payload.response.books;
		let sortinfo = browseUtils.getCollectionBrowseSortInfo();
		// sort direction take it from payload as it is removed from url
		parentNode.nodeValue.sort = action.meta.arg.sortDirection;
		parentNode.nodeValue.pageSize = sortinfo.pageSize;
		parentNode.nodeValue.offset = sortinfo.offset;
		// update parent node with pagination info
	}

	updateActiveKeys(pathTokens, state);
	// removeChildKeysAndAddCurrentkey(state,pathTokens)
}

// given the browse path tokens it shall find the node in the state.
// in case it did not find it it will return the parent node
const findNode = (state: ChildNode, tokens: string[]) => {
let target:ChildNode = state; 
let targetPath = null;
	tokens.forEach((token, index) => {
		targetPath = targetPath == null ? token : targetPath + `/${token}`;
		let childNode:ChildNode = findChildNodeNode(target, targetPath);
		if (childNode) {
			target = childNode ;
			//target.nodeValue.open=true
		}
	});

	return target;
}

const updateActiveKeys = (tokens: string[], state: ChildNode) => {
	let path = null;
	let key = null;
	tokens.forEach((token, index) => {
		path = decodeURIComponent(path==null ? token: `${path}/${token}`).replaceAll(/\s/g,'');
		// key = decodeURIComponent(path).replaceAll(/\//g,'-').replaceAll(/\s/g,'');
		key = path.replaceAll(/\//g,'-').replaceAll(/\s/g,'');
		if (state.activeKeys.indexOf(key) == -1) {
			state.activeKeys.push(key);
		}
		if (state.visitedKeys.indexOf(key) == -1) {
			state.visitedKeys.push(key);
		}
		if (state.visitedPaths.indexOf(key) == -1) {
			state.visitedPaths.push(path);
		}
	})

	if (key) {
		path = path.replaceAll(/\//g,'-').replaceAll(/\s/g,'');
		state.activeKeys = state.activeKeys.filter(k => (k == path || !k.includes(path))); // childkeys are removed
	} else {
		state.activeKeys.splice(0, state.activeKeys.length);
	}
}

const findChildNodeNode=(state: ChildNode, token:string) => {
	let foundIndex = -1;
	let found = state.childNodes.find((node, i) => {
		if (token.includes("BUDGET") && node.childNodes.length > 0) { // HACK
			foundIndex = node.childNodes.findIndex((n) => {
				return decodeURIComponent(`${n.nodeValue.packageid}`) == decodeURIComponent(token);
			});
			if (foundIndex > -1) {
				return node;
			}
		} else if (node.nodeValue.hasgranules && node.nodeValue.hasgranules === "true") {
			 return decodeURIComponent(`${node.nodeValue.browsePathAlias}/${node.nodeValue.packageid}`) == decodeURIComponent(token);
		} else {
			return decodeURIComponent(node.nodeValue.browsePathAlias) == decodeURIComponent(token);
		}
	});
	if (foundIndex > -1) {
		return found.childNodes[foundIndex];
	} else {
		return found;
	}
} 

const removeChildKeysAndAddCurrentkey = (state: ChildNode, tokens: string[]) => {
	let targetPath= tokens.join('/');
	// do remove active keys
	if (targetPath) {
		targetPath = targetPath.replaceAll(/\//g,'-').replaceAll(/\s/g,'');
		state.activeKeys = state.activeKeys.filter(key => (key==targetPath || !key.includes(targetPath))); // childkeys are removed
		
		let currentToken = null;
		tokens.forEach((t) => {
			currentToken = currentToken ? currentToken+'/' + t : t;
			let key = currentToken.replaceAll(/\//g,'-').replaceAll(/\s/g,'');
			if (!state.activeKeys.includes(key)) {
				state.activeKeys.push(key);
			}
		});
	} else {
		state.activeKeys.splice(0, state.activeKeys.length);
	}
}

export const { removeActiveKey, addActiveNodeKey, removeVisitedKey, collapseAllNodes } = collectionsSlice.actions;
export default collectionsSlice.reducer;
