import React from "react";
import { ModalBasic, ShareDetailsType } from "@govinfo/react-components";

// import Modal from 'react-bootstrap/Modal';
// import ModalHeader from 'react-bootstrap/ModalHeader';
// import ModalBody from 'react-bootstrap/ModalBody';
// import ModalFooter from 'react-bootstrap/ModalFooter';
// import ShareIcons from '../navigation/ShareIcons';
// import ButtonClose from '../navigation/ButtonClose';
import { ResultLineItem } from "../../features/search/search-results-slice";

const ModalShareResultItem = (
	{ details, show, onClose}: {
		details: ShareDetailsType,
		show: boolean,
		onClose: () => void
	}) => {

		// https://www.govinfo.gov/app/details/FR-2006-12-15/E6-21193
		// /app/details/PAI-2025-COMMERCE-interim

  return (
		// <!-- Modal - Share Result -->
		<ModalBasic
			show={show}
			onClose={onClose}
			icon="share"
			title="Share"
			hasShareIcons={true}
			details={details}
			// hasDownload={false}
			downloadCallback={() => {}}
			toastTitle="Copy"
		>
			<h1 className="result-title">{details.title}</h1>
				{
					(details.subtitle)
						? <h2 className="document-title">{details.subtitle}</h2>
						: null
				}			
		</ModalBasic>
		// <Modal
		// 	show={show}
		// 	onHide={onClose}>
		// 	<ModalHeader closeButton>
		// 		<h2 className="modal-title"><i className="bi bi-share-fill"></i>Share</h2>
		// 	</ModalHeader>
		// 	<ModalBody>
		// 		<h1 className="result-title">{details.title}</h1>
		// 		{
		// 			(details.subtitle)
		// 				? <h2 className="document-title">{details.subtitle}</h2>
		// 				: null
		// 		}
		// 		{/* <!-- TODO: This will need fine tuning once populated with actual content --> */}
		// 	</ModalBody>
		// 	<ModalFooter as="footer">
		// 		{/* <aside className="share-icons"> */}
		// 			<ShareIcons isModal={true} details={details} hasDownload={false} />
		// 		{/* </aside> */}
				
		// 		<ButtonClose onClose={onClose} />
		// 	</ModalFooter>
		// </Modal>
	);
};

export default ModalShareResultItem;
