export default {
	resourceList: [
		{
			slug: "a-guide-to-the-rulemaking-process-pdf",
			title: "A Guide to the Rulemaking Process (PDF)",
			description: "The guide is prepared by the Office of the Federal Register, National Archives and Records Administration.",
			href: "https://www.federalregister.gov/uploads/2011/01/the_rulemaking_process.pdf"
		},
		{
			slug: "about-positive-and-non-positive-law",
			title: "About Positive and Non-Positive Law",
			description: "Learn about the distinction between positive versus non-positive law titles of the U.S. Code on the House Office of the Legislative Counsel (HOLC) website.",
			href: "https://legcounsel.house.gov/holc-guide-legislative-drafting#IIB"
		},
		{
			slug: "additional-eric-reports",
			title: "Additional Eric Reports",
			description: "View reports on the Department of Education website.",
			href: "http://eric.ed.gov/"
		},
		{
			slug: "bills-published-in-the-last-24-hours",
			title: "Bills Published in the Last 24 Hours",
			description: "View search results containing Congressional bills published in the last 24 hours.",
			href: "/app/latestbills"
		},
		{
			slug: "browse-cfr-parts-afected-from-the-federal-register",
			title: "Browse CFR Parts Affected from the Federal Register",
			description: "Browse final and proposed rules that affect the CFR and have been published in the Federal Register within the past 24 hours, week, month, or within a specific date range.",
			href: "/app/cfrparts/day"
		},
		{
			slug: "browse-by-congressional-committees",
			title: "Congressional Committees",
			description: "Browse documents issued by individual committees including Bills, Documents, and Hearings.",
			href: "/browse/committee"
		},
		{
			slug: "browse-by-government-author",
			title: "Browse by Government Author",
			description: "Browse documents issued by selected government authors.",
			href: "/app/browse/author"
		},
		{
			slug: "browse-by-topic-serial-set",
			title: "Browse by Topic",
			description: "Browse the Serial Set by Topic.",
			href: "/app/browse/topic/serialset/"
		},
		{
			slug: "browse-featured-house-publications",
			title: "Browse Featured House Publications",
			description: "Browse miscellaneous House publications.",
			href: "/collection/featured-house-publications"
		},
		{
			slug: "browse-featured-senate-publications",
			title: "Browse Featured Senate Publications",
			description: "Browse miscellaneous Senate publications.",
			href: "/collection/featured-senate-publications"
		},
		{
			slug: "browse-legislative-calendars",
			title: "Browse Legislative Calendars",
			description: "View calendars from select Congressional Committees.",
			href: "/collection/legislative-calendars"
		},
		{
			slug: "browse-public-and-private-laws",
			title: "Browse Public and Private Laws",
			description: "View Congressional Bills organized by Congress.",
			href: "/app/collection/plaw"
		},
		{
			slug: "browse-statutes-at-large",
			title: "Browse Statutes at Large",
			description: "View the permanent collection of all laws and resolutions enacted during each session of Congress.",
			href: "/app/collection/statute"
		},
		{
			slug: "browse-the-budget-of-the-us-government",
			title: "Browse the Budget of the U.S. Government",
			description: "View documents that contain the budget message of the President, information about the President's budget proposals for a given fiscal year, and other budgetary publications that have been issued throughout the fiscal year.",
			href: "/app/collection/budget"
		},
		{
			slug: "browse-the-code-of-federal-regulations",
			title: "Browse the Code of Federal Regulations",
			description: "View the annual edition of the codified general and permanent rules published in the Federal Register by the departments and agencies of the Federal Government.",
			href: "/app/collection/cfr"
		},
		{
			slug: "browse-the-congressional-pictorial-directory",
			title: "Browse the Congressional Pictorial Directory",
			description: "View color photographs of all of the men and women of Congress.",
			href: "/collection/congressional-pictorial-directory"
		},
		{
			slug: "browse-the-congressional-record-bound-edition",
			title: "Browse the Congressional Record (Bound Edition)",
			description: "View daily editions of the Congressional Record that have been collected, re-paginated, and re-indexed into a permanent, bound edition.",
			href: "/app/collection/crecb_gpo/_crecb"
		},
		{
			slug: "browse-the-congressional-record-daily",
			title: "Browse the Congressional Record (Daily)",
			description: "View edtions of the Congressional Record, published daily when Congress is in session.",
			href: "/app/collection/crec"
		},
		{
			slug: "browse-the-congressional-record-index",
			title: "Browse the Congressional Record Index",
			description: "The Index lists individuals, organizations, and topics mentioned in the Congressional Record.",
			href: "/app/collection/cri"
		},
		{
			slug: "browse-congressional-serial-set",
			title: "Browse the Congressional Serial Set",
			description: "Browse documents that are published as part of the U.S. Congressional Serial Set, bound by Session of Congress.",
			href: "/app/collection/serialset"
		},
		{
			slug: "browse-the-us-code",
			title: "Browse the U.S. Code",
			description: "View the United States Code, the codification by subject matter of the general and permanent laws of the United States.",
			href: "/app/collection/uscode"
		},
		{
			slug: "budget-amendments",
			title: "Budget Amendments",
			description: "Search requests for supplemental appropriations.",
			href: "/app/search/%7B%22query%22%3A%22collection%3Acdoc%20and%20%5C%22budget%20amendments%5C%22%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "catalog-of-us-government-publications",
			title: "Catalog of U.S. Government Publications",
			description: "Visit the CGP to search for print and electronic titles of the National bibliography of U.S. Government Publications.",
			href: "http://catalog.gpo.gov/"
		},
		{
			slug: "cfr-parts-affected-from-the-federal-register",
			title: "Browse CFR Parts Affected from the Federal Register",
			description: "Browse final and proposed rules that affect the CFR and have been published in the Federal Register within the past 24 hours, week, month, or within a specific date range.",
			href: "/app/cfrparts/day"
		},
		{
			slug: "challenger-sts-51l-accident-from-nasas-history-program-office",
			title: "Challenger STS-51L Accident from NASA's History Program Office",
			description: "Visit NASA's website to access more information about the Challenger STS 51-L accident.",
			href: "http://history.nasa.gov/sts51l.html"
		},
		{
			slug: "code-of-federal-regulations",
			title: "Code of Federal Regulations",
			description: "View the annual edition of the codified general and permanent rules published in the Federal Register by the departments and agencies of the Federal Government.",
			href: "/app/collection/cfr"
		},
		{
			slug: "congress-gov",
			title: "Congress.gov",
			description: "Official website for U.S. Federal legislative information.",
			href: "https://www.congress.gov/"
		},
		{
			slug: "congressional-calendars",
			title: "Congressional Calendars",
			description: "View House and Senate Congressional Calendars.",
			href: "/app/collection/ccal"
		},
		{
			slug: "congressional-committees",
			title: "Congressional Committees",
			description: "Browse documents issued by individual committees including Bills, Documents, and Hearings.",
			href: "/browse/committee"
		},
		{
			slug: "congressional-documents",
			title: "Congressional Documents",
			description: "Browse Congressional Documents.",
			href: "/app/collection/cdoc"
		},
		{
			slug: "congressional-record-index",
			title: "Congressional Record Index",
			description: "The Index lists individuals, organizations, and topics mentioned in the Congressional Record.",
			href: "/app/collection/cri"
		},
		{
			slug: "congressional-reports",
			title: "Congressional Reports",
			description: "Browse Congressional Reports.",
			href: "/app/collection/crpt"
		},
		{
			slug: "congressionally-mandated-reports-guidance",
			title: "Congressionally Mandated Reports Guidance",
			description: "View submission guidance for Federal agencies and other resources related to the *Access to Congressionally Mandated Reports Act*.",
			href: "https://www.gpo.gov/how-to-work-with-us/agency/congressionally-mandated-reports"
		},
		{
			slug: "contact-local-federal-depository-library",
			title: "Contact a Local Federal Depository Library",
			description: "Visit a local Federal depository library to access a physical copy of this publication.",
			href: "https://ask.gpo.gov/s/FDLD"
		},
		{
			slug: "currently-updated-online-government-manual",
			title: "Currently Updated Online Government Manual",
			description: "Website jointly administered by the Office of the Federal Register and Government Publishing Office.",
			href: "http://www.usgovernmentmanual.gov/"
		},
		{
			slug: "download-bill-texts-and-summaries-in-bulk-xml",
			title: "Download Bill Texts and Summaries in Bulk XML",
			description: "Download XML from GPO's bulk data repository.",
			href: "/bulkdata/BILLS"
		},
		{
			slug: "download-the-cfr-annual-in-bulk-xml",
			title: "Download the CFR (Annual) in Bulk XML",
			description: "Download XML from GPO's bulk data repository.",
			href: "/bulkdata/CFR"
		},
		{
			slug: "download-the-government-manual-in-bulk-xml",
			title: "Download the Government Manual in Bulk XML",
			description: "Download XML from GPO's bulk data repository.",
			href: "/bulkdata/GOVMAN"
		},
		{
			slug: "economic-indicators",
			title: "Economic Indicators",
			description: "Browse economic information on gross domestic product, income, employment, production, business activity, prices, money, credit, security markets, Federal finance, and international statistics.",
			href: "/app/collection/ECONI"
		},
		{
			slug: "economic-indicators-back-to-1948-in-fraser",
			title: "Economic Indicators Back to 1948 in FRASER",
			description: "The Federal Reserve Archival System for Economic Research (FRASER) is not an official version of Economic Indicators and GPO can not guarantee the authenticity or completeness of the data. FRASER is provided through a partnership between GPO and the Federal Reserve Bank of St. Louis.",
			href: "https://fraser.stlouisfed.org/title/?id=1"
		},
		{
			slug: "economic-report-of-the-president",
			title: "Economic Report of the President",
			description: "Overviews the nation's economic progress using text and extensive data appendices.",
			href: "/app/collection/ERP"
		},
		{
			slug: "economic-report-of-the-president-1947-forward-in-fraser",
			title: "Economic Report of the President, 1947 Forward, in FRASER",
			description: "The Federal Reserve Archival System for Economic Research (FRASER) is not an official version of Economic Indicators and GPO can not guarantee the authenticity or completeness of the data. FRASER is provided through a partnership between GPO and the Federal Reserve Bank of St. Louis.",
			href: "https://fraser.stlouisfed.org/title/45"
		},
		{
			slug: "electronic-code-of-federal-regulations-e-cfr",
			title: "Electronic Code of Federal Regulations (e-CFR)",
			description: "View the regularly updated, unofficial editorial compilation of CFR material and Federal Register amendments produced by the National Archives and Records Administration's Office of the Federal Register and the Government Publishing Office.",
			href: "https://www.ecfr.gov/"
		},
		{
			slug: "executive-order-13563-retrospective-review-documents",
			title: "Executive Order 13563 Retrospective Review Documents",
			description: `In accordance with Executive Order 13563, "Improving Regulation and Regulatory Review," Federal agencies and departments periodically review existing regulations that may be outmoded, ineffective, insufficient, or excessively burdensome and to modify, streamline, expand, or repeal them in accordance with what has been learned.`,
			href: "/app/search/%7B%22query%22%3A%22collection%3Afr%20and%20(summary%3A(executive%20order%2013563)%20or%20summary%3A(retrospective%20review%201356)%20or%20summary%3A(executive%20orders%201356))%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "executive-orders",
			title: "Executive Orders",
			description: "Search official documents through which the President of the United States manages the operations of the Federal Government.",
			href: "/app/search/%7B%22query%22%3A%22cpdcategory%3A%5C%22executive%20orders%5C%22%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "fdlp-webite",
			title: "FDLP Website",
			description: "Visit the FDLP website to access tools and learn more about the Federal Depository Library Program.",
			href: "http://www.fdlp.gov/"
		},
		{
			slug: "featured-house-publications",
			title: "Featured House Publications",
			description: "Browse Miscellaneous House publications.",
			href: "/collection/featured-house-publications"
		},
		{
			slug: "featured-senate-journals",
			title: "Featured Senate Publications",
			description: "Browse Miscellaneous Senate publications.",
			href: "/collection/featured-senate-publications"
		},
		{
			slug: "federal-depository-library-program-publications",
			title: "Federal Depository Library Program Publications",
			description: "Access additional publications related to the FDLP",
			href: "/collection/FDLP"
		},
		{
			slug: "federal-register",
			title: "Federal Register",
			description: "Published by the Office of the Federal Register, National Archives and Records Administration (NARA), the Federal Register is the official daily publication for rules, proposed rules, and notices of Federal agencies and organizations, as well as executive orders and other presidential documents.",
			href: "/app/collection/FR"
		},
		{
			slug: "federal-register-gov",
			title: "FederalRegister.gov",
			description: "View the unofficial, HTML (XML-based) edition of the daily Federal Register provided by the Office of the Federal Register, National Archives and Records Administration.",
			href: "https://www.federalregister.gov/"
		},
		{
			slug: "government-document-indexes",
			title: "Government Document Indexes",
			description: "The MoCat lists all of the publications produced by the U.S. Government Printing Office from 1895 to 2004.",
			href: "/collection/government-document-indexes"
		},
		{
			slug: "gpo-government-book-talk-blog-on-the-panama-canal",
			title: "GPO Government Book Talk Blog on the Panama Canal",
			description: "TBD",
			href: "http://govbooktalk.gpo.gov/tag/panama-canal-commission"
		},
		{
			slug: "gpo-website",
			title: "GPO Website",
			description: "Visit the U.S. Government Publishing Office (GPO) website to learn more about GPO and how to work with the agency.",
			href: "http://www.gpo.gov/"
		},
		{
			slug: "guide-to-law-online",
			title: "Guide to Law Online",
			description: "View the annotated guide to sources of information on government and law available online prepared by the Law Library of Congress Public Services Division.",
			href: "http://www.loc.gov/law/help/guide/federal.php"
		},
		{
			slug: "historical-editions-of-the-budget-of-the-us-government-on-fraser",
			title: "Historical Editions of the Budget of the U.S. Government on FRASER",
			description: "The Federal Reserve Archival System for Economic Research (FRASER) is not an official version of the Budget of the United States Government and GPO can not guarantee the authenticity or completeness of the data. FRASER is provided through a partnership between GPO and the Federal Reserve Bank of St. Louis. (Note: Dates of Appendix and Special Analysis volumes may differ.)",
			href: "https://fraser.stlouisfed.org/title/?id=54"
		},
		{
			slug: "history-of-bills",
			title: "History of Bills",
			description: "View legislative actions on bills that are reported in the Congressional Record.",
			href: "/app/collection/hob"
		},
		{
			slug: "history-of-gpos-federal-depository-library-program",
			title: "History of GPO’s Federal Depository Library Program",
			description: "View a video explaining the history of the Federal Depository Program.",
			href: "https://www.youtube.com/watch?v=vRFACkDd9d0"
		},
		{
			slug: "house-committee-on-appropriations-website",
			title: "House Committee on Appropriations Website",
			description: "Visit their website to learn more about House Appropriations.",
			href: "http://appropriations.house.gov/"
		},
		{
			slug: "house-journals",
			title: "House Journals",
			description: "Browse the Journal of the House of Representatives.",
			href: "/app/collection/hjournal"
		},
		{
			slug: "house-practice",
			title: "House Practice",
			description: "View the Guide to the Rules, Precedents, and Procedures of the House.",
			href: "/collection/house-practice"
		},
		{
			slug: "house-reports",
			title: "House Reports",
			description: "Search House reports of congressional committees concerning proposed legislation or containing findings on matters under investigation.",
			href: "/app/search/%7B%22query%22%3A%22doctype%3Ahrpt%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "house-rules-and-manual-in-bulk-xml",
			title: "House Rules and Manual in Bulk XML",
			description: "Download XML from GPO's bulk data repository.",
			href: "/bulkdata/HMAN"
		},
		{
			slug: "how-our-laws-are-made-pdf",
			title: "How Our Laws are Made (PDF)",
			description: "View House Document 110-49 that details how U.S. laws are made.",
			href: "/content/pkg/CDOC-110hdoc49/pdf/CDOC-110hdoc49.pdf"
		},
		{
			slug: "how-to-use-panama-canal-personnel-records-at-nara",
			title: "How to Use Panama Canal Personnel Records at NARA",
			description: "TBD",
			href: "https://rediscovering-black-history.blogs.archives.gov/2015/01/27/part-i-how-to-use-panama-canal-personnel-records-at-the-national-archives-my-grandfather-worked-on-the-panama-canal"
		},
		{
			slug: "index-or-federal-agencies-from-usa-gov",
			title: "Index of Federal Agencies From USA.gov",
			description: "View an A-Z index of Federal agencies, a service of the U.S. General Services Administration.",
			href: "https://www.usa.gov/federal-agencies/a"
		},
		{
			slug: "issues-of-the-federal-register-in-bulk-xml",
			title: "Issues of the Federal Register in Bulk XML",
			description: "Download XML from GPO's bulk data repository.",
			href: "/bulkdata/FR"
		},
		{
			slug: "list-of-cfr-sections-affected",
			title: "List of CFR Sections Affected",
			description: "The LSA includes proposed, new, and amended Federal regulations that have been published in the Federal Register since the most recent revision date of a CFR title.",
			href: "/app/collection/lsa"
		},
		{
			slug: "national-debate-topics-for-high-schools",
			title: "National Debate Topics for High Schools",
			description: "Each year the NFHS Speech, Debate & Theatre Association announces the national high school debate topic. According to 44 U.S.C. 1333, the Library of Congress is responsible for preparing compilations of pertinent excerpts, bibliographical references, and other appropriate materials relating to the annual topic. The compilation is then printed as a Senate document and disseminated by GPO.",
			href: "/app/search/%7B%22facets%22%3A%7B%7D%2C%22filterOrder%22%3A%5B%5D%2C%22facetToExpand%22%3A%22non_existing_facet%22%2C%22offset%22%3A0%2C%22pageSize%22%3A%2210%22%2C%22sortBy%22%3A%222%22%2C%22query%22%3A%22collection%3Acdoc%20and%20%5C%22national%20debate%20topic%5C%22%22%2C%22historical%22%3Afalse%7D"
		},
		{
			slug: "noaa-central-library",
			title: "NOAA Central Library",
			description: "View additional NOAA publications.",
			href: "https://www.lib.noaa.gov/"
		},
		{
			slug: "not-finding-a-new-public-law-search-for-the-final-enrolled-bill",
			title: "Not Finding a New Public Law? Search for the Final Enrolled Bill.",
			description: "Search the final official copy of the bill or joint resolution which both the House and the Senate have passed in identical form.",
			href: "/app/search/%7B%22query%22%3A%22billversion%3Aenr%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "obama-public-papers-of-the-presidents-in-bulk-xml",
			title: "Obama Public Papers of the Presidents in Bulk XML",
			description: "Download XML from GPO's bulk data repository.",
			href: "/bulkdata/PPP"
		},
		{
			slug: "official-office-of-management-and-budget-website",
			title: "Official Office of Management and Budget Website",
			description: "The OMB carries out budget development and execution, coordination and review of all significant Federal regulations, Executive Orders and Presidential Memoranda to agency heads and officials, review of all agency communications with Congress, and other activities.",
			href: "https://www.whitehouse.gov/omb/budget"
		},
		{
			slug: "official-photos",
			title: "Official Photos",
			description: "Search the official photographic portfolio.",
			href: "/app/search/%7B%22query%22%3A%22pppsection%3Aphoto%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "official-website-of-the-council-of-economic-advisers",
			title: "Official Website of the Council of Economic Advisers",
			description: "The CEA is charged with offering the President objective economic advice on the formulation of both domestic and international economic policy.",
			href: "https://www.whitehouse.gov/administration/eop/cea"
		},
		{
			slug: "official-website-of-the-house-committee-on-ways-and-means",
			title: "Official Website of the House Committee on Ways and Means",
			description: "Visit their official website to learn more about the House Committee on Ways and Means.",
			href: "http://waysandmeans.house.gov/"
		},
		{
			slug: "official-website-of-the-united-states-courts",
			title: "Official Website of the United States Courts",
			description: "The agency within the judicial branch that provides a broad range of legislative, legal, financial, technology, management, administrative, and program support services to federal courts.",
			href: "http://www.uscourts.gov/"
		},
		{
			slug: "online-guide-to-house-and-senate-members",
			title: "Online Guide to House and Senate Members",
			description: "This guide serves as a single point of access for Member information compiled from several different official sources.",
			href: "http://www.memberguide.gpo.gov/"
		},
		{
			slug: "other-publications-from-the-house-committee-on-appropriations",
			title: "Other Publications From the House Committee on Appropriations",
			description: "View other publications from this committee.",
			href: "/committee/house-appropriations"
		},
		{
			slug: "other-publications-from-the-joint-committee-on-printing",
			title: "Other Publications From the Joint Committee on Printing",
			description: "TBD",
			href: "/committee/joint-printing"
		},
		{
			slug: "other-publications-from-the-senate-committee-on-appropriations",
			title: "Other Publications From the Senate Committee on Appropriations",
			description: "View other publications from this committee.",
			href: "/committee/senate-appropriations"
		},
		{
			slug: "panama-canal-collection-at-the-university-of-florida",
			title: "Panama Canal Collection at the University of Florida",
			description: "TBD",
			href: "http://guides.uflib.ufl.edu/c.php?g=147621&p=969209"
		},
		{
			slug: "parallel-table-of-authorities-and-rules",
			title: "Parallel Table of Authorities and Rules",
			description: "View the Table of Authorities and Rules for the Code of Federal Regulations and the United States Code.",
			href: "/help/cfr#parallel-table"
		},
		{
			slug: "precedents-of-the-house",
			title: "Precedents of the House",
			description: "View Hinds’ Precedents, Cannon’s Precedents and Deschlers’s Precedents.",
			href: "/collection/precedents-of-the-house"
		},
		{
			slug: "",
			title: "Privacy Act Issuances in Bulk XML",
			description: "Download XML from GPO's bulk data repository.",
			href: "/bulkdata/PAI"
		},
		{
			slug: "",
			title: "Proclamations",
			description: "Search proclamations made by the President of the United States.",
			href: "/app/search/%7B%22query%22%3A%22cpdcategory%3Aproclamations%20%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "",
			title: "Public Access to Court Electronic Records (PACER) ",
			description: "An electronic service for obtaining case and docket information online from federal appellate, district, and bankruptcy courts, and the PACER Case Locator. Requires registration for access.",
			href: "https://www.pacer.gov/"
		},
		{
			slug: "purchase-an-electronic-subscription",
			title: "Purchase an Electronic Subscription",
			description: "Visit GPO's Online Bookstore to purchase electronic subscriptions.",
			href: "https://bookstore.gpo.gov/Electronic-Data-Products#Daily-Bills"
		},
		{
			slug: "purchase-individual-issues-or-a-subscription",
			title: "Purchase Individual Issues or a Subscription ",
			description: "Visit GPO's Online Bookstore to purchase individual issues or subscriptions.",
			href: "https://bookstore.gpo.gov/catalog/laws-regulations/congressional-record-daily"
		},
		{
			slug: "purchase-printed-copies",
			title: "Purchase Printed Copies",
			description: "Visit GPO's Online Bookstore to purchase physical copies of this publication.",
			href: "https://bookstore.gpo.gov/catalog/budget-economy"
		},
		{
			slug: "recent-presidential-documents",
			title: "Recent Presidential Documents",
			description: "View most recent Presidential Materials from the Federal Register and Compilation of Presidential Documents.",
			href: "/app/latestpresdocs/7"
		},
		{
			slug: "reginfo-gov",
			title: "Reginfo.gov",
			description: "Published by the OMB, Reginfo.gov provides reliable, transparent information about regulations under development.",
			href: "http://www.reginfo.gov/public/"
		},
		{
			slug: "regulations-gov",
			title: "Regulations.gov",
			description: "Find, review, and submit comments on Federal rules that are open for comment and published in the Federal Register. Managed by the U.S. General Services Administration eRulemaking Program Management Office.",
			href: "http://www.regulations.gov/"
		},
		{
			slug: "riddicks-senate-procedure",
			title: "Riddick's Senate Procedure",
			description: "View the Senate document that contains contemporary precedents and practices of the Senate.",
			href: "/collection/riddicks-senate-procedure"
		},
		{
			slug: "search-house-documents",
			title: "Search House Documents",
			description: "Search various materials ordered printed by the House of Representatives.",
			href: "/app/search/%7B%22query%22%3A%22collection%3Acdoc%20and%20chamber%3Ahouse%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "search-senate-documents",
			title: "Search Senate Documents",
			description: "Search various materials ordered printed by the Senate.",
			href: "/app/search/%7B%22query%22%3A%22collection%3Acdoc%20and%20chamber%3Asenate%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "search-senate-treaty-documents",
			title: "Search Senate Treaty Documents",
			description: "Search the text of a Treaty as it is submitted to the U.S. Senate for ratification by the President of the United States.",
			href: "/app/search/%7B%22query%22%3A%22collection%3Acdoc%20and%20%5C%22treaty%20doc%5C%22%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "senate-committee-on-appropriations-website",
			title: "Senate Committee on Appropriations Website",
			description: "Visit their website to learn more about Senate Appropriations.",
			href: "http://www.appropriations.senate.gov/"
		},
		{
			slug: "senate-executive-reports",
			title: "Senate Executive Reports",
			description: "Search reports of the Committee on Foreign Relations relating to Treaties between the United States and foreign nations, which have been submitted to the U.S. Senate for ratification, or are reports of various Senate Committees regarding nomination of individuals.",
			href: "/app/search/%7B%22query%22%3A%22doctype%3Aerpt%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "senate-journals",
			title: "Senate Journals",
			description: "Browse the Journal of the Senate.",
			href: "/app/collection/sjournal"
		},
		{
			slug: "senate-rules-and-procedures-standing-rules-of-the-senate-and-senate-manual",
			title: "Senate Rules and Procedures (Standing Rules of the Senate and Senate Manual)",
			description: "View the rules and procedures that governs the legislative process on the Senate floor.",
			href: "http://www.senate.gov/reference/reference_index_subjects/Rules_and_Procedure_vrd.htm"
		},
		{
			slug: "statutes-at-large",
			title: "Statutes at Large",
			description: "View the permanent collection of all laws and resolutions enacted during each session of Congress.",
			href: "/app/collection/STATUTE"
		},
		{
			slug: "supreme-court-nomination-hearings",
			title: "Supreme Court Nomination Hearings",
			description: "View transcripts from the Senate Committee on the Judiciary: Supreme Court Nomination Hearings (1971-forward).",
			href: "/collection/supreme-court-nomination-hearings"
		},
		{
			slug: "table-of-contents-electronic-mailing-list",
			title: "Table of Contents Electronic Mailing List ",
			description: "Sign up to receive the daily Table of Contents by email.",
			href: "https://public.govdelivery.com/accounts/USGPOOFR/subscriber/new"
		},
		{
			slug: "treasury-website",
			title: "Treasury Website",
			description: "Visit their website to learn more about the Department of the Treasury.",
			href: "http://www.treasury.gov/"
		},
		{
			slug: "tributes-and-memorial-tributes",
			title: "Tributes and Memorial Tributes",
			description: "Search tributes from the 103rd Congress forward in the Congressional Documents, Congressional Hearings, and Congressional Committee Prints collections.",
			href: "/app/search/%7B%22query%22%3A%22collection%3A(cdoc%20or%20chrg%20or%20cprt)%20title%3Atributes%22%2C%22offset%22%3A0%7D"
		},
		{
			slug: "us-code-classification-tables",
			title: "U.S. Code Classification Tables",
			description: "View tables that show where recently enacted laws will appear in the United States Code and which sections of the Code have been amended by those laws. Tables are prepared by the Office of the Law Revision Counsel of the U.S. House of Representatives.",
			href: "http://uscode.house.gov/classification/tables.shtml"
		},
		{
			slug: "unified-agenda",
			title: "Unified Agenda",
			description: "View Unified Agenda documents that describe regulatory actions agencies are developing or have recently completed.",
			href: "/collection/unified-agenda"
		},
		{
			slug: "united-states-government-manual",
			title: "United States Government Manual",
			description: "TBD",
			href: "/app/collection/GOVMAN"
		},
		{
			slug: "uscode-house-gov",
			title: "USCode.House.gov",
			description: "Search and browse the U.S. Code, a service of the Office of the Law Revision Counsel of the U.S. House of Representatives.",
			href: "http://uscode.house.gov/"
		},
		{
			slug: "view-state-of-the-union-addresses",
			title: "View State of the Union Addresses",
			description: "Search the President's Addresses Before a Joint Session of Congress on the State of the Union.",
			href: "/app/search/%7B%22query%22%3A%22collection%3Acpd%20title%3A%5C%22Address%20Before%20a%20Joint%20Session%20of%20the%20Congress%20on%20the%20State%20of%20the%20Union%5C%22%22%2C%22offset%22%3A0%7D%0A"
		},
		{
			slug: "website-of-the-parliamentatian-of-the-house",
			title: "Website of the Parliamentarian of the House",
			description: "Visit their official website to learn more about the Parliamentarian of the House.",
			href: "http://www.house.gov/content/learn/officers_and_organizations/parliamentarian.php"
		}
	]
}