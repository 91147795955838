import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { updateLayout, dateBrowseTabSelect } from "./features/layout/layout-slice";
import { Nav, NavItem, NavLink, TabContainer, TabContent, TabPane, Form } from 'react-bootstrap';
import { PageHeader, SiteFooter, DateSelect, getQPLFormat, getToday } from "@govinfo/react-components";
import { addDays, format, parse } from "date-fns";


function AppBrowseDate({ slug }: { slug: string }) {
	const mainContentLinkRef = useRef();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const layout = useAppSelector((state) => state.layout);
	let { tab } = useParams();

	const [dateVal, setDateVal] = useState({ selectedValue: 'AD' });
	const [fromDateObj, setFromDateObj] = useState({ input: '', picker: new Date() });
	const [toDateObj, setToDateObj] = useState({ input: '', picker: new Date() });

	useEffect(() => {
		dispatch(updateLayout(slug));
	}, []);

	const onDateSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setDateVal({ selectedValue: e.target.value });
		// setToDateObj({
		// 	input: "",
		// 	picker: new Date()
		// });
		// setFromDateObj({
		// 	input: "",
		// 	picker: new Date()
		// });
	}

	// TODO: This needs to be refactored so any of the App files can use the same function
	const widgetCallback = (urlFrag: string) => {
		navigate('/app/search/' + encodeURIComponent(urlFrag.replaceAll('/', '¼')));
	}

	const timeframes = [
		{
			duration: 24,
			unit: "hour"
		},
		{
			duration: 7,
			unit: "day"
		},
		{
			duration: 30,
			unit: "day"
		},
		{
			duration: 180,
			unit: "day"
		},
		{
			duration: 365,
			unit: "day"
		},
	];
	const [duration, setDuration] = useState<number>()
	const handleDurationSearch = (e) => {
		const q = `publishdate:range(${format(addDays(new Date(), -1 * (duration==24?1:duration) ), 'yyyy-MM-dd')},${format(new Date(), 'yyyy-MM-dd')})`
		const searchString = { query: `${q}`, browseByDate: true, sortBy: 2 }
		navigate('/app/search/' + encodeURIComponent(JSON.stringify(searchString)));
	}
	const handleDateRangeSearch = (e) => {
		
		let q =(dateVal.selectedValue == 'DI')
		? `publishdate:${fromDateObj.input ? getQPLFormat(fromDateObj.input, null,dateVal.selectedValue) : ''}`
		: `publishdate:range(${(dateVal.selectedValue == 'DBT' || dateVal.selectedValue == 'DA')
			? getQPLFormat(fromDateObj.input, false,dateVal.selectedValue)
			: ''},${(dateVal.selectedValue == 'DB' || dateVal.selectedValue == 'DBT')
				? (toDateObj.input ? getQPLFormat(toDateObj.input, true,dateVal.selectedValue) : format(new Date(),'yyyy-MM-dd'))
				: ((dateVal.selectedValue == 'AD') ? format(new Date(),'yyyy-MM-dd') : '')})`;
		const searchString = { query: `${q}`, browseByDate: true, sortBy: 2 }
		navigate('/app/search/' + encodeURIComponent(JSON.stringify(searchString)))
	}

	return (
		<>
			<PageHeader layout={layout} widgetCallback={widgetCallback} mainContentLinkRef={mainContentLinkRef} hasSearchWidget={true} widgetDefaultOpen={false} />

			<main className="browse-date">
				<p className="description">{layout.description}</p>

				<TabContainer id="date-browse" defaultActiveKey={layout.dateBrowseTab}>
					<Nav
						variant="tab"
						as="ul"
						className="browse-nav-tabs"
						activeKey={layout.dateBrowseTab}
						onSelect={(e)=> {dispatch(dateBrowseTabSelect(e))}}
					>
						<NavItem as="li">
							<NavLink eventKey="timeframe" className="browse-nav-tab">
								Select a Timeframe
							</NavLink>
						</NavItem>
						<NavItem as="li">
							<NavLink eventKey="range" className="browse-nav-tab" >
								Select a Date Range
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent as="section" className="browse-date-content">
						<TabPane id="timeframe" eventKey="timeframe" as="aside">
							<Form>
								<aside className="timeframes-buttons">
									{
										timeframes.map((t) => {
											return (
												<Form.Check onChange={e => console.log(e)}
													id={`timeframe-${t.duration}-${t.unit}s`}
													className="btn-timeframe"
													key={`timeframe-${t.duration}-${t.unit}s`}
												>
													<Form.Check.Input
														type="radio"
														className="btn-timeframe-radio"
														name="timeFrame-select"
														onChange={e => setDuration(t.duration)}
													/>
													<Form.Check.Label className="btn-timeframe-label">{`Past ${t.duration} ${t.unit}s`} </Form.Check.Label>
												</Form.Check>
											)
										})
									}
								</aside>
								<p>
									<button type="button" className="btn-ui" onClick={handleDurationSearch}>
										Search
									</button>
								</p>
							</Form>
						</TabPane>
						<TabPane id="range" eventKey="range" as="aside">
							<DateSelect
								selectedRangeOption={dateVal.selectedValue}
								onChange={onDateSelection}
								fromDate={fromDateObj}
								toDate={toDateObj}
								setFromDate={setFromDateObj}
								setToDate={setToDateObj}
							/>
							<p>
								<button type="button" className="btn-ui" onClick={handleDateRangeSearch}>
									Search
								</button>
							</p>
						</TabPane>
					</TabContent>
				</TabContainer>
			</main>
			<SiteFooter mainContentLinkRef={mainContentLinkRef} />
		</>
	)
}

export default AppBrowseDate;