import React from 'react';
import { useAppSelector } from '../../app/hooks';
import Markdown from 'react-markdown';
import ContentSection from './ContentSection';

function RelatedResources() {
	const layout = useAppSelector((state) => state.layout);

	return (
		<section id="related-resources">
			<ContentSection collapsable={true} defaultOpen={false} helpRibbon={false} helpUrl="" sectionTitle="Related Resources">
				<ul className="related-resources-list">
					{
						layout.relatedResources.map((item, index) => {
							return (
								<li className="related-resources-list-item" key={`item-${index}`}>
									<a className="resource-item-link" href={item.href} target={(item.href.includes("http") && item.href.includes("://")) ? '_blank' : "_self"}>
										<h5 className="resource-title">{item.title}</h5>
										<Markdown>{item.description}</Markdown>
									</a>
								</li>
							);
						})
					}
				</ul>
			</ContentSection>
		</section>
	)
}

export default RelatedResources;