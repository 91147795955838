import React from 'react';

function LinkHelp({isRibbon, helpUrl}: {isRibbon: boolean, helpUrl: string}) {
	return (
		<p className={`${isRibbon ? "btn-help-ribbon" : "link-help"}`}>
			<a href={helpUrl} className={`${isRibbon ? "btn-help" : ""}`} target="_blank" rel="noopener noreferrer" title="Help">
				Help
			</a>
		</p>
	);
}

export default LinkHelp;