import React from 'react'

function ErrorNotFound() {

	return (
		<article className="message error">
			<h2>An error occurred. The content you requested cannot be found.</h2>
			<p>Please report this error to <a href="https://www.gpo.gov/error-pages/askgpo" target="_blank" rel="noopener noreferrer">askGPO</a>.</p>
			<p>Provide the following information to help us resolve this problem: the URL of the page or the content you were trying to access, the steps you followed to produce the error, specific search or browse terms, and/or a screenshot of the page where the error occurred.</p>
			<p>Thank you for your patience.</p>
			<p>Return to GovInfo's <a href="https://www.govinfo.gov/">Home page</a></p>
			<p>View <a href="/help/searching">Search Tips</a></p>
		</article>
	);
}

export default ErrorNotFound;
