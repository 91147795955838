// This file is set up similar to related resources and site nav.
// The const categoryItems is an array of objects, each with 3 properties: label, slug, and items
// Categories are listed in order as they are to appear on the page.
// The label property (string) is the label for the Category Browse tabs
// The slug property is a kabob-case string used for routing.
// The items property (array) is a list/array of slugs that reference items in collections below.
// This file gets imported by ./AppBrowseCategory.tsx and 
// is used to create the tabbed navigation for Category Browse.
// If editing items, edit the items in collections, below.
// If adding items, add the item in collections, below, alphetize by slug, and add the slug to the appropriate category.
// If editing a category's items, add/remove collection items list for the category.
// If editing a category's name, edit the category label name.

export const categoryItems = [
	{
		label: "Bills and Statutes",
		slug: "bills-statutes",
		items: [
			"congressional-bills",
			"public-and-private-laws",
			"statute-compilations",
			"statutes-at-large",
			"us-code"
		]
	},
	{
		label: "Budget and Presidential Materials",
		slug: "budget-presidential",
		items: [
			"budget-of-the-us-government",
			"compilation-of-presidential-documents",
			"economic-report-of-the-president",
			"public-papers-of-the-presidents",
		]
	},
	{
		label: "Congressional Committee Materials",
		slug: "committee-materials",
		items: [
			"appropriations-budget-estimates-etc",
			"green-book",
			"challenger-space-shuttle-accident",
			"congressional-committee-prints",
			"congressional-documents",
			"congressional-hearings",
			"congressional-reports",
			"congressional-serial-set",
			"congressional-serial-set-topic",
			"congressionally-mandated-reports",
			"congressionally-mandated-reports-topic",
			"constitution-annotated",
			"economic-indicators",
			"featured-commission-publications",
			"featured-house-publications",
			"featured-joint-publications",
			"featured-senate-publications",
			"independent-counsel-investigations",
			"legislative-calendars",
			"supreme-court-nomination-hearings",
			"us-government-policy-plum-book",
		]
	},
	{
		label: "Congressional Rules and Procedures",
		slug: "congressional-rules",
		items: [
			"congressional-documents",
			"house-practice",
			"house-rules-and-manual",
			"precedents-of-the-house",
			"riddicks-senate-procedure",
			"senate-manual",
		]
	},
	{
		label: "Directories of Organizations and Officials",
		slug: "directories-of-orgs",
		items: [
			"congressional-directory",
			"congressional-pictorial-directory",
			"us-government-manual",
		]
	},
	{
		label: "Executive Agency Publications",
		slug: "exec-agency-pubs",
		items: [
			"army-university-press-publications",
			"boem-publications",
			"coastal-zone-information-center",
			"congressionally-mandated-reports",
			"congressionally-mandated-reports-topic",
			"department-interior-inspector-general-reports",
			"education-reports-from-eric",
			"featured-board-publications",
			"featured-commission-publications",
			"featured-executive-branch-publications",
			"nsa-the-next-wave",
			"nist-publications",
			"panama-canal-publications",
			"president-jfk-assassination-records-collection",
			"railroad-retirement-board-publications",
			"watergate-special-procecution-force-records",
			"treasury-department-publications",
		]
	},
	{
		label: "Independent Establishments and Government Corporations",
		slug: "ind-est-gov-corp",
		items: [
			"congressionally-mandated-reports",
			"congressionally-mandated-reports-topic",
			"railroad-retirement-board-publications",
		]
	},
	{
		label: "Judicial Publications",
		slug: "judicial-pubs",
		items: [
			"congressionally-mandated-reports",
			"congressionally-mandated-reports-topic",
			"featured-judicial-publications",
			"us-courts-opinions",
			"independent-counsel-investigations",
			"us-reports"
		]
	},
	{
		label: "Legislative Agency Publications",
		slug: "legislative-pubs",
		items: [
			"congressionally-mandated-reports",
			"congressionally-mandated-reports-topic",
			"featured-commission-publications",
			"featured-legislative-branch-publications",
			"fdlp",
			"gao-reports",
			"gpo-publications",
		]
	},
	{
		label: "Proceedings of Congress and General Congressional Publications",
		slug: "proceedings-general",
		items: [
			"congressional-calendars",
			"congressional-record",
			"congressional-record-bound",
			"congressional-record-index",
			"congressional-serial-set",
			"congressional-serial-set-topic",
			"featured-house-publications",
			"featured-senate-publications",
			"history-of-bills",
			"journal-house",
			"journal-senate",
			"precedents-of-the-house",
		]
	},
	{
		label: "Regulatory Information",
		slug: "regulatory-info",
		items: [
			"cfr-index",
			"cfr-parts-affected",
			"code-of-federal-regulations",
			"federal-register",
			"federal-register-index",
			"list-of-cfr-sections-affected",
			"privacy-act-issuances",
			"unified-agenda",
		]
	},

];


// This exported const collections is a list/array of items used for Category Browse.
// categoryBrowseNav object items lists collection items by slug.
// Each collection list item has 4 properties: label, slug, code, url.
// The label property (string) is the label that appears in the browse tab.
// The slug property is a kabab-case string identifier, used by categoryBrowseNav above.
// Collections are sorted alphabetically by slug.
// The code property is carried over from the Backbone app, in case it is needed (I suspect it is not).
// TODO: determine if code property is needed or used for Category Browse. Remove if not needed.
// The url property holds the path for the collection item.
// Some items are listed in more than 1 category.
// This structure is designed to reduce repetion in code (keepin' it DRY) and
// to make it easier to update/edit as needed with less error potential.
// If adding collection items, follow the pattern established. Keep slugs human parsable.
// (no prizes and only bad karma for unnecessarily obtuse abbreviations).

export const collectionItems = [
	{
			label: "Appropriations, Budget Estimates, etc",
			slug: "appropriations-budget-estimates-etc",
			code: "other",
			url: "/collection/appropriations-and-budget-estimates"
	},
	{
			label: "Army University Press Publications",
			slug: "army-university-press-publications",
			code: "other",
			url: "/collection/army-university-press"
	},
	{
		label: "Background Material and Data on the Programs within the Jurisdiction of the Committee on Ways and Means (Green Book)",
		slug: "green-book",
		code: "other",
		url: "/collection/green-book"
	},
	{
			label: "Bureau of Ocean Energy Management (BOEM) Publications",
			slug: "boem-publications",
			code: "other",
			url: "/collection/boem"
	},
	{
			label: "Budget of the U.S. Government",
			slug: "budget-of-the-us-government",
			code: "BUDGET",
			url: "/app/collection/budget"
	},
	{
			label: "CFR Index and Finding Aids",
			slug: "cfr-index",
			code: "GPO",
			url: "/collection/cfr-index"
	},
	{
			label: "CFR Parts Affected from the Federal Register",
			slug: "cfr-parts-affected",
			code: "CFRPARTS",
			url: "/app/cfrparts/month"
	},
	{
			label: "Challenger Space Shuttle Accident Selected Congressional Hearings and Reports",
			slug: "challenger-space-shuttle-accident",
			code: "other",
			url: "/collection/challenger-space-shuttle-accident"
	},
	{
			label: "Coastal Zone Information Center",
			slug: "coastal-zone-information-center",
			code: "CZIC",
			url: "/app/collection/czic"
	},
	{
			label: "Code of Federal Regulations",
			slug: "code-of-federal-regulations",
			code: "CFR",
			url: "/app/collection/cfr"
	},
	{
			label: "Compilation of Presidential Documents",
			slug: "compilation-of-presidential-documents",
			code: "CPD",
			url: "/app/collection/cpd"
	},
	{
			label: "Congressional Bills",
			slug: "congressional-bills",
			code: "BILLS",
			url: "/app/collection/bills"
	},
	{
			label: "Congressional Calendars",
			slug: "congressional-calendars",
			code: "CCAL",
			url: "/app/collection/ccal"
	},
	{
			label: "Congressional Committee Prints",
			slug: "congressional-committee-prints",
			code: "CPRT",
			url: "/app/collection/cprt"
	},
	{
			label: "Congressional Directory",
			slug: "congressional-directory",
			code: "CDIR",
			url: "/app/collection/cdir"
	},
	{
			label: "Congressional Documents",
			slug: "congressional-documents",
			code: "CDOC",
			url: "/app/collection/cdoc"
	},
	{
			label: "Congressional Hearings",
			slug: "congressional-hearings",
			code: "CHRG",
			url: "/app/collection/chrg"
	},
	{
			label: "Congressional Pictorial Directory",
			slug: "congressional-pictorial-directory",
			code: "other",
			url: "/collection/congressional-pictorial-directory"
	},
	{
			label: "Congressional Record",
			slug: "congressional-record",
			code: "CREC",
			url: "/app/collection/crec"
	},
	{
			label: "Congressional Record (Bound Edition)",
			slug: "congressional-record-bound",
			code: "CRECB",
			url: "/app/collection/crecb"
	},
	{
			label: "Congressional Record Index",
			slug: "congressional-record-index",
			code: "CRI",
			url: "/app/collection/cri"
	},
	{
			label: "Congressional Reports",
			slug: "congressional-reports",
			code: "CRPT",
			url: "/app/collection/crpt"
	},
	{
			label: "Congressional Serial Set",
			slug: "congressional-serial-set",
			code: "SERIALSET",
			url: "/app/collection/serialset"
	},
	{
			label: "Congressional Serial Set - Browse by Topic",
			slug: "congressional-serial-set-topic",
			code: "SERIALSET",
			url: "/app/browse/topic/serialset"
	},
	{
			label: "Congressionally Mandated Reports",
			slug: "congressionally-mandated-reports",
			code: "CMR",
			url: "/app/collection/cmr"
	},
	{
			label: "Congressionally Mandated Reports - Browse by Topic",
			slug: "congressionally-mandated-reports-topic",
			code: "CMR",
			url: "/app/browse/topic/cmr"
	},
	{
			label: "Constitution of the United States of America: Analysis and Interpretation",
			slug: "constitution-annotated",
			code: "other",
			url: "/collection/constitution-annotated"
	},
	{
			label: "Department of Interior Inspector General Reports",
			slug: "department-interior-inspector-general-reports",
			code: "other",
			url: "/collection/interior-inspector-general-reports"
	},
	{
			label: "Economic Indicators",
			slug: "economic-indicators",
			code: "ECONI",
			url: "/app/collection/econi"
	},
	{
			label: "Economic Report of the President",
			slug: "economic-report-of-the-president",
			code: "ERP",
			url: "/app/collection/erp"
	},
	{
			label: "Education Reports from ERIC",
			slug: "education-reports-from-eric",
			code: "ERIC",
			url: "/app/collection/eric"
	},
	{
			label: "Featured Board Publications",
			slug: "featured-board-publications",
			code: "other",
			url: "/collection/featured-board-publications"
	},
	{
			label: "Featured Commission Publications",
			slug: "featured-commission-publications",
			code: "other",
			url: "/collection/featured-commission-publications"
	},
	{
			label: "Featured Executive Branch Publications",
			slug: "featured-executive-branch-publications",
			code: "other",
			url: "/collection/featured-executive-branch-publications"
	},
	{
			label: "Featured House Publications",
			slug: "featured-house-publications",
			code: "other",
			url: "/collection/featured-house-publications"
	},
	{
			label: "Featured Joint Publications",
			slug: "featured-joint-publications",
			code: "other",
			url: "/collection/featured-joint-publications"
	},
	{
			label: "Featured Judicial Publications",
			slug: "featured-judicial-publications",
			code: "other",
			url: "/collection/featured-judicial-publications"
	},
	{
			label: "Featured Legislative Branch Publications",
			slug: "featured-legislative-branch-publications",
			code: "other",
			url: "/collection/featured-legislative-branch-publications"
	},
	{
			label: "Featured Senate Publications",
			slug: "featured-senate-publications",
			code: "other",
			url: "/collection/featured-senate-publications"
	},
	{
		label: "Federal Depository Library Program Publications",
		slug: "fdlp",
		code: "FDLP",
		url: "/collection/FDLP"
	},
	{
			label: "Federal Register",
			slug: "federal-register",
			code: "FR",
			url: "/app/collection/fr"
	},
	{
			label: "Federal Register Index",
			slug: "federal-register-index",
			code: "GPO",
			url: "/collection/fr-index"
	},
	{
			label: "GAO Reports and Comptroller General Decisions",
			slug: "gao-reports",
			code: "GAOREPORTS",
			url: "/app/collection/gaoreports"
	},
	{
			label: "U.S. Government Publishing Office Publications",
			slug: "gpo-publications",
			code: "other",
			url: "/collection/gpo"
	},
	{
			label: "History of Bills",
			slug: "history-of-bills",
			code: "HOB",
			url: "/app/collection/hob"
	},
	{
			label: "House Practice",
			slug: "house-practice",
			code: "other",
			url: "/collection/house-practice"
	},
	{
			label: "House Rules and Manual",
			slug: "house-rules-and-manual",
			code: "HMAN",
			url: "/app/collection/hman"
	},
	{
			label: "Independent Counsel Investigations",
			slug: "independent-counsel-investigations",
			code: "other",
			url: "/collection/independent-counsel-investigations"
	},
	{
		label: "United States Courts Opinions",
		slug: "us-courts-opinions",
		code: "USCOURTS",
		url: "/app/collection/uscourts"
},
{
			label: "Journal of the House of Representatives",
			slug: "journal-house",
			code: "HJOURNAL",
			url: "/app/collection/hjournal"
	},
	{
			label: "Journal of the Senate",
			slug: "journal-senate",
			code: "SJOURNAL",
			url: "/app/collection/sjournal"
	},
	{
			label: "Legislative Calendars",
			slug: "legislative-calendars",
			code: "other",
			url: "/collection/legislative-calendars"
	},
	{
			label: "List of CFR Sections Affected",
			slug: "list-of-cfr-sections-affected",
			code: "LSA",
			url: "/app/collection/lsa"
	},
	{
			label: "National Security Agency’s, The Next Wave",
			slug: "nsa-the-next-wave",
			code: "other",
			url: "/collection/tnw"
	},
	{
			label: "NIST Publications",
			slug: "nist-publications",
			code: "GOVPUB",
			url: "/collection/nist"
	},
	{
			label: "Panama Canal Related Publications",
			slug: "panama-canal-publications",
			code: "other",
			url: "/collection/panama-canal"
	},
	{
			label: "Precedents of the U.S. House of Representatives",
			slug: "precedents-of-the-house",
			code: "other",
			url: "/collection/precedents-of-the-house"
	},
	{
		label: "President John F. Kennedy Assassination Records Collection",
		slug: "president-jfk-assassination-records-collection",
		code: "other",
		url: "/collection/jfk-assassination-records-collection"
	},
	{
			label: "Privacy Act Issuances",
			slug: "privacy-act-issuances",
			code: "PAI",
			url: "/app/collection/pai"
	},
	{
			label: "Public and Private Laws",
			slug: "public-and-private-laws",
			code: "PLAW",
			url: "/app/collection/plaw"
	},
	{
			label: "Public Papers of the Presidents",
			slug: "public-papers-of-the-presidents",
			code: "PPP",
			url: "/app/collection/ppp"
	},
	{
			label: "Railroad Retirement Board",
			slug: "railroad-retirement-board-publications",
			code: "other",
			url: "/collection/railroad-retirement-board-publications"
	},
	{
			label: "Records of the Watergate Special Prosecution Force, 1971 - 1977",
			slug: "watergate-special-procecution-force-records",
			code: "other",
			url: "/collection/watergate-special-prosecution-force-records"
	},
	{
			label: "Riddick's Senate Procedure",
			slug: "riddicks-senate-procedure",
			code: "other",
			url: "/collection/riddicks-senate-procedure"
	},
	{
			label: "Senate Manual",
			slug: "senate-manual",
			code: "SMAN",
			url: "/app/collection/sman"
	},
	{
			label: "Statute Compilations",
			slug: "statute-compilations",
			code: "COMPS",
			url: "/app/collection/comps"
	},
	{
			label: "Statutes At Large",
			slug: "statutes-at-large",
			code: "STATUTE",
			url: "/app/collection/statute"
	},
	{
			label: "Supreme Court Nomination Hearings",
			slug: "supreme-court-nomination-hearings",
			code: "other",
			url: "/collection/supreme-court-nomination-hearings"
	},
	{
			label: "Treasury Department Publications",
			slug: "treasury-department-publications",
			code: "GOVPUB",
			url: "/collection/treasury-department"
	},
	{
			label: "Unified Agenda",
			slug: "unified-agenda",
			code: "UA",
			url: "/collection/unified-agenda"
	},
	{
			label: "U.S. Code",
			slug: "us-code",
			code: "USCODE",
			url: "/app/collection/uscode"
	},
	,
	{
			label: "United States Reports",
			slug: "us-reports",
			code: "USREPORTS",
			url: "/app/collection/usreports"
	},
	{
			label: "The United States Government Manual",
			slug: "us-government-manual",
			code: "GOVMAN",
			url: "/app/collection/govman"
	},
	{
			label: "United States Government Policy and Supporting Positions (Plum Book)",
			slug: "us-government-policy-plum-book",
			code: "other",
			url: "/collection/plum-book"
	},
];


// const categoryBrowseNav = () => {
// 	const navItemsList = [];
// 	categoryItems.forEach((cat) => {
// 		const navItem = {
// 			label: cat.label,
// 			slug: cat.slug,
// 			items: cat.items.map((item) => {
// 				return collectionItems.find((collection) => collection.slug === item);
// 			})
// 		}
// 		navItemsList.push(navItem);
// 	});
// 	return { categories: navItemsList };
// }

// export default categoryBrowseNav;
