import React from "react";

function SearchSummaryHeader({searchQuery, clearQueryUrl}: {searchQuery: string, clearQueryUrl: string}) {

	return (
		<header className="search-summary-header">
			<h1 className="summary-title">You Searched For:</h1>
			<p className="facet-field">
				<a className="facet-field-value" href={clearQueryUrl}>
					{searchQuery}
				</a>
			</p>
		</header>
	)
}

export default SearchSummaryHeader;