import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import pageDetails from '../../components/data/pageDetails';
import relatedResources from '../../components/data/relatedResources';
import axios from 'axios'

export type OverlayType = {
	type: "tooltip" | "popover",
	title: string,
	text: string
}

export type ThumbnailType = {
	src: string,
	alt: string,
	overlay?: OverlayType
}

export type RelatedResource = {
	title: string,
	description: string,
	href: string
}

export type LayoutState = {
	template: string,
	title: string,
	slug: string,
	hasTheInHeading?: boolean,
	help?: string,
	rss?: string,
	// helpButtonLocation?: "masthead" | "page-description",
	helpButtonLocation?: string,
	description?: string,
	thumbnail?: ThumbnailType,
	relatedResources?: Array<RelatedResource>
	dateBrowseTab?: string,
	apiInProgress?: boolean,
	pageSizes?: number[],
    paginationEnabled: boolean
}

export const defaultPageSizes = [20, 50, 100];
export const pageSizeOptions = {
    bills: [20, 50, 100],
    crpt: [100, 200, 500],
    plaw: [20, 50, 100],
    serialset: [100, 200, 500],
    uscourts: [100, 200, 500],
		usreports: [100, 200, 500]
};
export const collectionsWithoutTheInHeading = ["crpt", "ccal", "chrg", "cdoc", "cprt", "uscourts"];

const initialState = {
	template: "",
	title: "",
	slug: "",
	hasTheInHeading: false,
	help: "",
	rss: "",
	helpButtonLocation: "page-description",
	description: "",
	thumbnail: null,
	relatedResources: [],
	dateBrowseTab: "timeframe",
	apiInProgress: false,
	pageSizes: defaultPageSizes,
    paginationEnabled: false
}

function updateHTMLTitle(title: string) {
	document.title = title;
	return;
}

function updateBodyClassName(className) {
	document.body.className = ""; // clear the classes off body
	document.body.classList.add(className); // add the relevant one

	return;
}

export const getCollectionData = createAsyncThunk('ui-fragment/getcollectiondata', (collection: String) => {
	return axios.get('/wssearch/ui-fragment/getCollectionData/' + collection)
		.then(({data}) => data);
});

const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		updateLayout(state, action) {
			const pageSlug = action.payload;
			const details = pageDetails.details.find((detail) => detail.slug === pageSlug);
			const pageResources = [];

			state.slug = pageSlug;
			state.template = details.template;
			state.title = (details.title) ? details.title : "";
			state.help = (details.help) ? details.help : "";
			state.rss = (details.rss) ? details.rss : "";
			state.description = (details.description) ? details.description : "";
			state.thumbnail = (details.thumbnail) ? details.thumbnail : null;

			if (pageSlug.includes("browse-topic")) {
				state.hasTheInHeading = !collectionsWithoutTheInHeading.includes(pageSlug.split("-")[2]);
			}

			if (details.relatedResources != null) {
				details.relatedResources.forEach((resource) => {
					const r = relatedResources.resourceList.find((r) => r.slug === resource);
					if (r !== undefined) {
						pageResources.push(r);
					}
				});
				state.relatedResources = [...pageResources];
			}

			state.helpButtonLocation = (details.helpButtonLocation) ? details.helpButtonLocation : "page-description";
			
			updateBodyClassName(state.template);
			updateHTMLTitle(`${state.title} | Govinfo`);
		},
		updateTemplate(state, action) {
			state.template = action.payload;
			updateBodyClassName(state.template);
		},
		updateTitle(state, action) {
			state.title = action.payload;
			updateHTMLTitle(`${state.title} | Govinfo`);
		},
		dateBrowseTabSelect(state, action) {
			state.dateBrowseTab = action.payload;
		},
		updateSearchRSS(state, action) {
			state.rss = action.payload;
		},
		updatePageSizes(state, action) {
			const givenCollection = action.payload?.toLowerCase();
			if (givenCollection && givenCollection in pageSizeOptions) {
					state.paginationEnabled = true;
					state.pageSizes = pageSizeOptions[givenCollection];
			} else {
					state.paginationEnabled = false;
			}
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getCollectionData.pending, (state) => {
			state.apiInProgress = true;
		}),
		builder.addCase(getCollectionData.rejected, (state) => {
			console.log("No data for collection");
			state.apiInProgress = false;
		}),
		builder.addCase(getCollectionData.fulfilled, (state, action) => {
			state.apiInProgress = false;
			state.title = action.payload.title;
			state.hasTheInHeading = !collectionsWithoutTheInHeading.includes(action.meta.arg.toLowerCase());
			state.description = action.payload.description;
			state.help = (action.payload.help) ? action.payload.help : "";
			state.rss = (action.payload.rss) ? action.payload.rss : "";
			state.thumbnail = action.payload.thumbnail && typeof action.payload.thumbnail === "object" ? {...action.payload.thumbnail} : null;
			state.relatedResources = [...action.payload.relatedResources];

			updateHTMLTitle(`${state.title} | Govinfo`);
			updateBodyClassName(state.template); // Most of the time this won't change for collections. But in case a collection needs a specific template, it's ready.

			return state;
		})
	}
});

export const {updateLayout, updateTemplate, updateTitle, dateBrowseTabSelect, updateSearchRSS, updatePageSizes} = layoutSlice.actions;
export default layoutSlice.reducer;