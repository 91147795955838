import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import NavigatorSectionToggle from './NavigatorSectionToggle';
import {useAppSelector} from '../../app/hooks';
import ButtonClearNavigators from './ButtonClearNavigators'
import NavigatorPanel from './NavigatorPanel';

const NavigatorMenu =()=>{
	const [ showNavigatorSection, setShowNavigatorSection ] = useState(false);
	const toggleNavigatorSection = () => {
		setShowNavigatorSection(!showNavigatorSection);
	};

	const [ showselectedNavs, setShowselectedNavs ] = useState(false);
	const toggleShowselectedNavs = () => {
		setShowselectedNavs(!showselectedNavs);
	};

	const [ showUnselectedNavs, setShowUnselectedNavs ] = useState(false);
	const toggleShowUnselectedNavs = () => {
		setShowUnselectedNavs(!showUnselectedNavs);
	};


	const criteria = useAppSelector((state)=> state.criteria);
	const resultsCount = useAppSelector((state)=> state.results.response.iTotalCount);
	const navigators = useAppSelector((state)=> state.results.response.navigators);
	const selectedNavs =  navigators?.filter((nav) => criteria.filterOrder && criteria.filterOrder.includes(nav.name));
	const unselectedNavs = navigators?.filter((nav) => nav.buckets.length>0 && criteria.filterOrder && !criteria.filterOrder.includes(nav.name));

  return (
		<>
			{
				resultsCount > 0 &&
					<section id="search-navigators" className="" tabIndex={0} >
						{	
							!selectedNavs || selectedNavs?.length == 0 && 
								<section id={`navigator-filters-avilable`} className="navigator-section" aria-label="Refine Your Search Results By Selecting Filters">
									<header id={`heading-filters-avilable`} className="navigator-header">
										<h1 className="navigator-title">Refine Your Search</h1>
										{
											(criteria.filterOrder && criteria.filterOrder.length>0 ) && <ButtonClearNavigators criteria ={criteria} />
										}
										
										<NavigatorSectionToggle
											toggleOnClick={toggleNavigatorSection} 
											toggleState={showNavigatorSection}>
											<i className="bi navigator-section-toggle-caret"></i>
										</NavigatorSectionToggle>

									</header>
									<Collapse
										in={showNavigatorSection}>
										<section className="navigators-section-panel">
											{
												navigators?.filter(navigator=> navigator.buckets && navigator.buckets.length>0).map((navigator, index) => {
													return (
														<NavigatorPanel navigator={navigator} key={index}/>
													);
												})
											}
										</section>
									</Collapse>
								</section>       
						}

						{
							selectedNavs && selectedNavs?.length > 0 &&
								<section id={`navigator-filters-avilable-selected`} className="navigator-section" aria-label="Refined By">
									<header id={`heading-filters-avilable-selected`} className="navigator-header">
										<h1 className="navigator-title">Refined By</h1>
										{
											(criteria.filterOrder && criteria.filterOrder.length>0 ) && <ButtonClearNavigators  criteria={criteria} />
										}
									
										<NavigatorSectionToggle
											toggleOnClick={toggleShowselectedNavs} 
											toggleState={showselectedNavs}>
											<i className="bi navigator-section-toggle-caret"></i>
										</NavigatorSectionToggle>

									</header>
									<Collapse
										in={showselectedNavs}>
										<section className="navigators-section-panel">
											{
												selectedNavs?.filter(navigator=> navigator.buckets && navigator.buckets.length>0).map((navigator, index) => {
													return (
														<NavigatorPanel navigator={navigator} key={index}/>
													);
												})
											}
										</section>
									</Collapse>
								</section>
						}

						{
							selectedNavs && selectedNavs?.length > 0 && unselectedNavs && unselectedNavs?.length > 0 &&
								<section id="search-navigators" className="" tabIndex={0} >
									<section id={`navigator-filters-avilable-unselected`} className="navigator-section" aria-label="Refine Your Search Further">
										<header id={`heading-filters-avilable-unselected`} className="navigator-header">
											<h1 className="navigator-title">Refine Your Search Further</h1>
											<NavigatorSectionToggle
												toggleOnClick={toggleShowUnselectedNavs} 
												toggleState={showUnselectedNavs}>
												<i className="bi navigator-section-toggle-caret"></i>
											</NavigatorSectionToggle>
										</header>
										<Collapse
											in={showUnselectedNavs}>
											<section className="navigators-section-panel">
												{
													unselectedNavs?.filter(navigator=> navigator.buckets && navigator.buckets.length>0).map((navigator, index) => {
														return (
															<NavigatorPanel navigator={navigator} key={index}/>
														);
													})
												}
											</section>
										</Collapse>
									</section>
								</section>
						}
					</section>	
			}
 		</>	
  )
}
export default NavigatorMenu