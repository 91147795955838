import React from 'react';
import ReactMarkdown from 'react-markdown';

function ResultItemTitle({title, subtitle = "", url = "", hasPill = false}:{title:string, subtitle:string, url:string, hasPill?: boolean}) {

	return (
		<>
			<h4 className="result-title">
				{
					(url === "")
					? <ReactMarkdown
							components={{
								em(props) {
									const {node, ...rest} = props;
									return <i className={`${(hasPill) ? "pill" : ""}`} {...rest} />
								}
							}}
						>
							{ title }
						</ReactMarkdown>
					:	<a href={url}>
							<ReactMarkdown
							components={{
								em(props) {
									const {node, ...rest} = props;
									return <i className={`${(hasPill) ? "pill" : ""}`} {...rest} />
								},
								// ol: "p",
								// li: "p"
							}}
						>
							{ title }
						</ReactMarkdown>
						</a>
				}
				{/* {
					(url === "")
					? <ReactMarkdown>{ title }</ReactMarkdown>
					:	<a href={url}>
							<ReactMarkdown>{ title }</ReactMarkdown>
						</a>
				} */}
			</h4>
			{
				(subtitle != "")
					? <h5 className="document-title">
							<ReactMarkdown>{ subtitle }</ReactMarkdown>
						</h5>
					: null
			}
		</>
	)
}
export default ResultItemTitle;
